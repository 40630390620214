/* .posts{
    width: -webkit-fill-available;
} */
.gridPost1{
    height: 360px;
}
.article-desc h5 a{
    text-decoration: none;
    color: black;
    font-size: 16px;
    /* font-family: sans-serif; */
}
.article-desc h5 a:hover{
    color: red;
}
.article-desc p a{
    text-decoration: none;
    color: #444;
}
.article-desc p a:hover{
    color: red;
}
.article-desc h4 a{
    text-decoration: none;
    color: black;
}
.article-desc h4 a:hover{
    color: red;
}
.post-Widget{
    font-weight: lighter;
    border: 1px solid black;
    padding: 10px;
}
.post-title h4 a{
    color: black;
    text-decoration: none;
    font-size: 18px;
}
.post-title h4 a:hover{
    color: red;
}
.post-title p a{
    color: black;
    text-decoration: none;
    font-size: 18px;
}
.post-title p a:hover{
    color: red;
}
.table-image{
    border: 1px solid red;
    transition: 0.5s all ease-in-out;
}
.table-image:hover a img {
    transform: scale(1.5);
}