/* Navbar.css */
.asli-navbar {
  background-color: transparent;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  position: relative;
}

/* .logo {
  color: #fff;
  font-size: 24px;
} */

.nav-linking {
  list-style: none;
    display: flex;
    gap: 10px;
    justify-content: space-evenly;
    width: calc(100% - 380px);
}

.nav-linking li {
  /* margin: 0 15px; */
  /* padding-top: 6px; */
}

.nav-linking a {
  text-decoration: none;
    color: black;
}

/* Media Query for Mobile View */
@media (max-width: 768px) {
  .nav-linking {
    /* display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background-color: #333;
    padding: 10px 20px; */
    
  }
  .nav-btn {
    transition: transform 0.5s ease-in-out;
  }
  
  .nav-btn.open {
    transform: rotate(90deg);
  }
  .nav-linking {
    transition: all 0.3s ease-in-out;
    /* transform: translateX(-100%); */
    position: absolute;
    flex-flow: column;
    width: 100%;
    left: -100%;
    right: 0;
    top: 60px;
    z-index: 1010;
    text-align: center;
    gap: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    padding: 15px 0;
   }
   
   .nav-linking.open {
    transform: translateX(0);
   }
   
   
  /* .nav-linking.open {
    display: flex;
  } */
  .nav-linking.open{
    display: flex;
    left: 0;
  }

  .nav-linking li {
    /* margin: 6px 10px;
    text-align: center; */
  }

  .toggle-buttons {
    display: block;
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .bar {
    /* width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 5px 0; */
  }
  .asli-navbar {
    background-color: transparent;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    position: relative;
  }
}
