.bg-weather{
    background-image: url("https://media.istockphoto.com/id/536559095/zh/%E7%85%A7%E7%89%87/sky-background-on-sunset.jpg?s=612x612&w=0&k=20&c=5vV8uFey8SpkOpeVTQSfjIfDzqj5O3Vu9_-6mDFLobU=");
    /* border: 1px solid red; */
    background-size: cover;
    background-repeat: no-repeat;
}

@media screen and (max-width: 1000px) {
   
    
    .ajax-section .cities {
      grid-template-columns: repeat(3, 1fr);
    }
  }
@media screen and (max-width: 700px) {
    .heading,
    .ajax-section .city-temp {
      font-size: 3rem;
    }
    
    .ajax-section {
      margin-top: 20px;
    }
    
    .top-banner form {
      flex-direction: column;
      align-items: flex-start;
    }
    
    .top-banner form input,
    .top-banner form button {
      width: 100%;
    }
  
    .top-banner form button {
      margin: 20px 0 0 0;
    }
    
    .top-banner form .msg {
      position: static;
      max-width: none;
      min-height: 0;
      margin-top: 10px;
    }
  
    .ajax-section .cities {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media screen and (max-width: 500px) {
   
    .ajax-section .cities {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .ajax-section .cities {
    display: grid;
    /* grid-gap: 32px 20px;
    grid-template-columns: repeat(4, 1fr); */
    margin-top: -154px;
  }
  
  .ajax-section .city {
    position: relative;
    padding: 40px 10%;
    border-radius: 20px;
    background: var(--text_light);
    color: var(--text_med);
  }
  
  .ajax-section .city::after {
    content: '';
    width: 90%;
    height: 50px;
    position: absolute;
    bottom: -12px;
    left: 5%;
    z-index: -1;
    opacity: 0.3;
    border-radius: 20px;
    background: var(--text_light);
  }
  
  .ajax-section figcaption {
    margin-top: 10px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }
  
  .ajax-section .city-temp {
    font-size: 5rem;
    font-weight: bold;
    margin-top: 10px;
    color: var(--text_dark);
  }
  
  .ajax-section .city sup {
    font-size: 0.5em;
  }
  
  .ajax-section .city-name sup {
    padding: 0.2em 0.6em;
    border-radius: 30px;
    color: var(--text_light);
    background: var(--orange);
  }
  
  .ajax-section .city-icon {
    margin-top: 10px;
    width: 100px;
    height: 100px;
  }
  .heading {
    font-weight: bold;
    font-size: 4rem;
    letter-spacing: 0.02em;
    color: white;
    /* padding: 0 0 30px 0; */
  }
  
  .top-banner form {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .top-banner form input {
    font-size: 2rem;
    height: 40px;
    padding: 5px 5px 10px;
    border-bottom: 1px solid;
    color: white;
  }
  
  .top-banner form input::placeholder {
    color: currentColor; 
  }
  
  .top-banner form button {
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0.1em;
    padding: 15px 20px;
    /* margin-left: 15px; */
    border-radius: 5px;
    color: white;
    background: var(--red);
    transition: background 0.3s ease-in-out;
  }
  
  .top-banner form button:hover {
    background: var(--darkred);
  }
  
  .top-banner form .msg {
    position: absolute;
    bottom: -40px;
    left: 0;
    max-width: 450px;
    min-height: 40px;
  }
  .containers {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }
  button,
  input {
    border: none;
    background: none;
    outline: none;
    color: inherit;
  }
  button{
    cursor: pointer;
  }