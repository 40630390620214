
/* navigation */
.megaMenu {
  margin: 0 auto;
  padding: 0;
  display: block;
  float: none;
  min-width: 200px;
  position: relative;
  font-size: 14px;
  /* font-family: 'Roboto', sans-serif; */
  width: 100%;
  font-weight: 400;
  clear: both;
  min-height: 3.75em;
  background: #FFF;
}

.megaMenu:after {
  content: "";
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}

@media screen and (max-width: 991px) {
  .megaMenu {
    border-radius: 4px;
    overflow: hidden;
    background: #333333;
  }
}

.megaMenu * {
  outline: none !important;
  text-decoration: none;
  list-style: none;
  border: none;
  box-shadow: none;
  box-sizing: border-box;
  /* font-family: 'Roboto', sans-serif; */
  font-weight: 400;
}

.megaMenu a, .megaMenu img {
  /*display: inline-block;*/
  max-width: 100%;
}

.megaMenu i.fa {
  font-family: FontAwesome, serif !important;
}

.megaMenu .nav-container {
  /* navigation container */
  margin: 0 auto;
  padding: 0;
  display: block;
  float: none;
  width: 100%;
  position: relative;
  z-index: 10;
  background: #333333;
}

.megaMenu ul.menu-links {
  /* menu links */
  float: left;
  margin: 0;
  padding: 0;
  display: block;
}

@media screen and (max-width: 991px) {
  .megaMenu ul.menu-links {
    width: 100%;
    display: none;
  }
  .megaMenu.collapse_hide select.menu-links {
    /* hide collapse drop down when this class add */
    display: block;
  }
}

.megaMenu ul.menu-links > li {
  margin: 0;
  padding: 0;
  display: block;
  float: left;
}

@media screen and (min-width: 992px) {
  .megaMenu ul.menu-links > li.relative {
    /* ------------------- relative class for align the drop down right side */
    position: relative;
  }
}

@media screen and (min-width: 992px) and (min-width: 992px) {
  .megaMenu ul.menu-links > li.relative .drop-down > li > a {
    /* a tag single line text white space nowrap on desktop view */
    white-space: nowrap;
  }
}

@media screen and (max-width: 991px) {
  .megaMenu ul.menu-links > li {
    width: 100%;
    clear: both;
    border-bottom: 1px solid rgba(35, 35, 35, 0.4);
  }
  .megaMenu.vertical_lines_hide ul.menu-links > li {
    /* hide the menu vertical lines if the class add using jquery */
    border-bottom: none;
  }
}

@media screen and (min-width: 992px) {
  .megaMenu ul.menu-links > li {
    border-left: 1px solid #232323;
  }
  .megaMenu.vertical_lines_hide ul.menu-links > li {
    /* hide the menu vertical lines if the class add using jquery */
    border-left: none;
  }
  .megaMenu ul.menu-links > li:last-child a {
    border-right: 1px solid #232323;
  }
  .megaMenu.vertical_lines_hide ul.menu-links > li:last-child a {
    /* hide the menu vertical lines if the class add using jquery */
    border-right: none;
  }
}

.megaMenu ul.menu-links > li > a {
  margin: 0;
  padding: 0 0.875em;
  height: 3.75em;
  width: 100%;
  font-size: 1em;
  font-weight: 600;
  overflow: hidden;
  text-decoration: none;
  transition: color 300ms ease;
  position: relative;
  z-index: 10;
  text-align: center;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  cursor: pointer;
  color: #242424;
}

.megaMenu ul.menu-links.pull-right > li > a {
  border-right: none;
}

@media screen and (max-width: 1199px) {
  .megaMenu ul.menu-links > li > a {
    padding-left: 0.625em;
    padding-right: 0.625em;
  }
}

@media screen and (max-width: 991px) {
  .megaMenu ul.menu-links > li > a {
    height: auto;
    display: block;
    text-align: left;
    padding: 0.5em 0.9375em;
  }
  .megaMenu ul.menu-links > li > a:after {
    height: 101%;
  }
}

@media screen and (min-width: 992px) {
  .megaMenu.menu-items-direction-reverse ul.menu-links > li > a {
    /* menu items change the direction */
    -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .megaMenu.menu-items-align ul.menu-links > li > a {
    /* menu items text align left if this class add using jquery */
    text-align: left;
  }
}

.megaMenu ul.menu-links > li.active > a {
  /* add active class on li element */
  /* color: #FFF; */
  background-color: #f44a56;
  color:black;
  /* font-family: 'Roboto', sans-serif; */
  font-weight: 400;
  outline: none;
}

.megaMenu ul.menu-links > li:hover > a {
  color: #f44a56;
  
}
 /* .main-home{
  text-align: center;
  width:100px;
  color:black;
  background-color: #f44a56;
  font-family: 'Roboto', sans-serif; 
  font-weight: 400;
  outline: none;
}  */

.megaMenu ul.menu-links > li > a:after {
  /* menu items mouse hover effect */
  background-color: #f44a56;

}

.megaMenu ul.menu-links > li:hover > a:after {
  opacity: 1;
}

.megaMenu ul.menu-links > li.active > a:after {
  /* add active class on li element */
  opacity: 1;
}

.megaMenu ul.menu-links > li > a i.fa {
  /* font awesome icon */
  font-size: 1.5625em;
  display: inline-block;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 991px) {
  .megaMenu ul.menu-links > li > a i.fa {
    font-size: 1em;
  }
}

.megaMenu ul.menu-links > li > a span.description {
  /* description */
  font-size: 0.625em;
  display: block;
  width: 100%;
  clear: both;
  margin: 0;
  padding: 0;
  font-weight: 600;
}

.megaMenu.items-description-hide ul.menu-links > li > a span.description {
  /* menu items description hide. if this class add using jquery */
  display: none;
}

.megaMenu ul.menu-links > li > a span.text {
  /* text */
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  clear: both;
  font-weight: 500;
  text-transform:uppercase;
}

.megaMenu ul.menu-links > li > a span.text i.fa {
  /* font awesome icons inside a */
  margin: 0;
  display: inline-block;
  font-size: 1em;
  padding: 0 0.125em 0 0;
}

@media screen and (min-width: 992px) {
  .megaMenu ul.menu-links > li > a span.text i.fa.fa-2x {
    font-size: 1.25em;
  }
  .megaMenu ul.menu-links > li > a span.text i.fa.fa-3x {
    font-size: 1.875em;
  }
  .megaMenu ul.menu-links > li > a span.text i.fa.fa-4x {
    font-size: 2.5em;
  }
  .megaMenu ul.menu-links > li > a span.text i.fa.fa-5x {
    font-size: 3.125em;
  }
}

.megaMenu ul.menu-links > li > a span.text i.fa.fa-indicator {
  float: right;
  display: inline-block;
  margin-left: 0.3125em;
  line-height: 1.675em;
}

@media screen and (max-width: 991px) {
  .megaMenu ul.menu-links > li > a span.text i.fa.fa-indicator {
    font-size: 0.75em;
    width: 1.25em;
    text-align: right;
  }
}

.megaMenu ul.menu-links > li > a span.hidden {
  /* hidden class is used to show the text on desktop view and hide the text on mobile view */
}

@media screen and (max-width: 991px) {
  .megaMenu ul.menu-links > li > a span.hidden {
    display: inline-block !important;
    font-weight: 600;
  }
}

.megaMenu ul.menu-links > li > a i.fa.fa-indicator {
  /* font awesome icon hide with in a tag */
  display: none;
}

.megaMenu ul.menu-search-bar-desktop, .megaMenu ul.menu-search-bar-mobile {
  /* search bar for desktop view */
  margin: 0;
  padding: 0;
  display: block;
  float: right;
  position: relative;
}

@media screen and (max-width: 991px) {
  .megaMenu ul.menu-search-bar-desktop, .megaMenu ul.menu-search-bar-mobile {
    display: none;
  }
}

.megaMenu ul.menu-search-bar-desktop .effect-div, .megaMenu ul.menu-search-bar-mobile .effect-div, .megaMenu ul.menu-search-bar-desktop .fa-indicator, .megaMenu ul.menu-search-bar-mobile .fa-indicator {
  display: none !important;
}

.megaMenu ul.menu-search-bar-desktop > li > a, .megaMenu ul.menu-search-bar-mobile > li > a {
  display: block;
  margin: 0;
  padding: 0;
  color: transparent;
}

.megaMenu ul.menu-search-bar-desktop > li > a > i.fa, .megaMenu ul.menu-search-bar-mobile > li > a > i.fa {
  display: block;
  margin: 0;
  padding: 0;
  color: #ffffff;
  height: 3.75em;
  width: 3.75em;
  background: #1986c1;
  text-align: center;
  line-height: 3.75em;
  cursor: pointer;
  transition: background 300ms ease;
  position: relative;
  z-index: 10;
  overflow: hidden;
}

.megaMenu ul.menu-search-bar-desktop > li > a > i.fa:after, .megaMenu ul.menu-search-bar-mobile > li > a > i.fa:after {
  background: #f44a56;
}

.megaMenu ul.menu-search-bar-desktop > li > a > i.fa:hover:after, .megaMenu ul.menu-search-bar-mobile > li > a > i.fa:hover:after {
  opacity: 1;
}

.megaMenu ul.menu-search-bar-desktop > li > a > i.fa:before, .megaMenu ul.menu-search-bar-mobile > li > a > i.fa:before {
  font-size: 1.25em;
}

@media screen and (min-width: 992px) {
  .megaMenu.search-bar-hide-desktop ul.menu-search-bar-desktop, .megaMenu.search-bar-hide-desktop ul.menu-search-bar-mobile {
    /* hide the search bar on desktop view if this class add */
    display: none;
  }
}

.megaMenu ul.menu-search-bar-desktop .drop-down, .megaMenu ul.menu-search-bar-mobile .drop-down {
  position: absolute;
  right: 0;
  top: auto;
  background: #1986c1;
  padding: 0.625em;
  border-top: none;
}

.megaMenu ul.menu-search-bar-desktop li, .megaMenu ul.menu-search-bar-mobile li, .megaMenu ul.menu-search-bar-desktop form, .megaMenu ul.menu-search-bar-mobile form, .megaMenu ul.menu-search-bar-desktop label, .megaMenu ul.menu-search-bar-mobile label, .megaMenu ul.menu-search-bar-desktop input, .megaMenu ul.menu-search-bar-mobile input, .megaMenu ul.menu-search-bar-desktop button, .megaMenu ul.menu-search-bar-mobile button {
  margin: 0;
  padding: 0;
  display: block;
  width: 100%;
}

.megaMenu ul.menu-search-bar-desktop :-moz-placeholder, .megaMenu ul.menu-search-bar-mobile :-moz-placeholder {
  color: #ffffff !important;
  opacity: 1 !important;
}

.megaMenu ul.menu-search-bar-desktop ::-moz-placeholder, .megaMenu ul.menu-search-bar-mobile ::-moz-placeholder {
  color: #ffffff !important;
  opacity: 1 !important;
}

.megaMenu ul.menu-search-bar-desktop ::-webkit-input-placeholder, .megaMenu ul.menu-search-bar-mobile ::-webkit-input-placeholder {
  color: #ffffff !important;
  opacity: 1 !important;
}

.megaMenu ul.menu-search-bar-desktop :-ms-input-placeholder, .megaMenu ul.menu-search-bar-mobile :-ms-input-placeholder {
  color: #ffffff !important;
  opacity: 1 !important;
}

.megaMenu ul.menu-search-bar-desktop button[type=submit], .megaMenu ul.menu-search-bar-mobile button[type=submit],
.megaMenu ul.menu-search-bar-desktop input[type=submit], .megaMenu ul.menu-search-bar-mobile input[type=submit] {
  padding: 0;
  display: block;
  height: 2.5em;
  width: 100%;
  background: none;
  color: #ffffff;
  background: #146d9d;
  margin: 0.625em 0 0;
  font-size: 0.875em;
  font-weight: 600;
  transition: background 300ms ease;
  overflow: hidden;
  position: relative;
  z-index: 10;
}

.megaMenu ul.menu-search-bar-desktop button[type=submit]:after, .megaMenu ul.menu-search-bar-mobile button[type=submit]:after,
.megaMenu ul.menu-search-bar-desktop input[type=submit]:after, .megaMenu ul.menu-search-bar-mobile input[type=submit]:after {
  background: #136794 !important;
}

.megaMenu ul.menu-search-bar-desktop button[type=submit]:hover:after, .megaMenu ul.menu-search-bar-mobile button[type=submit]:hover:after,
.megaMenu ul.menu-search-bar-desktop input[type=submit]:hover:after, .megaMenu ul.menu-search-bar-mobile input[type=submit]:hover:after {
  opacity: 1;
}

.megaMenu ul.menu-search-bar-desktop button[type=search], .megaMenu ul.menu-search-bar-mobile button[type=search], .megaMenu ul.menu-search-bar-desktop input[type=search], .megaMenu ul.menu-search-bar-mobile input[type=search] {
  margin: 0;
  display: block;
  height: 2.5em;
  width: 100%;
  background: #136794;
  padding: 0 0.625em;
  font-size: 0.875em;
  color: #ffffff;
}

.megaMenu ul.menu-search-bar-mobile {
  /* search bar for mobile view */
  display: none;
  width: 100%;
}

@media screen and (max-width: 991px) {
  .megaMenu.collapse_hide ul.menu-search-bar-mobile {
    /* hide collapse drop down when this class add */
    display: block;
  }
  .megaMenu.search-bar-hide-mobile ul.menu-search-bar-mobile {
    /* hide the search bar on mobile view if this class add */
    display: none;
  }
}

.megaMenu ul.menu-search-bar-mobile button[type=submit],
.megaMenu ul.menu-search-bar-mobile input[type=submit] {
  left: auto;
  right: 0;
  position: absolute;
  width: 3.75em;
  height: 3.75em;
  top: 0;
  margin: 0;
}

.megaMenu ul.menu-search-bar-mobile label i.fa {
  font-size: 1.25em;
}

.megaMenu ul.menu-search-bar-mobile input[type=search] {
  padding-left: 1.25em;
  padding-right: 3.75em;
  height: 3.75em;
}

@media screen and (max-width: 991px) {
  .megaMenu ul.menu-search-bar-mobile {
    position: relative;
    border-bottom: 1px solid #232323;
  }
}

.megaMenu ul.menu-logo {
  /* logo */
  margin: 0;
  padding: 0;
  float: left;
  display: block;
}

@media screen and (min-width: 992px) {
  .megaMenu.logo-align-right ul.menu-logo {
    /* align the logo right side on desktop view */
    float: right;
  }
  .megaMenu.logo-hide-desktop ul.menu-logo {
    /* hide the logo on desktop view */
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .megaMenu ul.menu-logo {
    width: 100%;
    min-height: 3.75em;
    position: relative;
  }
}

.megaMenu ul.menu-logo > li {
  margin: 0;
  padding: 0;
  display: block;
  float: left;
}

@media screen and (max-width: 991px) {
  .megaMenu ul.menu-logo > li {
    width: 100%;
    border-bottom: 1px solid rgba(35, 35, 35, 0.6);
  }
  .megaMenu.vertical_lines_hide ul.menu-logo > li {
    /* hide the menu vertical lines if the class add using jquery */
    border-bottom: none;
  }
}

.megaMenu ul.menu-logo > li > a {
  margin: 0;
  padding: 0 0.625em;
  height: 2.5em;
  width: 100%;
  font-size: 1.5em;
  font-weight: 600;
  text-decoration: none;
  transition: color 300ms ease;
  position: relative;
  z-index: 10;
  text-align: center;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  color: #ffffff;
}

@media screen and (min-width: 992px) {
  .megaMenu ul.menu-logo > li > a span.type-1, .megaMenu ul.menu-logo > li > a span.type-3 {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

.megaMenu ul.menu-logo > li > a span.type-2, .megaMenu ul.menu-logo > li > a span.type-3 {
  display: none;
}

.megaMenu.logo-type-2-show ul.menu-logo > li > a {
  /* show the type 2 logo and hide the type 1 logo */
}

.megaMenu.logo-type-2-show ul.menu-logo > li > a span.type-2 {
  display: inline-block;
}

.megaMenu.logo-type-2-show ul.menu-logo > li > a span.type-1 {
  display: none;
}

.megaMenu.logo-type-3-show ul.menu-logo > li > a {
  /* show the type 3 logo and hide the type 1 logo */
}

.megaMenu.logo-type-3-show ul.menu-logo > li > a span.type-3 {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.megaMenu.logo-type-3-show ul.menu-logo > li > a span.type-1 {
  display: none;
}

@media screen and (max-width: 991px) {
  .megaMenu ul.menu-logo > li > a {
    display: block;
    text-align: left;
    padding-top: 10px;
    line-height: 30px;
    font-size: 1.125em;
    min-height: 3.375em;
    padding-right: 3.75em;
    word-break: break-all;
  }
  .megaMenu.logo-hide-mobile ul.menu-logo > li > a {
    /* this classes is used to hide the logo on mobile view */
    display: none;
  }
}

.megaMenu ul.menu-logo > li > a i.fa {
  padding: 0;
  margin: 0 0.3125em 0 0;
  display: inline-block;
}

.megaMenu ul.menu-logo > li > a:hover {
  color: #ffffff;
}

.megaMenu ul.menu-logo > li > a:after {
  /* menu items mouse hover effect */
}

.megaMenu ul.menu-logo > li:hover > a:after {
  opacity: 1;
}

.megaMenu ul.menu-logo > li.active > a:after {
  /* add active class on li element */
  opacity: 1;
}

@media screen and (max-width: 991px) {
  .megaMenu ul.menu-logo > li > a:after {
    display: none;
  }
}

.megaMenu ul.menu-logo > li > a img {
  padding: 0;
  display: inline-block;
  max-width: 100%;
  max-height: 1em;
  margin: 0 0.3125em 0 0;
}

@media screen and (max-width: 991px) {
  .megaMenu ul.menu-logo > li > a img {
    position: relative;
    top: -0.0625em;
  }
}

.megaMenu .drop-down {
  /* drop down */
  margin: 0;
  padding: 0;
  display: block;
  position: absolute;
  top: auto;
  left: auto;
  min-width: 12.813em;
  transition: all 300ms ease;
  opacity: 0;
  visibility: hidden;
  z-index: 9999;
  background: #282828;
  border-top: 1px solid #232323;
}

@media screen and (max-width: 991px) {
  .megaMenu .drop-down {
    background: #282828;
    border-top: 1px solid #333333;
    border-bottom: 1px solid #333333;
  }
  .megaMenu .drop-down:after {
    content: "";
    clear: both;
    display: block;
    visibility: hidden;
    height: 0;
  }
}

@media screen and (min-width: 992px) {
  .megaMenu .drop-down.pull-right {
    /* align the drop down right side */
    right: 0;
  }
  .megaMenu .drop-down.active {
    /* show the drop down on desktop view by adding active class */
    opacity: 1;
    visibility: visible;
  }
}

@media screen and (max-width: 991px) {
  .megaMenu .drop-down {
    position: relative;
    min-width: 1px;
    display: none;
    opacity: 1;
    visibility: visible;
  }
  .megaMenu .drop-down.pull-right {
    /* align the drop down right side */
    width: 100%;
    left: 0;
  }
  .megaMenu .drop-down.active {
    /* show the drop down on mobile view by adding active class */
    display: block;
  }
}

.megaMenu .drop-down li {
  margin: 0;
  padding: 0;
  display: block;
  float: left;
  clear: both;
  width: 100%;
  position: relative;
  border-bottom: 1px solid #232323;
}

@media screen and (min-width: 992px) {
  .megaMenu.vertical_lines_hide .drop-down li {
    /* hide the menu vertical lines if the class add using jquery */
    border-bottom: none;
  }
}

.megaMenu .drop-down li:last-child {
  border-bottom: none;
}

@media screen and (max-width: 991px) {
  .megaMenu .drop-down li {
    border: none;
  }
  .megaMenu.vertical_lines_hide .drop-down li {
    /* hide the menu vertical lines if the class add using jquery */
    border-bottom: none;
    border-top: none;
  }
}

.megaMenu .drop-down li:hover > a,
.megaMenu .drop-down li:hover > a .description {
  color: #f44a56;
}

.megaMenu .drop-down li > a:after {
  background: #333333 ;
}

.megaMenu .drop-down li:hover > a:after {
  opacity: 1;
}

.megaMenu .drop-down a {
  margin: 0;
  padding: 0.75em 1.25em;
  display: block;
  text-decoration: none;
  /* font-family: 'Roboto', sans-serif; */
  font-size: 0.875em;
  font-weight: 500;
  position: relative;
  transition: color 300ms ease;
  z-index: 1;
  color: #262626;
  border-bottom:1px solid #f9f9f9;
  text-transform:uppercase;
}

@media screen and (max-width: 991px) {
  .megaMenu .drop-down a {
    padding-left: 1.875em;
  }
}

@media screen and (max-width: 991px) and (max-width: 300px) {
  .megaMenu .drop-down a {
    padding-left: 1.25em;
  }
}

.megaMenu .drop-down a i.fa {
  /* font awesome icon */
  font-size: 0.875em;
  width: 1.25em;
  text-align: left;
  position: relative;
  top: -0.0625em;
}

.megaMenu .drop-down a span.description {
  /* description */
  display: block;
  /* font-family: 'Roboto', sans-serif; */
  font-size: 0.6875em;
  font-weight: 600;
  text-transform: uppercase;
  transition: color 300ms ease;
  width: 100%;
  clear: both;
  color: #262626;
}

.megaMenu .drop-down .drop-down {
  /* second level drop down */
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  border-top: none;
}

@media screen and (max-width: 991px) {
  .megaMenu .drop-down .drop-down {
    position: relative;
    left: 0;
    border-top: 1px solid #333333;
    border-bottom: 1px solid #333333;
  }
}

.megaMenu .drop-down .drop-down.left {
  /* this classes is used to align the drop down left side */
  left: auto;
  right: 100%;
}

@media screen and (max-width: 991px) {
  .megaMenu .drop-down .drop-down.left {
    left: 0;
    right: 0;
  }
}

@media screen and (max-width: 991px) {
  .megaMenu .drop-down .drop-down a {
    padding-left: 3.125em;
  }
}

@media screen and (max-width: 991px) and (max-width: 300px) {
  .megaMenu .drop-down .drop-down a {
    padding-left: 1.25em;
  }
}

@media screen and (max-width: 991px) {
  .megaMenu .drop-down .drop-down .drop-down a {
    padding-left: 4.375em;
  }
}

@media screen and (max-width: 991px) and (max-width: 300px) {
  .megaMenu .drop-down .drop-down .drop-down a {
    padding-left: 1.25em;
  }
}

.megaMenu .drop-down i.fa.fa-indicator, .megaMenu .drop-down .pull-right, .megaMenu .drop-down .pull-left {
  /* font awesome icon indicator icon */
  float: right;
  text-align: right;
  line-height: 2.1em;
  margin: 0;
  padding: 0;
  display: inline-block;
  position: relative;
  left: 0;
}

.megaMenu .drop-down .pull-right {
  text-align: right !important;
}

.megaMenu .drop-down h1, .megaMenu .drop-down h2, .megaMenu .drop-down h3, .megaMenu .drop-down h4, .megaMenu .drop-down h5, .megaMenu .drop-down h6 {
  /* headings */
  color: #262626;
  margin: 0;
  display: block;
  width: 100%;
  clear: both;
  padding: 0.3125em;
  font-weight: 600;
}

.megaMenu .drop-down h1 {
  padding: 0.4375em;
}

.megaMenu .drop-down h2 {
  padding: 0.5625em;
}

.megaMenu .drop-down h3 {
  padding: 0.5em 0.6875em;
}

.megaMenu .drop-down h4 {
  padding: 0.625em 0.9375em;
}

.megaMenu .drop-down h5 {
  padding: 0.9375em 1.25em;
}

.megaMenu .drop-down h6 {
  padding: 1.25em 1.5625em;
}

.megaMenu .drop-down hr {
  /* horizontal line */
  margin: 0;
  padding: 0;
  display: block;
  width: 100%;
  clear: both;
  position: relative;
  border-color: rgba(35, 35, 35, 0.4);
}

.megaMenu .drop-down .slider-view-drop-down {
  /* slide view drop down */
  display: block;
  margin: 0;
  padding: 0;
}

.megaMenu .drop-down .slider-view-drop-down ul {
  margin: 0;
  padding: 0;
  display: block;
  width: 100%;
}

.megaMenu .drop-down .slider-view-drop-down li {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
}

.megaMenu .drop-down .slider-view-drop-down li:last-child:before, .megaMenu .drop-down .slider-view-drop-down li:last-child:after {
  display: none;
}

.megaMenu .drop-down .slider-view-drop-down a {
  position: relative;
}

.megaMenu .drop-down .slider-view-drop-down .drop-down {
  left: 0;
  opacity: 1;
  visibility: visible;
}

.megaMenu ul.menu-mobile-trigger {
  /* mobile trigger button for show the collapse drop down */
  margin: 0;
  padding: 0;
  display: none;
  list-style: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9999;
  height: 3.75em;
  width: 6.25em;
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  .megaMenu ul.menu-mobile-trigger {
    display: block;
  }
}

.megaMenu ul.menu-mobile-trigger li {
  margin: 0;
  padding: 0;
  display: block;
  list-style: none;
  height: 3.75em;
  width: 3.75em;
  float: right;
  position: relative;
}

.megaMenu ul.menu-mobile-trigger i.fa {
  text-decoration: none;
  border-radius: 4px;
  float: right;
  height: 2.5em;
  width: 2.5em;
  line-height: 2.5em;
  text-align: center;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  transition: color 300ms ease, background 300ms ease;
  color: #999;
  background: #262626;
}

.megaMenu ul.menu-mobile-trigger:hover i.fa {
  color: #f44a56;
  background: #1a1a1a;
}

.megaMenu ul.menu-mobile-trigger i.fa:before {
  font-size: 1.5625em;
}

.megaMenu {
  /*----------------------------------------------------------------------------- MOUSE HOVER ---------------------*/
}

.megaMenu .menu-logo > li > a:after,
.megaMenu .menu-links > li > a:after,
.megaMenu .drop-down li > a:after,
.megaMenu .drop-down-large .nav-tabs.vertical a:after,
.megaMenu .drop-down-medium .nav-tabs.vertical a:after,
.megaMenu .drop-down-large .nav-tabs a:after,
.megaMenu .drop-down-medium .nav-tabs a:after,
.megaMenu .drop-down-large .well:after,
.megaMenu .drop-down-medium .well:after,
.megaMenu .drop-down-large .list-group .list-group-item:after,
.megaMenu .drop-down-medium .list-group .list-group-item:after,
.megaMenu .drop-down-large .btn-default:after,
.megaMenu .drop-down-medium .btn-default:after,
.megaMenu .drop-down-large .btn:after,
.megaMenu .drop-down-medium .btn:after,
.megaMenu .drop-down-large table td:after,
.megaMenu .drop-down-large table th:after,
.megaMenu .drop-down-medium table th:after,
.megaMenu .drop-down-medium table th:after,
.megaMenu .menu-user-profile .profile-icons a:after,
.megaMenu .menu-contact-us button[type=submit]:after,
.megaMenu .menu-contact-us button[type=reset]:after,
.megaMenu .menu-search-bar-desktop button[type=submit]:after, .megaMenu ul.menu-search-bar-mobile button[type=submit]:after,
.megaMenu .menu-search-bar-desktop > li > a > i.fa:after, .megaMenu ul.menu-search-bar-mobile > li > a > i.fa:after {
  /* menu items mouse hover effect */
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -1;
  transition: all 300ms ease;
  background-color: #f44a56;
}

.megaMenu .has-ripple {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.megaMenu .ripple {
  display: block;
  position: absolute;
  pointer-events: none;
  border-radius: 50%;
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0);
  background: #fff;
  opacity: 1;
}

.megaMenu .ripple-animate {
  -webkit-animation: ripple;
          animation: ripple;
}

@-webkit-keyframes ripple {
  100% {
    opacity: 0;
    -webkit-transform: scale(2);
            transform: scale(2);
  }
}

@keyframes ripple {
  100% {
    opacity: 0;
    -webkit-transform: scale(2);
            transform: scale(2);
  }
}

.megaMenu.mega-menu-sticky-fade .sticky-container, .megaMenu.mega-menu-sticky-scale .sticky-container, .megaMenu.mega-menu-sticky-move .sticky-container {
  /* menu sticky header class */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-duration: 300ms;
          animation-duration: 300ms;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
}

.megaMenu .sticky-container {
  position: relative;
  background: #333333;
}

.megaMenu.mega-menu-sticky-fade .sticky-container {
  /* sticky animation fade */
  -webkit-animation-name: sticky_fade;
          animation-name: sticky_fade;
}

@-webkit-keyframes sticky_fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes sticky_fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.megaMenu.mega-menu-sticky-scale .sticky-container {
  /* sticky animation scale */
  -webkit-animation-name: sticky_scale;
          animation-name: sticky_scale;
}

@-webkit-keyframes sticky_scale {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes sticky_scale {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.megaMenu.mega-menu-sticky-move .sticky-container {
  /* sticky animation scale */
  -webkit-animation-name: sticky_expand;
          animation-name: sticky_expand;
}

@-webkit-keyframes sticky_expand {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes sticky_expand {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@media screen and (min-width: 992px) {
  .megaMenu.menu-top-fixed-desktop {
    /* menu top fixed on desktop view with this class */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media screen and (max-width: 991px) {
  .megaMenu.menu-top-fixed-mobile {
    /* menu top fixed on mobile view with this class */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

.megaMenu .drop-down-medium, .megaMenu .drop-down-large {
  /* drop down with medium size */
  margin: 0;
  padding:0;
  position: absolute;
  top: auto;
  left: auto;
  opacity: 0;
  visibility: hidden;
  z-index: 9999;
  transition: all 300ms ease;
  font-size: 0.9375em;
  background: #282828;
  color: #262626;
  overflow: hidden;
  border-top: 1px solid #232323;
}

.megaMenu .drop-down-medium img, .megaMenu .drop-down-large img {
  transition: border-color 300ms ease;
}

.megaMenu .drop-down-medium img:hover, .megaMenu .drop-down-medium img:active, .megaMenu .drop-down-medium img:focus, .megaMenu .drop-down-large img:hover, .megaMenu .drop-down-large img:active, .megaMenu .drop-down-large img:focus {
  border-color: #f44a56;
}

.megaMenu .drop-down-medium.nav-tabs, .megaMenu .drop-down-large.nav-tabs {
  background: #333333;
}

@media screen and (min-width: 992px) {
  .megaMenu .drop-down-medium.active, .megaMenu .drop-down-large.active {
    /* show the drop down on desktop view by adding active class */
    opacity: 1;
    visibility: visible;
  }
}

@media screen and (max-width: 991px) {
  .megaMenu .drop-down-medium, .megaMenu .drop-down-large {
    position: relative;
    min-width: 1px;
    display: none;
    opacity: 1;
    visibility: visible;
    background: #282828;
    border-top: 1px solid #333333;
    border-bottom: 1px solid #333333;
  }
  .megaMenu.vertical_lines_hide .drop-down-medium, .megaMenu.vertical_lines_hide .drop-down-large {
    /* hide the border */
    border-top: none;
  }
  .megaMenu .drop-down-medium.active, .megaMenu .drop-down-large.active {
    /* show the drop down on mobile view by adding active class */
    display: block;
  }
}

@media screen and (max-width: 992px) {
  .megaMenu .drop-down-medium, .megaMenu .drop-down-large {
    max-width: 100%;
    left: 0;
  }
}

.megaMenu .drop-down-medium .list-items, .megaMenu .drop-down-large .list-items {
  /* list items */
  margin: 0;
  padding: 0 0 1.25em;
  display: block;
  clear: both;
  float: left;
  width: 100%;
  position: relative;
}

.megaMenu .drop-down-medium .list-items i.fa, .megaMenu .drop-down-large .list-items i.fa {
  margin-right: 0.3125em;
}

.megaMenu .drop-down-medium .list-items ul, .megaMenu .drop-down-large .list-items ul {
  margin: 0;
  padding: 0;
  display: block;
  width: 100%;
}

.megaMenu .drop-down-medium .list-items ul li, .megaMenu .drop-down-large .list-items ul li {
  margin: 0;
  padding: 0;
  display: block;
  width: 100%;
}

.megaMenu .drop-down-medium .list-items ul li a, .megaMenu .drop-down-large .list-items ul li a {
  font-weight: 600;
}

.megaMenu .drop-down-medium .list-items ol, .megaMenu .drop-down-large .list-items ol {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
}

.megaMenu .drop-down-medium .list-items ol li, .megaMenu .drop-down-large .list-items ol li {
  margin: 0;
  padding: 0;
  position: relative;
  display: block;
  width: 100%;
  color: #262626;
}

.megaMenu .drop-down-medium .list-items ol li a, .megaMenu .drop-down-large .list-items ol li a {
  font-weight: 600;
}

.megaMenu .drop-down-medium .list-items ol li:after, .megaMenu .drop-down-large .list-items ol li:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: list-item;
  list-style: decimal inside;
  line-height: 2.125em;
  transition: color 300ms ease;
}

.megaMenu .drop-down-medium .list-items ol li:hover:after, .megaMenu .drop-down-large .list-items ol li:hover:after {
  color: #f44a56;
}

.megaMenu .drop-down-medium .list-items ol a, .megaMenu .drop-down-large .list-items ol a {
  padding-left: 1.25em;
}

.megaMenu .drop-down-medium .list-items a, .megaMenu .drop-down-large .list-items a {
  padding: 0.5em 0;
  transition: color 300ms ease;
  font-size: 0.875em;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  display: block;
  width: 100%;
  text-transform:uppercase;
}

.megaMenu .drop-down-medium .list-items a:hover, .megaMenu .drop-down-large .list-items a:hover {
  color: #f44a56;
}

.megaMenu .drop-down-medium .vertical-line, .megaMenu .drop-down-large .vertical-line {
  /* vertical line */
  background: rgba(255, 255, 255, 0.1);
  position: absolute;
  height: 100%;
  width: 1px;
  top: 0;
}

.megaMenu .drop-down-medium .vertical-line.left, .megaMenu .drop-down-large .vertical-line.left {
  left: 0;
  right: auto;
}

.megaMenu .drop-down-medium .vertical-line.right, .megaMenu .drop-down-large .vertical-line.right {
  right: 0;
  left: auto;
}

.megaMenu .drop-down-medium p, .megaMenu .drop-down-large p {
  /* paragraph */
  font-size: 0.9375em;
  color: #262626;
  line-height: normal;
  font-weight: 400;
}

.megaMenu .drop-down-medium h1, .megaMenu .drop-down-medium h2, .megaMenu .drop-down-medium h3, .megaMenu .drop-down-medium h4, .megaMenu .drop-down-medium h5, .megaMenu .drop-down-medium h6, .megaMenu .drop-down-large h1, .megaMenu .drop-down-large h2, .megaMenu .drop-down-large h3, .megaMenu .drop-down-large h4, .megaMenu .drop-down-large h5, .megaMenu .drop-down-large h6 {
  padding-left: 0;
  padding-right: 0;
  color: #262626;
  font-weight: 500;
}

@media screen and (max-width: 991px) {
  .megaMenu .drop-down-medium h1, .megaMenu .drop-down-large h1 {
    font-size: 1.375em;
  }
  .megaMenu .drop-down-medium h2, .megaMenu .drop-down-large h2 {
    font-size: 1.25em;
  }
  .megaMenu .drop-down-medium h3, .megaMenu .drop-down-large h3 {
    font-size: 1.125em;
  }
}

.megaMenu .drop-down-medium a, .megaMenu .drop-down-large a {
  /*display: inline-block;*/
  padding: 0;
  margin: 0;
  color: #262626;
  font-weight: 400;
  transition: color 300ms ease;
  text-decoration: none;
}

.megaMenu .drop-down-medium a:hover, .megaMenu .drop-down-large a:hover {
  color: #f44a56;
}

.megaMenu .drop-down-medium .nav-tabs.vertical, .megaMenu .drop-down-large .nav-tabs.vertical {
  /* tabs */
  margin: 0;
  padding: 0;
  width: 100%;
  background: none;
  display: block;
}

.megaMenu .drop-down-medium .nav-tabs.vertical li, .megaMenu .drop-down-large .nav-tabs.vertical li {
  width: 100%;
  display: block;
  margin: 0;
  padding: 0;
  clear: both;
  position: relative;
  transition: none;
  border: none;
}

.megaMenu .drop-down-medium .nav-tabs.vertical li:last-child, .megaMenu .drop-down-large .nav-tabs.vertical li:last-child {
  border-right: none;
}

.megaMenu .drop-down-medium .nav-tabs.vertical li:first-child:before, .megaMenu .drop-down-large .nav-tabs.vertical li:first-child:before {
  display: none;
}

.megaMenu .drop-down-medium .nav-tabs.vertical li:after, .megaMenu .drop-down-medium .nav-tabs.vertical li:before, .megaMenu .drop-down-large .nav-tabs.vertical li:after, .megaMenu .drop-down-large .nav-tabs.vertical li:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #232323;
  display: block;
  height: 1px;
  opacity: 0;
  z-index: 999999;
  width: 100%;
  transition: opacity 300ms ease;
}

.megaMenu .drop-down-medium .nav-tabs.vertical li:after, .megaMenu .drop-down-large .nav-tabs.vertical li:after {
  bottom: 0;
  top: auto;
}

.megaMenu .drop-down-medium .nav-tabs.vertical li.active:before, .megaMenu .drop-down-medium .nav-tabs.vertical li.active:after, .megaMenu .drop-down-large .nav-tabs.vertical li.active:before, .megaMenu .drop-down-large .nav-tabs.vertical li.active:after {
  opacity: 1;
}

.megaMenu .drop-down-medium .nav-tabs.vertical li.active a, .megaMenu .drop-down-medium .nav-tabs.vertical li:hover a, .megaMenu .drop-down-medium .nav-tabs.vertical li:active a, .megaMenu .drop-down-medium .nav-tabs.vertical li:focus a, .megaMenu .drop-down-large .nav-tabs.vertical li.active a, .megaMenu .drop-down-large .nav-tabs.vertical li:hover a, .megaMenu .drop-down-large .nav-tabs.vertical li:active a, .megaMenu .drop-down-large .nav-tabs.vertical li:focus a {
  background: none;
  color: #f44a56;
}

.megaMenu .drop-down-medium .nav-tabs.vertical li.active a:after, .megaMenu .drop-down-medium .nav-tabs.vertical li:hover a:after, .megaMenu .drop-down-medium .nav-tabs.vertical li:active a:after, .megaMenu .drop-down-medium .nav-tabs.vertical li:focus a:after, .megaMenu .drop-down-large .nav-tabs.vertical li.active a:after, .megaMenu .drop-down-large .nav-tabs.vertical li:hover a:after, .megaMenu .drop-down-large .nav-tabs.vertical li:active a:after, .megaMenu .drop-down-large .nav-tabs.vertical li:focus a:after {
  width: 102% !important;
  height: 100%;
  opacity: 1;
  display: block !important;
}

.megaMenu.sweep-to-right .drop-down-medium .nav-tabs.vertical li.active a:after, .megaMenu.sweep-to-right .drop-down-medium .nav-tabs.vertical li:hover a:after, .megaMenu.sweep-to-right .drop-down-medium .nav-tabs.vertical li:active a:after, .megaMenu.sweep-to-right .drop-down-medium .nav-tabs.vertical li:focus a:after, .megaMenu.sweep-to-right .drop-down-large .nav-tabs.vertical li.active a:after, .megaMenu.sweep-to-right .drop-down-large .nav-tabs.vertical li:hover a:after, .megaMenu.sweep-to-right .drop-down-large .nav-tabs.vertical li:active a:after, .megaMenu.sweep-to-right .drop-down-large .nav-tabs.vertical li:focus a:after {
  right: -2px;
}

.megaMenu.scale-effect .drop-down-medium .nav-tabs.vertical li.active a:after, .megaMenu.scale-effect .drop-down-medium .nav-tabs.vertical li:hover a:after, .megaMenu.scale-effect .drop-down-medium .nav-tabs.vertical li:active a:after, .megaMenu.scale-effect .drop-down-medium .nav-tabs.vertical li:focus a:after, .megaMenu.scale-effect .drop-down-large .nav-tabs.vertical li.active a:after, .megaMenu.scale-effect .drop-down-large .nav-tabs.vertical li:hover a:after, .megaMenu.scale-effect .drop-down-large .nav-tabs.vertical li:active a:after, .megaMenu.scale-effect .drop-down-large .nav-tabs.vertical li:focus a:after {
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
  border-radius: 0;
}

.megaMenu .drop-down-medium .nav-tabs.vertical a, .megaMenu .drop-down-large .nav-tabs.vertical a {
  margin: 0;
  padding: 1.25em;
  display: block;
  background: none;
  border-radius: 0;
  border: none;
  color: #262626;
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
  position: relative;
  transition: background 300ms ease, color 300ms ease;
  z-index: 9999;
  overflow: visible;
  text-transform:uppercase;
}

@media screen and (max-width: 991px) {
  .megaMenu .drop-down-medium .nav-tabs.vertical a, .megaMenu .drop-down-large .nav-tabs.vertical a {
    border-bottom: none !important;
  }
}

.megaMenu .drop-down-medium .nav-tabs.vertical a:after, .megaMenu .drop-down-large .nav-tabs.vertical a:after {
  width: 102%;
  background: #282828;
}

.megaMenu .drop-down-medium .nav-tabs.vertical a:hover, .megaMenu .drop-down-large .nav-tabs.vertical a:hover {
  background: none;
  color: #f44a56;
}

.megaMenu .drop-down-medium .nav-tabs.vertical a i.fa, .megaMenu .drop-down-large .nav-tabs.vertical a i.fa {
  margin-right: 0.3125em;
}

.megaMenu .drop-down-medium .nav-tabs.vertical a span, .megaMenu .drop-down-large .nav-tabs.vertical a span {
  display: block;
  width: 100%;
  clear: both;
  font-size: 0.75em;
}

.megaMenu .drop-down-medium .nav-tabs.vertical img, .megaMenu .drop-down-large .nav-tabs.vertical img {
  max-width: 100%;
}

.megaMenu .drop-down-medium .nav-tabs.vertical.small, .megaMenu .drop-down-large .nav-tabs.vertical.small {
  font-size: 1.0625em;
}

.megaMenu .drop-down-medium .nav-tabs.vertical.small.pull-right li, .megaMenu .drop-down-large .nav-tabs.vertical.small.pull-right li {
  border-right: 1px solid #232323;
  border-left: none;
}

@media screen and (max-width: 991px) {
  .megaMenu .drop-down-medium .nav-tabs.vertical.small.pull-right li, .megaMenu .drop-down-large .nav-tabs.vertical.small.pull-right li {
    border-left: 1px solid #232323;
  }
}

.megaMenu .drop-down-medium .nav-tabs.vertical.small.pull-right li.active, .megaMenu .drop-down-medium .nav-tabs.vertical.small.pull-right li:hover, .megaMenu .drop-down-medium .nav-tabs.vertical.small.pull-right li:active, .megaMenu .drop-down-medium .nav-tabs.vertical.small.pull-right li:focus, .megaMenu .drop-down-large .nav-tabs.vertical.small.pull-right li.active, .megaMenu .drop-down-large .nav-tabs.vertical.small.pull-right li:hover, .megaMenu .drop-down-large .nav-tabs.vertical.small.pull-right li:active, .megaMenu .drop-down-large .nav-tabs.vertical.small.pull-right li:focus {
  border-right-color: #f44a56;
}

@media screen and (max-width: 991px) {
  .megaMenu .drop-down-medium .nav-tabs.vertical.small.pull-right li.active, .megaMenu .drop-down-medium .nav-tabs.vertical.small.pull-right li:hover, .megaMenu .drop-down-medium .nav-tabs.vertical.small.pull-right li:active, .megaMenu .drop-down-medium .nav-tabs.vertical.small.pull-right li:focus, .megaMenu .drop-down-large .nav-tabs.vertical.small.pull-right li.active, .megaMenu .drop-down-large .nav-tabs.vertical.small.pull-right li:hover, .megaMenu .drop-down-large .nav-tabs.vertical.small.pull-right li:active, .megaMenu .drop-down-large .nav-tabs.vertical.small.pull-right li:focus {
    border-right-color: #232323;
  }
}

.megaMenu .drop-down-medium .nav-tabs.vertical.small li, .megaMenu .drop-down-large .nav-tabs.vertical.small li {
  background: #333333;
  border: 1px solid #232323;
  border-bottom: none;
  border-right: none;
  transition: border-color 300ms ease;
}

.megaMenu .drop-down-medium .nav-tabs.vertical.small li:last-child, .megaMenu .drop-down-large .nav-tabs.vertical.small li:last-child {
  border-bottom: 1px solid #232323;
}

.megaMenu .drop-down-medium .nav-tabs.vertical.small li:after, .megaMenu .drop-down-medium .nav-tabs.vertical.small li:before, .megaMenu .drop-down-large .nav-tabs.vertical.small li:after, .megaMenu .drop-down-large .nav-tabs.vertical.small li:before {
  display: none !important;
}

.megaMenu .drop-down-medium .nav-tabs.vertical.small li.active, .megaMenu .drop-down-medium .nav-tabs.vertical.small li:hover, .megaMenu .drop-down-medium .nav-tabs.vertical.small li:active, .megaMenu .drop-down-medium .nav-tabs.vertical.small li:focus, .megaMenu .drop-down-large .nav-tabs.vertical.small li.active, .megaMenu .drop-down-large .nav-tabs.vertical.small li:hover, .megaMenu .drop-down-large .nav-tabs.vertical.small li:active, .megaMenu .drop-down-large .nav-tabs.vertical.small li:focus {
  border-left-color: #f44a56;
}

@media screen and (max-width: 991px) {
  .megaMenu .drop-down-medium .nav-tabs.vertical.small li.active, .megaMenu .drop-down-medium .nav-tabs.vertical.small li:hover, .megaMenu .drop-down-medium .nav-tabs.vertical.small li:active, .megaMenu .drop-down-medium .nav-tabs.vertical.small li:focus, .megaMenu .drop-down-large .nav-tabs.vertical.small li.active, .megaMenu .drop-down-large .nav-tabs.vertical.small li:hover, .megaMenu .drop-down-large .nav-tabs.vertical.small li:active, .megaMenu .drop-down-large .nav-tabs.vertical.small li:focus {
    border-left-color: #232323;
    border-top-color: #f44a56;
  }
}

@media screen and (max-width: 991px) {
  .megaMenu .drop-down-medium .nav-tabs.vertical.small li, .megaMenu .drop-down-large .nav-tabs.vertical.small li {
    display: block;
    width: 100%;
    border-right: 1px solid #232323;
  }
}

.megaMenu .drop-down-medium .nav-tabs.vertical.small li a, .megaMenu .drop-down-large .nav-tabs.vertical.small li a {
  font-weight: 600;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}

@media screen and (max-width: 991px) {
  .megaMenu .drop-down-medium .nav-tabs.vertical.small li a, .megaMenu .drop-down-large .nav-tabs.vertical.small li a {
    border-bottom: 1px solid rgba(53, 52, 52, 0.5) !important;
    padding-top: 0.625em;
    padding-bottom: 0.625em;
  }
}

.megaMenu .drop-down-medium .nav-tabs.vertical.small li a:after, .megaMenu .drop-down-large .nav-tabs.vertical.small li a:after {
  background: #232325;
  display: none !important;
}

@media screen and (max-width: 991px) {
  .megaMenu .drop-down-medium .nav-tabs.vertical.small li a:after, .megaMenu .drop-down-large .nav-tabs.vertical.small li a:after {
    background: #333333;
  }
}

.megaMenu .drop-down-medium .nav-tabs.vertical.small li.active a, .megaMenu .drop-down-medium .nav-tabs.vertical.small li:hover a, .megaMenu .drop-down-medium .nav-tabs.vertical.small li:focus a, .megaMenu .drop-down-medium .nav-tabs.vertical.small li:active a, .megaMenu .drop-down-large .nav-tabs.vertical.small li.active a, .megaMenu .drop-down-large .nav-tabs.vertical.small li:hover a, .megaMenu .drop-down-large .nav-tabs.vertical.small li:focus a, .megaMenu .drop-down-large .nav-tabs.vertical.small li:active a {
  background: #333333;
}

@media screen and (max-width: 991px) {
  .megaMenu .drop-down-medium .nav-tabs.vertical.small li.active a, .megaMenu .drop-down-medium .nav-tabs.vertical.small li:hover a, .megaMenu .drop-down-medium .nav-tabs.vertical.small li:focus a, .megaMenu .drop-down-medium .nav-tabs.vertical.small li:active a, .megaMenu .drop-down-large .nav-tabs.vertical.small li.active a, .megaMenu .drop-down-large .nav-tabs.vertical.small li:hover a, .megaMenu .drop-down-large .nav-tabs.vertical.small li:focus a, .megaMenu .drop-down-large .nav-tabs.vertical.small li:active a {
    background: #333333;
  }
}

.megaMenu .drop-down-medium .tab-content.vertical.small, .megaMenu .drop-down-large .tab-content.vertical.small {
  background: #333333 !important;
  padding: 1.25em;
  font-size: 1em;
  min-height: 10px !important;
  border: 1px solid #232323;
  border-radius: 0;
}

@media screen and (max-width: 991px) {
  .megaMenu .drop-down-medium .tab-content.vertical.small, .megaMenu .drop-down-large .tab-content.vertical.small {
    margin-bottom: 20px;
    margin-top: -1px;
  }
}

.megaMenu .drop-down-medium .tab-content.vertical, .megaMenu .drop-down-large .tab-content.vertical {
  /* tabs content */
  padding:  30px;
  float: left;
  width: 100%;
  border: none;
  margin-bottom: 0;
}

.megaMenu .drop-down-medium .tab-content.vertical, .megaMenu .drop-down-large.nav-tabs .tab-content.vertical {
  background: #282828;
  border-left: 1px solid #232323;
}

.megaMenu .drop-down-medium .tab-content, .megaMenu .drop-down-large .tab-content {
  /* inner tabs */
  background: #333333;
  border: 1px solid #232323;
  padding: 1.25em 1.25em;
  display: block;
  width: 100%;
  margin: 0 0 20px;
}

.megaMenu .drop-down-medium .tab-content h1, .megaMenu .drop-down-medium .tab-content h2, .megaMenu .drop-down-medium .tab-content h3, .megaMenu .drop-down-medium .tab-content h4, .megaMenu .drop-down-medium .tab-content h5, .megaMenu .drop-down-medium .tab-content h6, .megaMenu .drop-down-large .tab-content h1, .megaMenu .drop-down-large .tab-content h2, .megaMenu .drop-down-large .tab-content h3, .megaMenu .drop-down-large .tab-content h4, .megaMenu .drop-down-large .tab-content h5, .megaMenu .drop-down-large .tab-content h6 {
  margin-top: 0;
}

.megaMenu .drop-down-medium .nav-tabs, .megaMenu .drop-down-large .nav-tabs {
  /* inner tabs */
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (max-width: 991px) {
  .megaMenu .drop-down-medium .nav-tabs, .megaMenu .drop-down-large .nav-tabs {
    display: block;
    border-radius: 0;
  }
}

.megaMenu .drop-down-medium .nav-tabs li, .megaMenu .drop-down-large .nav-tabs li {
  background: none;
  padding: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  border: 1px solid #232323;
  border-bottom: none;
  border-right: none;
  transition: border-color 300ms ease;
}

.megaMenu .drop-down-medium .nav-tabs li:last-child, .megaMenu .drop-down-large .nav-tabs li:last-child {
  border-right: 1px solid #232323;
}

.megaMenu .drop-down-medium .nav-tabs li a, .megaMenu .drop-down-large .nav-tabs li a {
  border-radius: 0;
  color: #262626;
  font-weight: 600;
  font-size: 1em;
}

.megaMenu .drop-down-medium .nav-tabs li.active, .megaMenu .drop-down-medium .nav-tabs li:hover, .megaMenu .drop-down-medium .nav-tabs li:active, .megaMenu .drop-down-medium .nav-tabs li:focus, .megaMenu .drop-down-large .nav-tabs li.active, .megaMenu .drop-down-large .nav-tabs li:hover, .megaMenu .drop-down-large .nav-tabs li:active, .megaMenu .drop-down-large .nav-tabs li:focus {
  border-top-color: #f44a56;
}

.megaMenu .drop-down-medium .nav-tabs li.active a, .megaMenu .drop-down-medium .nav-tabs li:hover a, .megaMenu .drop-down-medium .nav-tabs li:active a, .megaMenu .drop-down-medium .nav-tabs li:focus a, .megaMenu .drop-down-large .nav-tabs li.active a, .megaMenu .drop-down-large .nav-tabs li:hover a, .megaMenu .drop-down-large .nav-tabs li:active a, .megaMenu .drop-down-large .nav-tabs li:focus a {
  background: #333333;
  color: #f44a56;
}

.megaMenu .drop-down-medium .nav-tabs li.active a:after, .megaMenu .drop-down-medium .nav-tabs li:hover a:after, .megaMenu .drop-down-medium .nav-tabs li:active a:after, .megaMenu .drop-down-medium .nav-tabs li:focus a:after, .megaMenu .drop-down-large .nav-tabs li.active a:after, .megaMenu .drop-down-large .nav-tabs li:hover a:after, .megaMenu .drop-down-large .nav-tabs li:active a:after, .megaMenu .drop-down-large .nav-tabs li:focus a:after {
  display: none !important;
}

@media screen and (max-width: 991px) {
  .megaMenu .drop-down-medium .nav-tabs li, .megaMenu .drop-down-large .nav-tabs li {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    border-radius: 0;
    border-bottom: 1px solid rgba(53, 52, 52, 0.5);
  }
  .megaMenu.vertical_lines_hide .drop-down-medium .nav-tabs li, .megaMenu.vertical_lines_hide .drop-down-large .nav-tabs li {
    /* hide the border */
    border-bottom: none;
  }
}

.megaMenu .drop-down-medium .nav-tabs a, .megaMenu .drop-down-large .nav-tabs a {
  background: #333333;
  border: none !important;
  padding: 0.625em 1.25em;
  color: #262626;
  display: block;
  margin: 0;
  width: 100%;
  transition: background 300ms ease, border-color 300ms ease, color 300ms ease;
  position: relative;
  z-index: 10;
  overflow: hidden;
  font-weight: 600;
}

@media screen and (max-width: 991px) {
  .megaMenu .drop-down-medium .nav-tabs a, .megaMenu .drop-down-large .nav-tabs a {
    border-radius: 0;
    border-bottom: 1px solid rgba(53, 52, 52, 0.5) !important;
  }
}

.megaMenu .drop-down-medium .nav-tabs a:after, .megaMenu .drop-down-large .nav-tabs a:after {
  background: #232325;
  border-color: #262626;
  color: #ffffff;
  border-bottom: none;
}

.megaMenu .drop-down-medium .nav-tabs a:hover:after, .megaMenu .drop-down-medium .nav-tabs a:active:after, .megaMenu .drop-down-medium .nav-tabs a:focus:after, .megaMenu .drop-down-large .nav-tabs a:hover:after, .megaMenu .drop-down-large .nav-tabs a:active:after, .megaMenu .drop-down-large .nav-tabs a:focus:after {
  border-color: #262626;
  color: #ffffff;
  border-bottom: none;
  opacity: 1;
}

.megaMenu .drop-down-medium .nav-tabs.nav-justified, .megaMenu .drop-down-large .nav-tabs.nav-justified {
  /* tabs justified */
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

@media screen and (max-width: 991px) {
  .megaMenu .drop-down-medium .nav-tabs.nav-justified, .megaMenu .drop-down-large .nav-tabs.nav-justified {
    display: block;
  }
}

.megaMenu .drop-down-medium .nav-tabs.nav-justified li, .megaMenu .drop-down-large .nav-tabs.nav-justified li {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

@media screen and (max-width: 991px) {
  .megaMenu .drop-down-medium .nav-tabs.nav-justified li, .megaMenu .drop-down-large .nav-tabs.nav-justified li {
    display: block;
  }
}

.megaMenu .drop-down-medium .nav-tabs.nav-justified li:last-child, .megaMenu .drop-down-large .nav-tabs.nav-justified li:last-child {
  padding-right: 0;
}

.megaMenu .drop-down-medium .nav-tabs.nav-justified a, .megaMenu .drop-down-large .nav-tabs.nav-justified a {
  width: 100%;
}

@media screen and (max-width: 991px) {
  .megaMenu .drop-down-medium .nav-tabs.nav-justified a, .megaMenu .drop-down-large .nav-tabs.nav-justified a {
    text-align: left;
  }
}

.megaMenu .drop-down-medium .nav-tabs .dropdown-menu, .megaMenu .drop-down-large .nav-tabs .dropdown-menu {
  left: 0;
  top: 100%;
}

.megaMenu .drop-down-medium mark, .megaMenu .drop-down-large mark {
  background: #333333;
  border: 1px solid #232323;
  color: #262626;
}

.megaMenu .drop-down-medium small, .megaMenu .drop-down-large small {
  color: #ffffff;
  font-size: 0.8125em;
}

.megaMenu .drop-down-medium blockquote, .megaMenu .drop-down-large blockquote {
  border-left: 5px solid transparent;
  font-size: 1em;
  position: relative;
}

.megaMenu .drop-down-medium blockquote:before, .megaMenu .drop-down-large blockquote:before {
  content: "";
  display: block;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 0;
  right: auto;
  top: 0;
  bottom: 0;
  width: 10px;
  background: #333333;
  z-index: 10;
  border: 1px solid #232323;
}

.megaMenu .drop-down-medium .black-box, .megaMenu .drop-down-large .black-box {
  /* black box */
  background: #333333;
  padding: 1.25em;
  font-size: 0.9375em;
  border: 1px solid #232323;
  border-radius: 0;
}

.megaMenu .drop-down-medium .black-box p, .megaMenu .drop-down-large .black-box p {
  font-size: 100%;
}

.megaMenu .drop-down-medium table, .megaMenu .drop-down-large table {
  /* table */
  font-size: 0.875em;
  background: #232325;
}

.megaMenu .drop-down-medium table *, .megaMenu .drop-down-large table * {
  background: none !important;
}

.megaMenu .drop-down-medium table th, .megaMenu .drop-down-large table th {
  font-size: 1.125em;
}

.megaMenu .drop-down-medium table th, .megaMenu .drop-down-medium table tr, .megaMenu .drop-down-medium table td, .megaMenu .drop-down-large table th, .megaMenu .drop-down-large table tr, .megaMenu .drop-down-large table td {
  border: none;
  transition: background 300ms ease;
  font-size: 1em;
  position: relative;
  z-index: 10;
  overflow: hidden;
}

.megaMenu .drop-down-medium table td:after, .megaMenu .drop-down-medium table th:after, .megaMenu .drop-down-large table td:after, .megaMenu .drop-down-large table th:after {
  background: #333333 !important;
}

.megaMenu .drop-down-medium table td:hover:after, .megaMenu .drop-down-medium table th:hover:after, .megaMenu .drop-down-large table td:hover:after, .megaMenu .drop-down-large table th:hover:after {
  opacity: 1;
}

.megaMenu .drop-down-medium .img-thumbnail, .megaMenu .drop-down-large .img-thumbnail {
  /* thumbnail */
  background: none;
  border: 1px solid #232323;
}

.megaMenu .drop-down-medium .well, .megaMenu .drop-down-large .well {
  background: #333333;
  border: 1px solid #232323;
  transition: background 300ms ease;
  font-size: 0.9375em;
  position: relative;
  z-index: 10;
  overflow: hidden;
  color: #262626;
  border-radius: 0;
  font-weight: 600;
}

.megaMenu .drop-down-medium .well:after, .megaMenu .drop-down-large .well:after {
  display: none;
}

.megaMenu .drop-down-medium .btn-default, .megaMenu .drop-down-medium .btn, .megaMenu .drop-down-large .btn-default, .megaMenu .drop-down-large .btn {
  background: #333333;
  color: #262626;
  border: 1px solid #232323;
  transition: background 300ms ease, color 300ms ease;
  font-size: 0.875em;
  position: relative;
  z-index: 10;
  overflow: hidden;
  font-weight: 600;
  border-radius: 0;
}

.megaMenu .drop-down-medium .btn-default:hover, .megaMenu .drop-down-medium .btn-default:active, .megaMenu .drop-down-medium .btn-default:focus, .megaMenu .drop-down-medium .btn:hover, .megaMenu .drop-down-medium .btn:active, .megaMenu .drop-down-medium .btn:focus, .megaMenu .drop-down-large .btn-default:hover, .megaMenu .drop-down-large .btn-default:active, .megaMenu .drop-down-large .btn-default:focus, .megaMenu .drop-down-large .btn:hover, .megaMenu .drop-down-large .btn:active, .megaMenu .drop-down-large .btn:focus {
  color: #f44a56;
}

.megaMenu .drop-down-medium .btn-default:hover .badge, .megaMenu .drop-down-medium .btn-default:active .badge, .megaMenu .drop-down-medium .btn-default:focus .badge, .megaMenu .drop-down-medium .btn:hover .badge, .megaMenu .drop-down-medium .btn:active .badge, .megaMenu .drop-down-medium .btn:focus .badge, .megaMenu .drop-down-large .btn-default:hover .badge, .megaMenu .drop-down-large .btn-default:active .badge, .megaMenu .drop-down-large .btn-default:focus .badge, .megaMenu .drop-down-large .btn:hover .badge, .megaMenu .drop-down-large .btn:active .badge, .megaMenu .drop-down-large .btn:focus .badge {
  color: #f44a56;
}

.megaMenu .drop-down-medium .btn-default:after, .megaMenu .drop-down-medium .btn:after, .megaMenu .drop-down-large .btn-default:after, .megaMenu .drop-down-large .btn:after {
  background: #333333;
}

.megaMenu .drop-down-medium .btn-default:hover:after, .megaMenu .drop-down-medium .btn:hover:after, .megaMenu .drop-down-large .btn-default:hover:after, .megaMenu .drop-down-large .btn:hover:after {
  opacity: 1;
}

.megaMenu .drop-down-medium .btn-default .badge, .megaMenu .drop-down-medium .btn .badge, .megaMenu .drop-down-large .btn-default .badge, .megaMenu .drop-down-large .btn .badge {
  transition: color 300ms ease;
  position: relative;
  left: 0.3125em;
  border: 1px solid #232323;
  background: #282828;
  color: #262626;
  font-weight: 600;
}

.megaMenu .drop-down-medium .list-group, .megaMenu .drop-down-large .list-group {
  background: #333333;
  overflow: hidden;
  display: block;
  border-radius: 0;
}

.megaMenu .drop-down-medium .list-group .list-group-item-heading, .megaMenu .drop-down-large .list-group .list-group-item-heading {
  font-weight: 600;
}

.megaMenu .drop-down-medium .list-group .list-group-item, .megaMenu .drop-down-large .list-group .list-group-item {
  position: relative;
  transition: background 300ms ease;
  z-index: 10;
  overflow: hidden;
  border: 1px solid #232323;
  color: #262626;
  font-size: 0.9375em;
  font-weight: 600;
  border-radius: 0;
}

.megaMenu .drop-down-medium .list-group .list-group-item:after, .megaMenu .drop-down-large .list-group .list-group-item:after {
  display: none;
}

.megaMenu .drop-down-medium .list-group .list-group-item:before, .megaMenu .drop-down-large .list-group .list-group-item:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  width: 100%;
  z-index: 99;
  background: #353434;
}

.megaMenu .drop-down-medium .list-group .list-group-item-text, .megaMenu .drop-down-large .list-group .list-group-item-text {
  color: #ffffff;
}

.megaMenu .drop-down-medium .list-group li, .megaMenu .drop-down-large .list-group li {
  background: none;
  border-radius: 0;
  transition: background 300ms ease;
  font-size: 0.875em;
  position: relative;
}

.megaMenu .drop-down-medium .list-group li:before, .megaMenu .drop-down-large .list-group li:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 1px;
  background: #333333;
  z-index: 99;
}

.megaMenu .drop-down-medium .list-group li:last-child:before, .megaMenu .drop-down-large .list-group li:last-child:before {
  display: none;
}

.megaMenu .drop-down-medium .list-group li .badge, .megaMenu .drop-down-large .list-group li .badge {
  border-color: #232323;
  transition: color 300ms ease;
  background: #282828;
  color: #262626;
  font-weight: 600;
}

.megaMenu .drop-down-medium .list-group a, .megaMenu .drop-down-large .list-group a {
  padding: 0.625em;
  width: 100%;
  display: block;
  font-size: 0.875em;
  background: none;
  margin-bottom: -1px;
}

.megaMenu .drop-down-medium .list-group a p, .megaMenu .drop-down-large .list-group a p {
  font-size: 1em;
  color: gray !important;
}

.megaMenu .drop-down-large {
  /* drop down large size */
  left: 0;
  width: 100%;
  right: 0;
}

.megaMenu .space-remove {
  /* remove the div space */
  padding: 0;
  margin: 0;
}

.megaMenu .badge {
  background: none;
  border: 1px solid #353434;
  color: #ffffff;
}

.megaMenu .panel {
  /* panel */
  background: #232325;
}

.megaMenu .panel .panel-heading {
  background: #333333;
  color: #262626;
  font-weight: 600;
  border: 1px solid #232323;
  border-radius: 0;
}

.megaMenu .panel .panel-title a {
  width: 100%;
  font-size: 0.9375em;
  font-weight: 600;
}

.megaMenu .panel .panel-title a:hover {
  color: #f44a56;
}

.megaMenu .panel .panel-body {
  font-size: 0.9375em;
  border: 1px solid #232323 !important;
  background: #333333;
  border-top: none !important;
}

.megaMenu .panel table {
  border: none !important;
  background: #333333;
}

.megaMenu .panel table *, .megaMenu .panel table td, .megaMenu .panel table th, .megaMenu .panel table tr {
  border: none;
}

.megaMenu .panel table th, .megaMenu .panel table td {
  padding-left: 1.25em;
  padding-right: 1.25em;
  font-weight: 600;
  border: 1px solid #232323;
}

.megaMenu .dropdown {
  /* bootstrap drop down */
  background: none;
}

.megaMenu .dropdown button {
  background: #232325 !important;
  color: #ffffff !important;
}

.megaMenu .dropdown button:before, .megaMenu .dropdown button:after {
  display: none !important;
}

.megaMenu .dropdown .dropdown-menu {
  background: #232325;
  top: auto;
  padding: 0.625em 0;
}

.megaMenu .dropdown .dropdown-menu .ripple {
  display: none;
}

.megaMenu .dropdown .dropdown-menu li {
  margin: 0;
  display: block;
  padding: 0.3125em 1.25em;
}

.megaMenu .dropdown .dropdown-menu .divider {
  padding: 0;
  background: #333333;
}

.megaMenu .dropdown .dropdown-menu a {
  font-size: 0.875em;
  width: 100%;
  padding: 0;
  margin: 0;
  background: none;
}

.megaMenu .carousel-row {
  /* carousel slider */
}

.megaMenu .carousel-row .slide-footer {
  display: block;
  float: left;
  width: 100%;
  text-align: left;
}

.megaMenu .carousel-row .slide-content {
  padding-top: 0.625em;
  padding-bottom: 0.625em;
}

.megaMenu .carousel-row .carousel-indicators {
  top: auto;
  bottom: 0.625em;
}

.megaMenu .carousel-row .carousel-indicators li {
  border-color: #f44a56;
}

.megaMenu .carousel-row .carousel-indicators li.active {
  background: #f44a56;
}

.megaMenu .media {
  margin-top: 0;
  margin-bottom: 15px;
  /* media */
}

.megaMenu .media .media-left {
  width: 100%;
  float: left;
  max-width: 6.875em;
}

.megaMenu .media .media-heading {
  margin-top: 0;
  padding-top: 0;
}

.megaMenu .media .media-body {
  padding-left: 0.9375em;
  font-size: 0.9375em;
}

.megaMenu .thumbnail {
  /* thumbnail */
  background: none;
}

.megaMenu .thumb {
  /* thumb */
  margin-bottom: 20px;
}

.megaMenu hr {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  clear: both;
  background: #CCC;
  height: 1px;
  border: none;
}

.megaMenu .equal-height {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  height: 100%;
}

@media screen and (max-width: 991px) {
  .megaMenu .equal-height {
    display: block;
    padding: 0 15px;
  }
}

.megaMenu .equal-height .black-box {
  width: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin: 15px;
}

@media screen and (max-width: 991px) {
  .megaMenu .equal-height .black-box {
    display: block;
    margin: 0;
    margin-bottom: 15px;
  }
}

.megaMenu .nav-gallery {
  /* --------------------------------------- Image Gallery */
  margin: 0;
  padding: 0.625em 0.9375em 1.25em;
  display: block;
  border-bottom: 1px solid #333333;
}

.megaMenu .nav-gallery ul.row {
  margin: 0;
  padding: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-flex-flow: row;
      -ms-flex-flow: row;
          flex-flow: row;
  clear: both;
}

@media screen and (max-width: 500px) {
  .megaMenu .nav-gallery ul.row {
    display: block;
  }
}

.megaMenu .nav-gallery ul.row li {
  float: left;
  margin: 0;
  padding: 0.25em;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

@media screen and (max-width: 500px) {
  .megaMenu .nav-gallery ul.row li {
    display: block;
    width: 33.33333333333333%;
  }
}

.megaMenu .nav-gallery ul.row img, .megaMenu .nav-gallery ul.row a {
  max-width: 100%;
  width: 100%;
  max-height: 80px;
}

.megaMenu .nav-gallery-output {
  /* ------------------------------- gallery output */
  padding: 1.25em;
  position: relative;
}

.megaMenu .nav-gallery-output > a {
  display: none;
}

.megaMenu .nav-gallery-output > a.active {
  display: block;
}

.megaMenu .nav-gallery-output img {
  max-height: 300px;
}

.megaMenu .menu-user-profile {
  /* --------------------------- user profile */
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 1em;
  max-width: 200px;
}

@media screen and (min-width: 992px) {
  .megaMenu .menu-user-profile {
    min-width: 200px;
  }
}

@media screen and (max-width: 991px) {
  .megaMenu .menu-user-profile {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    background: #282828;
  }
}

.megaMenu .menu-user-profile li {
  border: none;
}

.megaMenu .menu-user-profile .profile-image {
  /* ------------------ profile image */
  margin: 0;
  padding: 0.9375em 0;
  display: block;
  width: 100%;
  text-align: center;
}

.megaMenu .menu-user-profile .profile-image img {
  display: inline-block;
  border-radius: 100px;
  height: 5em;
  width: 5em;
  border: 1px solid #232323;
}

@media screen and (max-width: 991px) {
  .megaMenu .menu-user-profile .profile-image img {
    border-color: #333333;
  }
}

.megaMenu .menu-user-profile .profile-heading {
  /* --------------------- profile heading */
  margin: 0;
  padding: 0 0 0.625em;
  color: #262626;
  font-weight: 600;
}

.megaMenu .menu-user-profile .profile-description {
  /* ----------------------- profile description */
  margin: 0;
  padding: 0 1.25em 10px;
  color: #262626;
  font-weight: 400;
  font-size: 0.8125em;
  font-style: italic;
}

.megaMenu .menu-user-profile .profile-icons {
  /* --------------------- profile icons */
  padding: 0.625em 0;
  background: #333333;
  display: block;
  margin: 0.625em 0 0;
  border-top: 1px solid #333333;
}

@media screen and (max-width: 991px) {
  .megaMenu .menu-user-profile .profile-icons {
    background: #282828;
  }
}

.megaMenu .menu-user-profile .profile-icons:hover a {
  background: #282828;
}

@media screen and (max-width: 991px) {
  .megaMenu .menu-user-profile .profile-icons:hover a {
    background: #333333;
  }
}

.megaMenu .menu-user-profile .profile-icons:hover a:after {
  opacity: 0;
}

.megaMenu .menu-user-profile .profile-icons a {
  display: inline-block;
  background: #282828;
  border-radius: 100px;
  width: 1.875em;
  height: 1.875em;
  font-size: 1em;
  position: relative;
  overflow: hidden;
  padding: 0;
  transition: background 300ms ease;
  margin: 0 0.25em;
  z-index: 10;
}

@media screen and (max-width: 991px) {
  .megaMenu .menu-user-profile .profile-icons a {
    background: #333333;
  }
}

.megaMenu .menu-user-profile .profile-icons a:after {
  background: #282828 !important;
}

.megaMenu .menu-user-profile .profile-icons a:hover:after {
  opacity: 1;
}

.megaMenu .menu-user-profile .profile-icons a:hover i.fa {
  color: #f44a56;
}

.megaMenu .menu-user-profile .profile-icons i.fa {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  color: #262626;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  transition: color 300ms ease;
}

@media screen and (max-width: 991px) {
  .megaMenu .menu-user-profile .profile-icons i.fa {
    background: #333333;
  }
}

.megaMenu .menu-user-profile .effect-div {
  display: none !important;
}

.megaMenu .menu-contact-us {
  /* ----------------------- menu contact us */
  margin: 0;
  color: #262626;
  text-align: left;
  font-size: 1em;
  width: 100%;
  background: #282828;
  padding: 0.625em 0 0;
}

@media screen and (min-width: 992px) {
  .megaMenu .menu-contact-us {
    max-width: 300px;
    min-width: 300px;
  }
}

.megaMenu .menu-contact-us label {
  margin: 0;
  padding: 0.625em 1.25em;
  display: block;
  width: 100%;
  font-size: 0.875em;
  color: #262626;
  font-weight: 600;
}

.megaMenu .menu-contact-us input, .megaMenu .menu-contact-us textarea {
  padding: 0 0.625em;
  display: block;
  width: 100%;
  color: #262626;
  font-size: 0.9375em;
  height: 2.5em;
  margin: 0.625em 0 0;
  background: #333333;
  border: 1px solid #232323;
}

.megaMenu .menu-contact-us :-moz-placeholder {
  opacity: 1 !important;
}

.megaMenu .menu-contact-us ::-moz-placeholder {
  opacity: 1 !important;
}

.megaMenu .menu-contact-us ::-webkit-input-placeholder {
  opacity: 1 !important;
}

.megaMenu .menu-contact-us :-ms-input-placeholder {
  opacity: 1 !important;
}

.megaMenu .menu-contact-us textarea {
  resize: none;
  height: 6.25em;
  padding-top: 0.625em;
  margin-bottom: 1.25em;
}

.megaMenu .menu-contact-us .contact-buttons {
  /* ------------------ contact buttons */
  padding: 0.9375em 1.25em 0.5em;
  background: #333333;
  border-top: 1px solid #232323;
  display: block;
}

@media screen and (max-width: 991px) {
  .megaMenu .menu-contact-us .contact-buttons {
    background: #282828;
    border-color: #333333;
  }
}

.megaMenu .menu-contact-us button[type=submit], .megaMenu .menu-contact-us input[type=submit], .megaMenu .menu-contact-us button[type=reset], .megaMenu .menu-contact-us input[type=reset] {
  background: #282828;
  color: #262626;
  transition: background 300ms ease, color 300ms ease;
  font-size: 0.875em;
  font-weight: 600;
  height: 3.125em;
  padding-left: 1.25em;
  padding-right: 1.25em;
  display: inline-block;
  margin-right: 0.625em;
  position: relative;
  z-index: 10;
  overflow: hidden;
  border-radius: 0;
}

@media screen and (max-width: 991px) {
  .megaMenu .menu-contact-us button[type=submit], .megaMenu .menu-contact-us input[type=submit], .megaMenu .menu-contact-us button[type=reset], .megaMenu .menu-contact-us input[type=reset] {
    background: #333333;
    border: 1px solid #232323;
  }
}

.megaMenu .menu-contact-us button[type=submit]:hover, .megaMenu .menu-contact-us input[type=submit]:hover, .megaMenu .menu-contact-us button[type=reset]:hover, .megaMenu .menu-contact-us input[type=reset]:hover {
  color: #f44a56;
}

@media screen and (max-width: 991px) {
  .megaMenu .menu-contact-us button[type=submit], .megaMenu .menu-contact-us input[type=submit], .megaMenu .menu-contact-us button[type=reset], .megaMenu .menu-contact-us input[type=reset] {
    width: 100%;
    display: block;
    margin-bottom: 0.625em;
  }
  .megaMenu .menu-contact-us button[type=submit]:last-child, .megaMenu .menu-contact-us input[type=submit]:last-child, .megaMenu .menu-contact-us button[type=reset]:last-child, .megaMenu .menu-contact-us input[type=reset]:last-child {
    margin-bottom: 0;
  }
}

.megaMenu .menu-contact-us button[type=submit]:after, .megaMenu .menu-contact-us input[type=submit]:after, .megaMenu .menu-contact-us button[type=reset]:after, .megaMenu .menu-contact-us input[type=reset]:after {
  background: #232323;
}

@media screen and (max-width: 991px) {
  .megaMenu .menu-contact-us button[type=submit]:after, .megaMenu .menu-contact-us input[type=submit]:after, .megaMenu .menu-contact-us button[type=reset]:after, .megaMenu .menu-contact-us input[type=reset]:after {
    background: #303030;
  }
}

.megaMenu .menu-contact-us button[type=submit]:hover:after, .megaMenu .menu-contact-us input[type=submit]:hover:after, .megaMenu .menu-contact-us button[type=reset]:hover:after, .megaMenu .menu-contact-us input[type=reset]:hover:after {
  opacity: 1;
}

.megaMenu .menu-contact-us button[type=submit] .badge, .megaMenu .menu-contact-us input[type=submit] .badge, .megaMenu .menu-contact-us button[type=reset] .badge, .megaMenu .menu-contact-us input[type=reset] .badge {
  position: relative;
  left: 0.3125em;
}

.megaMenu .menu-contact-us button[type=submit] span.fa, .megaMenu .menu-contact-us input[type=submit] span.fa, .megaMenu .menu-contact-us button[type=reset] span.fa, .megaMenu .menu-contact-us input[type=reset] span.fa {
  font-family: FontAwesome, serif;
  position: relative;
  left: 0.3125em;
  display: none;
}

.megaMenu .menu-contact-us .contact-us-notification {
  font-size: 0.875em;
  color: #262626;
  margin-top: 0.625em;
  text-align: left;
}

.megaMenu .drop-down-medium, .megaMenu .drop-down-large {
  /* drop down large and medium size */
}

.megaMenu .drop-down-medium.breadcrumb, .megaMenu .drop-down-large.breadcrumb {
  /* tabs drop down */
  margin: 0;
  padding: 0.625em;
  border-radius: 0;
}

@media screen and (max-width: 991px) {
  .megaMenu .drop-down-medium.breadcrumb, .megaMenu .drop-down-large.breadcrumb {
    width: 100%;
  }
}

.megaMenu .drop-down-medium.breadcrumb .effect-div, .megaMenu .drop-down-large.breadcrumb .effect-div {
  display: none !important;
}

.megaMenu .drop-down-medium.breadcrumb > li, .megaMenu .drop-down-large.breadcrumb > li {
  margin: 0;
  padding: 0.3125em;
  display: block;
  float: left;
}

.megaMenu .drop-down-medium.breadcrumb > li:before, .megaMenu .drop-down-large.breadcrumb > li:before {
  color: #262626;
}

@media screen and (max-width: 991px) {
  .megaMenu .drop-down-medium.breadcrumb > li, .megaMenu .drop-down-large.breadcrumb > li {
    width: 100%;
  }
  .megaMenu .drop-down-medium.breadcrumb > li:before, .megaMenu .drop-down-large.breadcrumb > li:before {
    display: none;
  }
}

.megaMenu .drop-down-medium.breadcrumb > li.active, .megaMenu .drop-down-large.breadcrumb > li.active {
  color: #f44a56;
  font-weight: 600;
}

.megaMenu .drop-down-medium.breadcrumb > li > i.fa, .megaMenu .drop-down-large.breadcrumb > li > i.fa {
  padding-right: 0.3125em;
}

.megaMenu .drop-down-medium.breadcrumb > li > i.fa:nth-child(2), .megaMenu .drop-down-large.breadcrumb > li > i.fa:nth-child(2) {
  padding-left: 0.3125em;
  padding-right: 0;
}

.megaMenu .drop-down-medium.breadcrumb > li > a, .megaMenu .drop-down-large.breadcrumb > li > a {
  font-size: 0.9375em;
  border: none !important;
  font-weight: 600;
}

.megaMenu .drop-down-medium.breadcrumb > li > a > i.fa, .megaMenu .drop-down-large.breadcrumb > li > a > i.fa {
  padding-right: 0.3125em;
}

.megaMenu .drop-down-medium.breadcrumb > li > a > i.fa:nth-child(2), .megaMenu .drop-down-large.breadcrumb > li > a > i.fa:nth-child(2) {
  padding-left: 0.3125em;
  padding-right: 0;
}

.megaMenu[data-color='green'] {
  /* ---------------------------------- GREEN COLOR SCHEME */
}

.megaMenu[data-color='green'] ul.menu-search-bar-desktop > li > a > i.fa, .megaMenu[data-color='green'] ul.menu-search-bar-mobile > li > a > i.fa {
  background: #98cb00;
}

.megaMenu[data-color='green'] ul.menu-search-bar-desktop > li > a > i.fa:after, .megaMenu[data-color='green'] ul.menu-search-bar-mobile > li > a > i.fa:after {
  background: #85b200;
}

.megaMenu[data-color='green'] ul.menu-search-bar-desktop .drop-down, .megaMenu[data-color='green'] ul.menu-search-bar-mobile .drop-down {
  background: #98cb00;
}

.megaMenu[data-color='green'] ul.menu-search-bar-desktop button[type=submit], .megaMenu[data-color='green'] ul.menu-search-bar-mobile button[type=submit],
.megaMenu[data-color='green'] ul.menu-search-bar-desktop input[type=submit],
.megaMenu[data-color='green'] ul.menu-search-bar-mobile input[type=submit] {
  background: #79a200;
}

.megaMenu[data-color='green'] ul.menu-search-bar-desktop button[type=submit]:after, .megaMenu[data-color='green'] ul.menu-search-bar-mobile button[type=submit]:after,
.megaMenu[data-color='green'] ul.menu-search-bar-desktop input[type=submit]:after,
.megaMenu[data-color='green'] ul.menu-search-bar-mobile input[type=submit]:after {
  background: #729800 !important;
}

.megaMenu[data-color='green'] ul.menu-search-bar-desktop button[type=search], .megaMenu[data-color='green'] ul.menu-search-bar-mobile button[type=search], .megaMenu[data-color='green'] ul.menu-search-bar-desktop input[type=search], .megaMenu[data-color='green'] ul.menu-search-bar-mobile input[type=search] {
  background: #729800;
}

.megaMenu[data-color='green'] .menu-contact-us button[type=submit]:hover, .megaMenu[data-color='green'] .menu-contact-us input[type=submit]:hover, .megaMenu[data-color='green'] .menu-contact-us button[type=reset]:hover, .megaMenu[data-color='green'] .menu-contact-us input[type=reset]:hover {
  color: #98cb00;
}

.megaMenu[data-color='green'] .drop-down li:hover > a,
.megaMenu[data-color='green'] .drop-down li:hover > a .description {
  color: #98cb00;
}

.megaMenu[data-color='green'] .drop-down-medium img:hover, .megaMenu[data-color='green'] .drop-down-medium img:active, .megaMenu[data-color='green'] .drop-down-medium img:focus, .megaMenu[data-color='green'] .drop-down-large img:hover, .megaMenu[data-color='green'] .drop-down-large img:active, .megaMenu[data-color='green'] .drop-down-large img:focus {
  border-color: #98cb00;
}

.megaMenu[data-color='green'] .drop-down-medium .list-items ol li:hover:after, .megaMenu[data-color='green'] .drop-down-large .list-items ol li:hover:after {
  color: #98cb00;
}

.megaMenu[data-color='green'] .drop-down-medium .list-items a:hover, .megaMenu[data-color='green'] .drop-down-large .list-items a:hover {
  color: #98cb00;
}

.megaMenu[data-color='green'] .drop-down-medium a:hover, .megaMenu[data-color='green'] .drop-down-large a:hover {
  color: #98cb00;
}

.megaMenu[data-color='green'] .drop-down-medium .nav-tabs.vertical li.active a, .megaMenu[data-color='green'] .drop-down-medium .nav-tabs.vertical li:hover a, .megaMenu[data-color='green'] .drop-down-medium .nav-tabs.vertical li:active a, .megaMenu[data-color='green'] .drop-down-medium .nav-tabs.vertical li:focus a, .megaMenu[data-color='green'] .drop-down-large .nav-tabs.vertical li.active a, .megaMenu[data-color='green'] .drop-down-large .nav-tabs.vertical li:hover a, .megaMenu[data-color='green'] .drop-down-large .nav-tabs.vertical li:active a, .megaMenu[data-color='green'] .drop-down-large .nav-tabs.vertical li:focus a {
  color: #98cb00;
}

.megaMenu[data-color='green'] .drop-down-medium .nav-tabs.vertical a:hover, .megaMenu[data-color='green'] .drop-down-large .nav-tabs.vertical a:hover {
  color: #98cb00;
}

.megaMenu[data-color='green'] .drop-down-medium .nav-tabs.vertical.small.pull-right li.active, .megaMenu[data-color='green'] .drop-down-medium .nav-tabs.vertical.small.pull-right li:hover, .megaMenu[data-color='green'] .drop-down-medium .nav-tabs.vertical.small.pull-right li:active, .megaMenu[data-color='green'] .drop-down-medium .nav-tabs.vertical.small.pull-right li:focus, .megaMenu[data-color='green'] .drop-down-large .nav-tabs.vertical.small.pull-right li.active, .megaMenu[data-color='green'] .drop-down-large .nav-tabs.vertical.small.pull-right li:hover, .megaMenu[data-color='green'] .drop-down-large .nav-tabs.vertical.small.pull-right li:active, .megaMenu[data-color='green'] .drop-down-large .nav-tabs.vertical.small.pull-right li:focus {
  border-right-color: #98cb00;
}

@media screen and (max-width: 991px) {
  .megaMenu[data-color='green'] .drop-down-medium .nav-tabs.vertical.small.pull-right li.active, .megaMenu[data-color='green'] .drop-down-medium .nav-tabs.vertical.small.pull-right li:hover, .megaMenu[data-color='green'] .drop-down-medium .nav-tabs.vertical.small.pull-right li:active, .megaMenu[data-color='green'] .drop-down-medium .nav-tabs.vertical.small.pull-right li:focus, .megaMenu[data-color='green'] .drop-down-large .nav-tabs.vertical.small.pull-right li.active, .megaMenu[data-color='green'] .drop-down-large .nav-tabs.vertical.small.pull-right li:hover, .megaMenu[data-color='green'] .drop-down-large .nav-tabs.vertical.small.pull-right li:active, .megaMenu[data-color='green'] .drop-down-large .nav-tabs.vertical.small.pull-right li:focus {
    border-right-color: #232323;
  }
}

.megaMenu[data-color='green'] .drop-down-medium .nav-tabs.vertical.small li.active, .megaMenu[data-color='green'] .drop-down-medium .nav-tabs.vertical.small li:hover, .megaMenu[data-color='green'] .drop-down-medium .nav-tabs.vertical.small li:active, .megaMenu[data-color='green'] .drop-down-medium .nav-tabs.vertical.small li:focus, .megaMenu[data-color='green'] .drop-down-large .nav-tabs.vertical.small li.active, .megaMenu[data-color='green'] .drop-down-large .nav-tabs.vertical.small li:hover, .megaMenu[data-color='green'] .drop-down-large .nav-tabs.vertical.small li:active, .megaMenu[data-color='green'] .drop-down-large .nav-tabs.vertical.small li:focus {
  border-left-color: #98cb00;
  border-top-color: #232323;
}

@media screen and (max-width: 991px) {
  .megaMenu[data-color='green'] .drop-down-medium .nav-tabs.vertical.small li.active, .megaMenu[data-color='green'] .drop-down-medium .nav-tabs.vertical.small li:hover, .megaMenu[data-color='green'] .drop-down-medium .nav-tabs.vertical.small li:active, .megaMenu[data-color='green'] .drop-down-medium .nav-tabs.vertical.small li:focus, .megaMenu[data-color='green'] .drop-down-large .nav-tabs.vertical.small li.active, .megaMenu[data-color='green'] .drop-down-large .nav-tabs.vertical.small li:hover, .megaMenu[data-color='green'] .drop-down-large .nav-tabs.vertical.small li:active, .megaMenu[data-color='green'] .drop-down-large .nav-tabs.vertical.small li:focus {
    border-top-color: #98cb00;
    border-left-color: #232323;
  }
}

.megaMenu[data-color='green'] .drop-down-medium .nav-tabs li.active, .megaMenu[data-color='green'] .drop-down-medium .nav-tabs li:hover, .megaMenu[data-color='green'] .drop-down-medium .nav-tabs li:active, .megaMenu[data-color='green'] .drop-down-medium .nav-tabs li:focus, .megaMenu[data-color='green'] .drop-down-large .nav-tabs li.active, .megaMenu[data-color='green'] .drop-down-large .nav-tabs li:hover, .megaMenu[data-color='green'] .drop-down-large .nav-tabs li:active, .megaMenu[data-color='green'] .drop-down-large .nav-tabs li:focus {
  border-top-color: #98cb00;
}

.megaMenu[data-color='green'] .drop-down-medium .nav-tabs li.active a, .megaMenu[data-color='green'] .drop-down-medium .nav-tabs li:hover a, .megaMenu[data-color='green'] .drop-down-medium .nav-tabs li:active a, .megaMenu[data-color='green'] .drop-down-medium .nav-tabs li:focus a, .megaMenu[data-color='green'] .drop-down-large .nav-tabs li.active a, .megaMenu[data-color='green'] .drop-down-large .nav-tabs li:hover a, .megaMenu[data-color='green'] .drop-down-large .nav-tabs li:active a, .megaMenu[data-color='green'] .drop-down-large .nav-tabs li:focus a {
  color: #98cb00;
}

.megaMenu[data-color='green'] .drop-down-medium .btn-default:hover, .megaMenu[data-color='green'] .drop-down-medium .btn-default:active, .megaMenu[data-color='green'] .drop-down-medium .btn-default:focus, .megaMenu[data-color='green'] .drop-down-medium .btn:hover, .megaMenu[data-color='green'] .drop-down-medium .btn:active, .megaMenu[data-color='green'] .drop-down-medium .btn:focus, .megaMenu[data-color='green'] .drop-down-large .btn-default:hover, .megaMenu[data-color='green'] .drop-down-large .btn-default:active, .megaMenu[data-color='green'] .drop-down-large .btn-default:focus, .megaMenu[data-color='green'] .drop-down-large .btn:hover, .megaMenu[data-color='green'] .drop-down-large .btn:active, .megaMenu[data-color='green'] .drop-down-large .btn:focus {
  color: #98cb00;
}

.megaMenu[data-color='green'] .drop-down-medium .btn-default:hover .badge, .megaMenu[data-color='green'] .drop-down-medium .btn-default:active .badge, .megaMenu[data-color='green'] .drop-down-medium .btn-default:focus .badge, .megaMenu[data-color='green'] .drop-down-medium .btn:hover .badge, .megaMenu[data-color='green'] .drop-down-medium .btn:active .badge, .megaMenu[data-color='green'] .drop-down-medium .btn:focus .badge, .megaMenu[data-color='green'] .drop-down-large .btn-default:hover .badge, .megaMenu[data-color='green'] .drop-down-large .btn-default:active .badge, .megaMenu[data-color='green'] .drop-down-large .btn-default:focus .badge, .megaMenu[data-color='green'] .drop-down-large .btn:hover .badge, .megaMenu[data-color='green'] .drop-down-large .btn:active .badge, .megaMenu[data-color='green'] .drop-down-large .btn:focus .badge {
  color: #98cb00;
}

.megaMenu[data-color='green'] .drop-down-medium .panel .panel-title a:hover, .megaMenu[data-color='green'] .drop-down-large .panel .panel-title a:hover {
  color: #98cb00;
}

.megaMenu[data-color='green'] .drop-down-medium .carousel-row .carousel-indicators li, .megaMenu[data-color='green'] .drop-down-large .carousel-row .carousel-indicators li {
  border-color: #98cb00;
}

.megaMenu[data-color='green'] .drop-down-medium .carousel-row .carousel-indicators li.active, .megaMenu[data-color='green'] .drop-down-large .carousel-row .carousel-indicators li.active {
  background: #98cb00;
}

.megaMenu[data-color='green'] ul.menu-links > li.active > a {
  /* add active class on li element */
  color: #98cb00;
}

.megaMenu[data-color='green'] ul.menu-links > li:hover > a {
  color: #98cb00;
}

.megaMenu[data-color='green'] ul.menu-mobile-trigger:hover {
  color: #98cb00;
}

.megaMenu[data-color='green'] ul.menu-mobile-trigger:hover i.fa {
  color: #98cb00;
}

.megaMenu[data-color='green'] .drop-down-medium.breadcrumb > li.active, .megaMenu[data-color='green'] .drop-down-large.breadcrumb > li.active {
  color: #98cb00;
}

.megaMenu[data-color='green'] .menu-user-profile .profile-icons a:hover i.fa {
  color: #98cb00;
}

.megaMenu[data-color='orange'] {
  /* ---------------------------------- ORANGE COLOR SCHEME */
}

.megaMenu[data-color='orange'] ul.menu-search-bar-desktop > li > a > i.fa, .megaMenu[data-color='orange'] ul.menu-search-bar-mobile > li > a > i.fa {
  background: #fc9900;
}

.megaMenu[data-color='orange'] ul.menu-search-bar-desktop > li > a > i.fa:after, .megaMenu[data-color='orange'] ul.menu-search-bar-mobile > li > a > i.fa:after {
  background: #e38a00;
}

.megaMenu[data-color='orange'] ul.menu-search-bar-desktop .drop-down, .megaMenu[data-color='orange'] ul.menu-search-bar-mobile .drop-down {
  background: #fc9900;
}

.megaMenu[data-color='orange'] ul.menu-search-bar-desktop button[type=submit], .megaMenu[data-color='orange'] ul.menu-search-bar-mobile button[type=submit],
.megaMenu[data-color='orange'] ul.menu-search-bar-desktop input[type=submit],
.megaMenu[data-color='orange'] ul.menu-search-bar-mobile input[type=submit] {
  background: #d38000;
}

.megaMenu[data-color='orange'] ul.menu-search-bar-desktop button[type=submit]:after, .megaMenu[data-color='orange'] ul.menu-search-bar-mobile button[type=submit]:after,
.megaMenu[data-color='orange'] ul.menu-search-bar-desktop input[type=submit]:after,
.megaMenu[data-color='orange'] ul.menu-search-bar-mobile input[type=submit]:after {
  background: #c97a00 !important;
}

.megaMenu[data-color='orange'] ul.menu-search-bar-desktop button[type=search], .megaMenu[data-color='orange'] ul.menu-search-bar-mobile button[type=search], .megaMenu[data-color='orange'] ul.menu-search-bar-desktop input[type=search], .megaMenu[data-color='orange'] ul.menu-search-bar-mobile input[type=search] {
  background: #c97a00;
}

.megaMenu[data-color='orange'] .menu-contact-us button[type=submit]:hover, .megaMenu[data-color='orange'] .menu-contact-us input[type=submit]:hover, .megaMenu[data-color='orange'] .menu-contact-us button[type=reset]:hover, .megaMenu[data-color='orange'] .menu-contact-us input[type=reset]:hover {
  color: #fc9900;
}

.megaMenu[data-color='orange'] .drop-down li:hover > a,
.megaMenu[data-color='orange'] .drop-down li:hover > a .description {
  color: #fc9900;
}

.megaMenu[data-color='orange'] .drop-down-medium img:hover, .megaMenu[data-color='orange'] .drop-down-medium img:active, .megaMenu[data-color='orange'] .drop-down-medium img:focus, .megaMenu[data-color='orange'] .drop-down-large img:hover, .megaMenu[data-color='orange'] .drop-down-large img:active, .megaMenu[data-color='orange'] .drop-down-large img:focus {
  border-color: #fc9900;
}

.megaMenu[data-color='orange'] .drop-down-medium .list-items ol li:hover:after, .megaMenu[data-color='orange'] .drop-down-large .list-items ol li:hover:after {
  color: #fc9900;
}

.megaMenu[data-color='orange'] .drop-down-medium .list-items a:hover, .megaMenu[data-color='orange'] .drop-down-large .list-items a:hover {
  color: #fc9900;
}

.megaMenu[data-color='orange'] .drop-down-medium a:hover, .megaMenu[data-color='orange'] .drop-down-large a:hover {
  color: #fc9900;
}

.megaMenu[data-color='orange'] .drop-down-medium .nav-tabs.vertical li.active a, .megaMenu[data-color='orange'] .drop-down-medium .nav-tabs.vertical li:hover a, .megaMenu[data-color='orange'] .drop-down-medium .nav-tabs.vertical li:active a, .megaMenu[data-color='orange'] .drop-down-medium .nav-tabs.vertical li:focus a, .megaMenu[data-color='orange'] .drop-down-large .nav-tabs.vertical li.active a, .megaMenu[data-color='orange'] .drop-down-large .nav-tabs.vertical li:hover a, .megaMenu[data-color='orange'] .drop-down-large .nav-tabs.vertical li:active a, .megaMenu[data-color='orange'] .drop-down-large .nav-tabs.vertical li:focus a {
  color: #fc9900;
}

.megaMenu[data-color='orange'] .drop-down-medium .nav-tabs.vertical a:hover, .megaMenu[data-color='orange'] .drop-down-large .nav-tabs.vertical a:hover {
  color: #fc9900;
}

.megaMenu[data-color='orange'] .drop-down-medium .nav-tabs.vertical.small.pull-right li.active, .megaMenu[data-color='orange'] .drop-down-medium .nav-tabs.vertical.small.pull-right li:hover, .megaMenu[data-color='orange'] .drop-down-medium .nav-tabs.vertical.small.pull-right li:active, .megaMenu[data-color='orange'] .drop-down-medium .nav-tabs.vertical.small.pull-right li:focus, .megaMenu[data-color='orange'] .drop-down-large .nav-tabs.vertical.small.pull-right li.active, .megaMenu[data-color='orange'] .drop-down-large .nav-tabs.vertical.small.pull-right li:hover, .megaMenu[data-color='orange'] .drop-down-large .nav-tabs.vertical.small.pull-right li:active, .megaMenu[data-color='orange'] .drop-down-large .nav-tabs.vertical.small.pull-right li:focus {
  border-right-color: #fc9900;
}

@media screen and (max-width: 991px) {
  .megaMenu[data-color='orange'] .drop-down-medium .nav-tabs.vertical.small.pull-right li.active, .megaMenu[data-color='orange'] .drop-down-medium .nav-tabs.vertical.small.pull-right li:hover, .megaMenu[data-color='orange'] .drop-down-medium .nav-tabs.vertical.small.pull-right li:active, .megaMenu[data-color='orange'] .drop-down-medium .nav-tabs.vertical.small.pull-right li:focus, .megaMenu[data-color='orange'] .drop-down-large .nav-tabs.vertical.small.pull-right li.active, .megaMenu[data-color='orange'] .drop-down-large .nav-tabs.vertical.small.pull-right li:hover, .megaMenu[data-color='orange'] .drop-down-large .nav-tabs.vertical.small.pull-right li:active, .megaMenu[data-color='orange'] .drop-down-large .nav-tabs.vertical.small.pull-right li:focus {
    border-right-color: #232323;
  }
}

.megaMenu[data-color='orange'] .drop-down-medium .nav-tabs.vertical.small li.active, .megaMenu[data-color='orange'] .drop-down-medium .nav-tabs.vertical.small li:hover, .megaMenu[data-color='orange'] .drop-down-medium .nav-tabs.vertical.small li:active, .megaMenu[data-color='orange'] .drop-down-medium .nav-tabs.vertical.small li:focus, .megaMenu[data-color='orange'] .drop-down-large .nav-tabs.vertical.small li.active, .megaMenu[data-color='orange'] .drop-down-large .nav-tabs.vertical.small li:hover, .megaMenu[data-color='orange'] .drop-down-large .nav-tabs.vertical.small li:active, .megaMenu[data-color='orange'] .drop-down-large .nav-tabs.vertical.small li:focus {
  border-left-color: #fc9900;
  border-top-color: #232323;
}

@media screen and (max-width: 991px) {
  .megaMenu[data-color='orange'] .drop-down-medium .nav-tabs.vertical.small li.active, .megaMenu[data-color='orange'] .drop-down-medium .nav-tabs.vertical.small li:hover, .megaMenu[data-color='orange'] .drop-down-medium .nav-tabs.vertical.small li:active, .megaMenu[data-color='orange'] .drop-down-medium .nav-tabs.vertical.small li:focus, .megaMenu[data-color='orange'] .drop-down-large .nav-tabs.vertical.small li.active, .megaMenu[data-color='orange'] .drop-down-large .nav-tabs.vertical.small li:hover, .megaMenu[data-color='orange'] .drop-down-large .nav-tabs.vertical.small li:active, .megaMenu[data-color='orange'] .drop-down-large .nav-tabs.vertical.small li:focus {
    border-top-color: #fc9900;
    border-left-color: #232323;
  }
}

.megaMenu[data-color='orange'] .drop-down-medium .nav-tabs li.active, .megaMenu[data-color='orange'] .drop-down-medium .nav-tabs li:hover, .megaMenu[data-color='orange'] .drop-down-medium .nav-tabs li:active, .megaMenu[data-color='orange'] .drop-down-medium .nav-tabs li:focus, .megaMenu[data-color='orange'] .drop-down-large .nav-tabs li.active, .megaMenu[data-color='orange'] .drop-down-large .nav-tabs li:hover, .megaMenu[data-color='orange'] .drop-down-large .nav-tabs li:active, .megaMenu[data-color='orange'] .drop-down-large .nav-tabs li:focus {
  border-top-color: #fc9900;
}

.megaMenu[data-color='orange'] .drop-down-medium .nav-tabs li.active a, .megaMenu[data-color='orange'] .drop-down-medium .nav-tabs li:hover a, .megaMenu[data-color='orange'] .drop-down-medium .nav-tabs li:active a, .megaMenu[data-color='orange'] .drop-down-medium .nav-tabs li:focus a, .megaMenu[data-color='orange'] .drop-down-large .nav-tabs li.active a, .megaMenu[data-color='orange'] .drop-down-large .nav-tabs li:hover a, .megaMenu[data-color='orange'] .drop-down-large .nav-tabs li:active a, .megaMenu[data-color='orange'] .drop-down-large .nav-tabs li:focus a {
  color: #fc9900;
}

.megaMenu[data-color='orange'] .drop-down-medium .btn-default:hover, .megaMenu[data-color='orange'] .drop-down-medium .btn-default:active, .megaMenu[data-color='orange'] .drop-down-medium .btn-default:focus, .megaMenu[data-color='orange'] .drop-down-medium .btn:hover, .megaMenu[data-color='orange'] .drop-down-medium .btn:active, .megaMenu[data-color='orange'] .drop-down-medium .btn:focus, .megaMenu[data-color='orange'] .drop-down-large .btn-default:hover, .megaMenu[data-color='orange'] .drop-down-large .btn-default:active, .megaMenu[data-color='orange'] .drop-down-large .btn-default:focus, .megaMenu[data-color='orange'] .drop-down-large .btn:hover, .megaMenu[data-color='orange'] .drop-down-large .btn:active, .megaMenu[data-color='orange'] .drop-down-large .btn:focus {
  color: #fc9900;
}

.megaMenu[data-color='orange'] .drop-down-medium .btn-default:hover .badge, .megaMenu[data-color='orange'] .drop-down-medium .btn-default:active .badge, .megaMenu[data-color='orange'] .drop-down-medium .btn-default:focus .badge, .megaMenu[data-color='orange'] .drop-down-medium .btn:hover .badge, .megaMenu[data-color='orange'] .drop-down-medium .btn:active .badge, .megaMenu[data-color='orange'] .drop-down-medium .btn:focus .badge, .megaMenu[data-color='orange'] .drop-down-large .btn-default:hover .badge, .megaMenu[data-color='orange'] .drop-down-large .btn-default:active .badge, .megaMenu[data-color='orange'] .drop-down-large .btn-default:focus .badge, .megaMenu[data-color='orange'] .drop-down-large .btn:hover .badge, .megaMenu[data-color='orange'] .drop-down-large .btn:active .badge, .megaMenu[data-color='orange'] .drop-down-large .btn:focus .badge {
  color: #fc9900;
}

.megaMenu[data-color='orange'] .drop-down-medium .panel .panel-title a:hover, .megaMenu[data-color='orange'] .drop-down-large .panel .panel-title a:hover {
  color: #fc9900;
}

.megaMenu[data-color='orange'] .drop-down-medium .carousel-row .carousel-indicators li, .megaMenu[data-color='orange'] .drop-down-large .carousel-row .carousel-indicators li {
  border-color: #fc9900;
}

.megaMenu[data-color='orange'] .drop-down-medium .carousel-row .carousel-indicators li.active, .megaMenu[data-color='orange'] .drop-down-large .carousel-row .carousel-indicators li.active {
  background: #fc9900;
}

.megaMenu[data-color='orange'] ul.menu-links > li.active > a {
  /* add active class on li element */
  color: #fc9900;
}

.megaMenu[data-color='orange'] ul.menu-links > li:hover > a {
  color: #fc9900;
}

.megaMenu[data-color='orange'] ul.menu-mobile-trigger:hover {
  color: #fc9900;
}

.megaMenu[data-color='orange'] ul.menu-mobile-trigger:hover i.fa {
  color: #fc9900;
}

.megaMenu[data-color='orange'] .drop-down-medium.breadcrumb > li.active, .megaMenu[data-color='orange'] .drop-down-large.breadcrumb > li.active {
  color: #fc9900;
}

.megaMenu[data-color='orange'] .menu-user-profile .profile-icons a:hover i.fa {
  color: #fc9900;
}

.megaMenu[data-color='purple'] {
  /* ---------------------------------- ORANGE COLOR SCHEME */
}

.megaMenu[data-color='purple'] ul.menu-search-bar-desktop > li > a > i.fa, .megaMenu[data-color='purple'] ul.menu-search-bar-mobile > li > a > i.fa {
  background: #9191d9;
}

.megaMenu[data-color='purple'] ul.menu-search-bar-desktop > li > a > i.fa:after, .megaMenu[data-color='purple'] ul.menu-search-bar-mobile > li > a > i.fa:after {
  background: #7e7ed2;
}

.megaMenu[data-color='purple'] ul.menu-search-bar-desktop .drop-down, .megaMenu[data-color='purple'] ul.menu-search-bar-mobile .drop-down {
  background: #9191d9;
}

.megaMenu[data-color='purple'] ul.menu-search-bar-desktop button[type=submit], .megaMenu[data-color='purple'] ul.menu-search-bar-mobile button[type=submit],
.megaMenu[data-color='purple'] ul.menu-search-bar-desktop input[type=submit],
.megaMenu[data-color='purple'] ul.menu-search-bar-mobile input[type=submit] {
  background: #7373cf;
}

.megaMenu[data-color='purple'] ul.menu-search-bar-desktop button[type=submit]:after, .megaMenu[data-color='purple'] ul.menu-search-bar-mobile button[type=submit]:after,
.megaMenu[data-color='purple'] ul.menu-search-bar-desktop input[type=submit]:after,
.megaMenu[data-color='purple'] ul.menu-search-bar-mobile input[type=submit]:after {
  background: #6b6bcc !important;
}

.megaMenu[data-color='purple'] ul.menu-search-bar-desktop button[type=search], .megaMenu[data-color='purple'] ul.menu-search-bar-mobile button[type=search], .megaMenu[data-color='purple'] ul.menu-search-bar-desktop input[type=search], .megaMenu[data-color='purple'] ul.menu-search-bar-mobile input[type=search] {
  background: #6b6bcc;
}

.megaMenu[data-color='purple'] .menu-contact-us button[type=submit]:hover, .megaMenu[data-color='purple'] .menu-contact-us input[type=submit]:hover, .megaMenu[data-color='purple'] .menu-contact-us button[type=reset]:hover, .megaMenu[data-color='purple'] .menu-contact-us input[type=reset]:hover {
  color: #9191d9;
}

.megaMenu[data-color='purple'] .drop-down li:hover > a,
.megaMenu[data-color='purple'] .drop-down li:hover > a .description {
  color: #9191d9;
}

.megaMenu[data-color='purple'] .drop-down-medium img:hover, .megaMenu[data-color='purple'] .drop-down-medium img:active, .megaMenu[data-color='purple'] .drop-down-medium img:focus, .megaMenu[data-color='purple'] .drop-down-large img:hover, .megaMenu[data-color='purple'] .drop-down-large img:active, .megaMenu[data-color='purple'] .drop-down-large img:focus {
  border-color: #9191d9;
}

.megaMenu[data-color='purple'] .drop-down-medium .list-items ol li:hover:after, .megaMenu[data-color='purple'] .drop-down-large .list-items ol li:hover:after {
  color: #9191d9;
}

.megaMenu[data-color='purple'] .drop-down-medium .list-items a:hover, .megaMenu[data-color='purple'] .drop-down-large .list-items a:hover {
  color: #9191d9;
}

.megaMenu[data-color='purple'] .drop-down-medium a:hover, .megaMenu[data-color='purple'] .drop-down-large a:hover {
  color: #9191d9;
}

.megaMenu[data-color='purple'] .drop-down-medium .nav-tabs.vertical li.active a, .megaMenu[data-color='purple'] .drop-down-medium .nav-tabs.vertical li:hover a, .megaMenu[data-color='purple'] .drop-down-medium .nav-tabs.vertical li:active a, .megaMenu[data-color='purple'] .drop-down-medium .nav-tabs.vertical li:focus a, .megaMenu[data-color='purple'] .drop-down-large .nav-tabs.vertical li.active a, .megaMenu[data-color='purple'] .drop-down-large .nav-tabs.vertical li:hover a, .megaMenu[data-color='purple'] .drop-down-large .nav-tabs.vertical li:active a, .megaMenu[data-color='purple'] .drop-down-large .nav-tabs.vertical li:focus a {
  color: #9191d9;
}

.megaMenu[data-color='purple'] .drop-down-medium .nav-tabs.vertical a:hover, .megaMenu[data-color='purple'] .drop-down-large .nav-tabs.vertical a:hover {
  color: #9191d9;
}

.megaMenu[data-color='purple'] .drop-down-medium .nav-tabs.vertical.small.pull-right li.active, .megaMenu[data-color='purple'] .drop-down-medium .nav-tabs.vertical.small.pull-right li:hover, .megaMenu[data-color='purple'] .drop-down-medium .nav-tabs.vertical.small.pull-right li:active, .megaMenu[data-color='purple'] .drop-down-medium .nav-tabs.vertical.small.pull-right li:focus, .megaMenu[data-color='purple'] .drop-down-large .nav-tabs.vertical.small.pull-right li.active, .megaMenu[data-color='purple'] .drop-down-large .nav-tabs.vertical.small.pull-right li:hover, .megaMenu[data-color='purple'] .drop-down-large .nav-tabs.vertical.small.pull-right li:active, .megaMenu[data-color='purple'] .drop-down-large .nav-tabs.vertical.small.pull-right li:focus {
  border-right-color: #9191d9;
}

@media screen and (max-width: 991px) {
  .megaMenu[data-color='purple'] .drop-down-medium .nav-tabs.vertical.small.pull-right li.active, .megaMenu[data-color='purple'] .drop-down-medium .nav-tabs.vertical.small.pull-right li:hover, .megaMenu[data-color='purple'] .drop-down-medium .nav-tabs.vertical.small.pull-right li:active, .megaMenu[data-color='purple'] .drop-down-medium .nav-tabs.vertical.small.pull-right li:focus, .megaMenu[data-color='purple'] .drop-down-large .nav-tabs.vertical.small.pull-right li.active, .megaMenu[data-color='purple'] .drop-down-large .nav-tabs.vertical.small.pull-right li:hover, .megaMenu[data-color='purple'] .drop-down-large .nav-tabs.vertical.small.pull-right li:active, .megaMenu[data-color='purple'] .drop-down-large .nav-tabs.vertical.small.pull-right li:focus {
    border-right-color: #232323;
  }
}

.megaMenu[data-color='purple'] .drop-down-medium .nav-tabs.vertical.small li.active, .megaMenu[data-color='purple'] .drop-down-medium .nav-tabs.vertical.small li:hover, .megaMenu[data-color='purple'] .drop-down-medium .nav-tabs.vertical.small li:active, .megaMenu[data-color='purple'] .drop-down-medium .nav-tabs.vertical.small li:focus, .megaMenu[data-color='purple'] .drop-down-large .nav-tabs.vertical.small li.active, .megaMenu[data-color='purple'] .drop-down-large .nav-tabs.vertical.small li:hover, .megaMenu[data-color='purple'] .drop-down-large .nav-tabs.vertical.small li:active, .megaMenu[data-color='purple'] .drop-down-large .nav-tabs.vertical.small li:focus {
  border-left-color: #9191d9;
  border-top-color: #232323;
}

@media screen and (max-width: 991px) {
  .megaMenu[data-color='purple'] .drop-down-medium .nav-tabs.vertical.small li.active, .megaMenu[data-color='purple'] .drop-down-medium .nav-tabs.vertical.small li:hover, .megaMenu[data-color='purple'] .drop-down-medium .nav-tabs.vertical.small li:active, .megaMenu[data-color='purple'] .drop-down-medium .nav-tabs.vertical.small li:focus, .megaMenu[data-color='purple'] .drop-down-large .nav-tabs.vertical.small li.active, .megaMenu[data-color='purple'] .drop-down-large .nav-tabs.vertical.small li:hover, .megaMenu[data-color='purple'] .drop-down-large .nav-tabs.vertical.small li:active, .megaMenu[data-color='purple'] .drop-down-large .nav-tabs.vertical.small li:focus {
    border-top-color: #9191d9;
    border-left-color: #232323;
  }
}

.megaMenu[data-color='purple'] .drop-down-medium .nav-tabs li.active, .megaMenu[data-color='purple'] .drop-down-medium .nav-tabs li:hover, .megaMenu[data-color='purple'] .drop-down-medium .nav-tabs li:active, .megaMenu[data-color='purple'] .drop-down-medium .nav-tabs li:focus, .megaMenu[data-color='purple'] .drop-down-large .nav-tabs li.active, .megaMenu[data-color='purple'] .drop-down-large .nav-tabs li:hover, .megaMenu[data-color='purple'] .drop-down-large .nav-tabs li:active, .megaMenu[data-color='purple'] .drop-down-large .nav-tabs li:focus {
  border-top-color: #9191d9;
}

.megaMenu[data-color='purple'] .drop-down-medium .nav-tabs li.active a, .megaMenu[data-color='purple'] .drop-down-medium .nav-tabs li:hover a, .megaMenu[data-color='purple'] .drop-down-medium .nav-tabs li:active a, .megaMenu[data-color='purple'] .drop-down-medium .nav-tabs li:focus a, .megaMenu[data-color='purple'] .drop-down-large .nav-tabs li.active a, .megaMenu[data-color='purple'] .drop-down-large .nav-tabs li:hover a, .megaMenu[data-color='purple'] .drop-down-large .nav-tabs li:active a, .megaMenu[data-color='purple'] .drop-down-large .nav-tabs li:focus a {
  color: #9191d9;
}

.megaMenu[data-color='purple'] .drop-down-medium .btn-default:hover, .megaMenu[data-color='purple'] .drop-down-medium .btn-default:active, .megaMenu[data-color='purple'] .drop-down-medium .btn-default:focus, .megaMenu[data-color='purple'] .drop-down-medium .btn:hover, .megaMenu[data-color='purple'] .drop-down-medium .btn:active, .megaMenu[data-color='purple'] .drop-down-medium .btn:focus, .megaMenu[data-color='purple'] .drop-down-large .btn-default:hover, .megaMenu[data-color='purple'] .drop-down-large .btn-default:active, .megaMenu[data-color='purple'] .drop-down-large .btn-default:focus, .megaMenu[data-color='purple'] .drop-down-large .btn:hover, .megaMenu[data-color='purple'] .drop-down-large .btn:active, .megaMenu[data-color='purple'] .drop-down-large .btn:focus {
  color: #9191d9;
}

.megaMenu[data-color='purple'] .drop-down-medium .btn-default:hover .badge, .megaMenu[data-color='purple'] .drop-down-medium .btn-default:active .badge, .megaMenu[data-color='purple'] .drop-down-medium .btn-default:focus .badge, .megaMenu[data-color='purple'] .drop-down-medium .btn:hover .badge, .megaMenu[data-color='purple'] .drop-down-medium .btn:active .badge, .megaMenu[data-color='purple'] .drop-down-medium .btn:focus .badge, .megaMenu[data-color='purple'] .drop-down-large .btn-default:hover .badge, .megaMenu[data-color='purple'] .drop-down-large .btn-default:active .badge, .megaMenu[data-color='purple'] .drop-down-large .btn-default:focus .badge, .megaMenu[data-color='purple'] .drop-down-large .btn:hover .badge, .megaMenu[data-color='purple'] .drop-down-large .btn:active .badge, .megaMenu[data-color='purple'] .drop-down-large .btn:focus .badge {
  color: #9191d9;
}

.megaMenu[data-color='purple'] .drop-down-medium .panel .panel-title a:hover, .megaMenu[data-color='purple'] .drop-down-large .panel .panel-title a:hover {
  color: #9191d9;
}

.megaMenu[data-color='purple'] .drop-down-medium .carousel-row .carousel-indicators li, .megaMenu[data-color='purple'] .drop-down-large .carousel-row .carousel-indicators li {
  border-color: #9191d9;
}

.megaMenu[data-color='purple'] .drop-down-medium .carousel-row .carousel-indicators li.active, .megaMenu[data-color='purple'] .drop-down-large .carousel-row .carousel-indicators li.active {
  background: #9191d9;
}

.megaMenu[data-color='purple'] ul.menu-links > li.active > a {
  /* add active class on li element */
  color: #9191d9;
}

.megaMenu[data-color='purple'] ul.menu-links > li:hover > a {
  color: #9191d9;
}

.megaMenu[data-color='purple'] ul.menu-mobile-trigger:hover {
  color: #9191d9;
}

.megaMenu[data-color='purple'] ul.menu-mobile-trigger:hover i.fa {
  color: #9191d9;
}

.megaMenu[data-color='purple'] .drop-down-medium.breadcrumb > li.active, .megaMenu[data-color='purple'] .drop-down-large.breadcrumb > li.active {
  color: #9191d9;
}

.megaMenu[data-color='purple'] .menu-user-profile .profile-icons a:hover i.fa {
  color: #9191d9;
}

.megaMenu[data-color='grey'] {
  /* ---------------------------------- ORANGE COLOR SCHEME */
}

.megaMenu[data-color='grey'] ul.menu-search-bar-desktop > li > a > i.fa, .megaMenu[data-color='grey'] ul.menu-search-bar-mobile > li > a > i.fa {
  background: #aeaeac;
}

.megaMenu[data-color='grey'] ul.menu-search-bar-desktop > li > a > i.fa:after, .megaMenu[data-color='grey'] ul.menu-search-bar-mobile > li > a > i.fa:after {
  background: #a1a19f;
}

.megaMenu[data-color='grey'] ul.menu-search-bar-desktop .drop-down, .megaMenu[data-color='grey'] ul.menu-search-bar-mobile .drop-down {
  background: #aeaeac;
}

.megaMenu[data-color='grey'] ul.menu-search-bar-desktop button[type=submit], .megaMenu[data-color='grey'] ul.menu-search-bar-mobile button[type=submit],
.megaMenu[data-color='grey'] ul.menu-search-bar-desktop input[type=submit],
.megaMenu[data-color='grey'] ul.menu-search-bar-mobile input[type=submit] {
  background: #9a9a97;
}

.megaMenu[data-color='grey'] ul.menu-search-bar-desktop button[type=submit]:after, .megaMenu[data-color='grey'] ul.menu-search-bar-mobile button[type=submit]:after,
.megaMenu[data-color='grey'] ul.menu-search-bar-desktop input[type=submit]:after,
.megaMenu[data-color='grey'] ul.menu-search-bar-mobile input[type=submit]:after {
  background: #959592 !important;
}

.megaMenu[data-color='grey'] ul.menu-search-bar-desktop button[type=search], .megaMenu[data-color='grey'] ul.menu-search-bar-mobile button[type=search], .megaMenu[data-color='grey'] ul.menu-search-bar-desktop input[type=search], .megaMenu[data-color='grey'] ul.menu-search-bar-mobile input[type=search] {
  background: #959592;
}

.megaMenu[data-color='grey'] .menu-contact-us button[type=submit]:hover, .megaMenu[data-color='grey'] .menu-contact-us input[type=submit]:hover, .megaMenu[data-color='grey'] .menu-contact-us button[type=reset]:hover, .megaMenu[data-color='grey'] .menu-contact-us input[type=reset]:hover {
  color: #aeaeac;
}

.megaMenu[data-color='grey'] .drop-down li:hover > a,
.megaMenu[data-color='grey'] .drop-down li:hover > a .description {
  color: #aeaeac;
}

.megaMenu[data-color='grey'] .drop-down-medium img:hover, .megaMenu[data-color='grey'] .drop-down-medium img:active, .megaMenu[data-color='grey'] .drop-down-medium img:focus, .megaMenu[data-color='grey'] .drop-down-large img:hover, .megaMenu[data-color='grey'] .drop-down-large img:active, .megaMenu[data-color='grey'] .drop-down-large img:focus {
  border-color: #aeaeac;
}

.megaMenu[data-color='grey'] .drop-down-medium .list-items ol li:hover:after, .megaMenu[data-color='grey'] .drop-down-large .list-items ol li:hover:after {
  color: #aeaeac;
}

.megaMenu[data-color='grey'] .drop-down-medium .list-items a:hover, .megaMenu[data-color='grey'] .drop-down-large .list-items a:hover {
  color: #aeaeac;
}

.megaMenu[data-color='grey'] .drop-down-medium a:hover, .megaMenu[data-color='grey'] .drop-down-large a:hover {
  color: #aeaeac;
}

.megaMenu[data-color='grey'] .drop-down-medium .nav-tabs.vertical li.active a, .megaMenu[data-color='grey'] .drop-down-medium .nav-tabs.vertical li:hover a, .megaMenu[data-color='grey'] .drop-down-medium .nav-tabs.vertical li:active a, .megaMenu[data-color='grey'] .drop-down-medium .nav-tabs.vertical li:focus a, .megaMenu[data-color='grey'] .drop-down-large .nav-tabs.vertical li.active a, .megaMenu[data-color='grey'] .drop-down-large .nav-tabs.vertical li:hover a, .megaMenu[data-color='grey'] .drop-down-large .nav-tabs.vertical li:active a, .megaMenu[data-color='grey'] .drop-down-large .nav-tabs.vertical li:focus a {
  color: #aeaeac;
}

.megaMenu[data-color='grey'] .drop-down-medium .nav-tabs.vertical a:hover, .megaMenu[data-color='grey'] .drop-down-large .nav-tabs.vertical a:hover {
  color: #aeaeac;
}

.megaMenu[data-color='grey'] .drop-down-medium .nav-tabs.vertical.small.pull-right li.active, .megaMenu[data-color='grey'] .drop-down-medium .nav-tabs.vertical.small.pull-right li:hover, .megaMenu[data-color='grey'] .drop-down-medium .nav-tabs.vertical.small.pull-right li:active, .megaMenu[data-color='grey'] .drop-down-medium .nav-tabs.vertical.small.pull-right li:focus, .megaMenu[data-color='grey'] .drop-down-large .nav-tabs.vertical.small.pull-right li.active, .megaMenu[data-color='grey'] .drop-down-large .nav-tabs.vertical.small.pull-right li:hover, .megaMenu[data-color='grey'] .drop-down-large .nav-tabs.vertical.small.pull-right li:active, .megaMenu[data-color='grey'] .drop-down-large .nav-tabs.vertical.small.pull-right li:focus {
  border-right-color: #aeaeac;
}

@media screen and (max-width: 991px) {
  .megaMenu[data-color='grey'] .drop-down-medium .nav-tabs.vertical.small.pull-right li.active, .megaMenu[data-color='grey'] .drop-down-medium .nav-tabs.vertical.small.pull-right li:hover, .megaMenu[data-color='grey'] .drop-down-medium .nav-tabs.vertical.small.pull-right li:active, .megaMenu[data-color='grey'] .drop-down-medium .nav-tabs.vertical.small.pull-right li:focus, .megaMenu[data-color='grey'] .drop-down-large .nav-tabs.vertical.small.pull-right li.active, .megaMenu[data-color='grey'] .drop-down-large .nav-tabs.vertical.small.pull-right li:hover, .megaMenu[data-color='grey'] .drop-down-large .nav-tabs.vertical.small.pull-right li:active, .megaMenu[data-color='grey'] .drop-down-large .nav-tabs.vertical.small.pull-right li:focus {
    border-right-color: #232323;
  }
}

.megaMenu[data-color='grey'] .drop-down-medium .nav-tabs.vertical.small li.active, .megaMenu[data-color='grey'] .drop-down-medium .nav-tabs.vertical.small li:hover, .megaMenu[data-color='grey'] .drop-down-medium .nav-tabs.vertical.small li:active, .megaMenu[data-color='grey'] .drop-down-medium .nav-tabs.vertical.small li:focus, .megaMenu[data-color='grey'] .drop-down-large .nav-tabs.vertical.small li.active, .megaMenu[data-color='grey'] .drop-down-large .nav-tabs.vertical.small li:hover, .megaMenu[data-color='grey'] .drop-down-large .nav-tabs.vertical.small li:active, .megaMenu[data-color='grey'] .drop-down-large .nav-tabs.vertical.small li:focus {
  border-left-color: #aeaeac;
  border-top-color: #232323;
}

@media screen and (max-width: 991px) {
  .megaMenu[data-color='grey'] .drop-down-medium .nav-tabs.vertical.small li.active, .megaMenu[data-color='grey'] .drop-down-medium .nav-tabs.vertical.small li:hover, .megaMenu[data-color='grey'] .drop-down-medium .nav-tabs.vertical.small li:active, .megaMenu[data-color='grey'] .drop-down-medium .nav-tabs.vertical.small li:focus, .megaMenu[data-color='grey'] .drop-down-large .nav-tabs.vertical.small li.active, .megaMenu[data-color='grey'] .drop-down-large .nav-tabs.vertical.small li:hover, .megaMenu[data-color='grey'] .drop-down-large .nav-tabs.vertical.small li:active, .megaMenu[data-color='grey'] .drop-down-large .nav-tabs.vertical.small li:focus {
    border-top-color: #aeaeac;
    border-left-color: #232323;
  }
}

.megaMenu[data-color='grey'] .drop-down-medium .nav-tabs li.active, .megaMenu[data-color='grey'] .drop-down-medium .nav-tabs li:hover, .megaMenu[data-color='grey'] .drop-down-medium .nav-tabs li:active, .megaMenu[data-color='grey'] .drop-down-medium .nav-tabs li:focus, .megaMenu[data-color='grey'] .drop-down-large .nav-tabs li.active, .megaMenu[data-color='grey'] .drop-down-large .nav-tabs li:hover, .megaMenu[data-color='grey'] .drop-down-large .nav-tabs li:active, .megaMenu[data-color='grey'] .drop-down-large .nav-tabs li:focus {
  border-top-color: #aeaeac;
}

.megaMenu[data-color='grey'] .drop-down-medium .nav-tabs li.active a, .megaMenu[data-color='grey'] .drop-down-medium .nav-tabs li:hover a, .megaMenu[data-color='grey'] .drop-down-medium .nav-tabs li:active a, .megaMenu[data-color='grey'] .drop-down-medium .nav-tabs li:focus a, .megaMenu[data-color='grey'] .drop-down-large .nav-tabs li.active a, .megaMenu[data-color='grey'] .drop-down-large .nav-tabs li:hover a, .megaMenu[data-color='grey'] .drop-down-large .nav-tabs li:active a, .megaMenu[data-color='grey'] .drop-down-large .nav-tabs li:focus a {
  color: #aeaeac;
}

.megaMenu[data-color='grey'] .drop-down-medium .btn-default:hover, .megaMenu[data-color='grey'] .drop-down-medium .btn-default:active, .megaMenu[data-color='grey'] .drop-down-medium .btn-default:focus, .megaMenu[data-color='grey'] .drop-down-medium .btn:hover, .megaMenu[data-color='grey'] .drop-down-medium .btn:active, .megaMenu[data-color='grey'] .drop-down-medium .btn:focus, .megaMenu[data-color='grey'] .drop-down-large .btn-default:hover, .megaMenu[data-color='grey'] .drop-down-large .btn-default:active, .megaMenu[data-color='grey'] .drop-down-large .btn-default:focus, .megaMenu[data-color='grey'] .drop-down-large .btn:hover, .megaMenu[data-color='grey'] .drop-down-large .btn:active, .megaMenu[data-color='grey'] .drop-down-large .btn:focus {
  color: #aeaeac;
}

.megaMenu[data-color='grey'] .drop-down-medium .btn-default:hover .badge, .megaMenu[data-color='grey'] .drop-down-medium .btn-default:active .badge, .megaMenu[data-color='grey'] .drop-down-medium .btn-default:focus .badge, .megaMenu[data-color='grey'] .drop-down-medium .btn:hover .badge, .megaMenu[data-color='grey'] .drop-down-medium .btn:active .badge, .megaMenu[data-color='grey'] .drop-down-medium .btn:focus .badge, .megaMenu[data-color='grey'] .drop-down-large .btn-default:hover .badge, .megaMenu[data-color='grey'] .drop-down-large .btn-default:active .badge, .megaMenu[data-color='grey'] .drop-down-large .btn-default:focus .badge, .megaMenu[data-color='grey'] .drop-down-large .btn:hover .badge, .megaMenu[data-color='grey'] .drop-down-large .btn:active .badge, .megaMenu[data-color='grey'] .drop-down-large .btn:focus .badge {
  color: #aeaeac;
}

.megaMenu[data-color='grey'] .drop-down-medium .panel .panel-title a:hover, .megaMenu[data-color='grey'] .drop-down-large .panel .panel-title a:hover {
  color: #aeaeac;
}

.megaMenu[data-color='grey'] .drop-down-medium .carousel-row .carousel-indicators li, .megaMenu[data-color='grey'] .drop-down-large .carousel-row .carousel-indicators li {
  border-color: #aeaeac;
}

.megaMenu[data-color='grey'] .drop-down-medium .carousel-row .carousel-indicators li.active, .megaMenu[data-color='grey'] .drop-down-large .carousel-row .carousel-indicators li.active {
  background: #aeaeac;
}

.megaMenu[data-color='grey'] ul.menu-links > li.active > a {
  /* add active class on li element */
  color: #aeaeac;
}

.megaMenu[data-color='grey'] ul.menu-links > li:hover > a {
  color: #aeaeac;
}

.megaMenu[data-color='grey'] ul.menu-mobile-trigger:hover {
  color: #aeaeac;
}

.megaMenu[data-color='grey'] ul.menu-mobile-trigger:hover i.fa {
  color: #aeaeac;
}

.megaMenu[data-color='grey'] .drop-down-medium.breadcrumb > li.active, .megaMenu[data-color='grey'] .drop-down-large.breadcrumb > li.active {
  color: #aeaeac;
}

.megaMenu[data-color='grey'] .menu-user-profile .profile-icons a:hover i.fa {
  color: #aeaeac;
}
