section.topNavebar {
  padding: 0;
}

.topNav {
  padding: 10px 0px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-flow: wrap;
}

.timerLeft {
  display: flex;
  gap: 20px;
}

.timeSlot {
  display: flex;
  gap: 20px;
  align-items: center;
  color: black;
}

.timeSlot p {
  margin: 0;
  font-weight: 600;
  font-size: 13px;
}

.timeSlot span {
  margin: 0;
  font-size: 13px;
  min-width: 75px;
}

.weather p {
  margin: 0;
  font-size: 13px;
  color: #000;
  display: flex;
  align-items: center;
  gap: 10px;
}
.weather p i.fa-solid.fa-location-dot {
  color: #2196f3;
}

.weather p i.fa-solid.fa-temperature-low {
  color: #ff9800;
}
.weather {
  display: flex;
  align-items: center;
}

ul.socialIcons {
  display: flex;
  align-items: center;
  gap: 15px;
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.socialIcons a {
  color: black;
  font-size: 18px;
  /* transition:all .3s ease; */
}

ul.socialIcons a:hover i.fa-brands.fa-facebook {
  color: #1877f2;
}

ul.socialIcons a:hover i.fa-brands.fa-x-twitter {
  color: #000000;
}

ul.socialIcons a:hover i.fa-brands.fa-youtube {
  color: #ff0000;
}

ul.socialIcons a:hover i.fa-brands.fa-instagram {
  color: #e1306c;
}

@media (max-width: 768px) {
  .timeSlot {
    gap: 10px;
    color: #313030;
  }

  .timeSlot p {
    font-size: 11px;
  }

  .weather {
    display: none;
  }

  .weather p {
    font-size: 11px;
  }

  .timerLeft {
    gap: 10px;
  }

  .timeSlot span {
    display: none;
  }

  ul.socialIcons a {
    font-size: 14px;
  }

  ul.socialIcons {
    gap: 10px;
  }

  .topNav {
    padding: 5px 0px;
  }

  section.topNavebar {
    background: linear-gradient(217deg, rgb(252 255 214), rgb(255 0 0 / 0%) 70.71%),
      linear-gradient(127deg, rgb(144 246 255 / 64%), rgb(255 247 153 / 0%) 70.71%),
      linear-gradient(336deg, rgb(255 106 208 / 49%), rgb(0 0 255 / 0%) 70.71%);
  }
}
