@charset "utf-8";
/* CSS Document */
.top-form {
   float: left;
   margin: 0;
   position: relative;
}
.top-form input[type="text"] {
   background-color: #fff;
   border-radius: 0;
   box-shadow: none;
   box-sizing: border-box;
   color: #666;
   float: right;
   font: 400 12px/12px "Raleway", sans-serif;
   height: 40px;
   margin: 10px 20px;
   padding: 0 10px;
   width: 270px;
}
.top-form button {
   background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
   border: 0 none;
   color: #a6a6a6;
   font-size: 22px;
   position: absolute;
   right: 26px;
   top: 12px;
}
.container-fluid {
   padding: 0px;
}
.navbar > .container .navbar-brand,
.navbar > .container-fluid .navbar-brand {
   margin: 0px;
}
.navbar-nav.pull-right > li > .dropdown-menu,
.navbar-nav > li > .dropdown-menu.pull-right {
   left: auto;
   right: 0;
}
.navbar {
   border-radius: 0px;
   border: none;
}
.navbar .active {
   background-color: #6ACAC3;
   color:#FFF !important;
   padding:18px;
}
.navbar-default {
   background-color: rgba(44, 62, 80, 0.6);
    margin-bottom: 0;
    position: absolute;
    width: 100%;
    z-index: 999;
}
.navbar-default .navbar-brand {
   color: #FFF;
}
.navbar-default .navbar-nav > li > a {
     color: #FFF;
    display: block;
    /* font-family: Raleway,sans-serif; */
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 2px;
    padding: 12px 24px;
    position: relative;
    text-transform: uppercase;
   transition: all 0.1s ease-in 0s;
   line-height:2.2em;
}
.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
   background-color: #6ACAC3;
   color: #FFF;
}
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
   color: #FFF;
   background-color: #6ACAC3;
    display: block;
    /* font-family: Raleway,sans-serif; */
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 2.2em;
    padding: 12px 24px;
    position: relative;
    text-transform: uppercase;
    transition: all 0.1s ease-in 0s;
}
.container-fluid > .navbar-collapse, .container-fluid > .navbar-header, .container > .navbar-collapse, .container > .navbar-header {
	margin:0px
	}
.menu-flat {
   display: table;
   margin: 0 auto;
}
.social-icons-top a {
   float: left;
}
.social-icons-top a i {
	font-size:17px;
	}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
   background-color: #6ACAC3;
   color: #FFF;
}
.navbar-nav > li > .dropdown-menu {
   border-radius: 0px;
   padding: 0px;
   min-width: 210px;
   border:none;
}
.navbar-nav > li > .dropdown-menu li {
   border-bottom: 1px solid #f1f1f1;
}
.navbar-nav > li > .dropdown-menu li:last-child {
	border-bottom: none;
	}
.dropdown-menu > li > a {
   color: #707070;
    /* font-family: Raleway,sans-serif; */
    font-size: 14px;
    font-weight: 500;
    padding: 12px 15px !important;
    transition: all 0.1s ease-in 0s;
   
   /*animation: hover-expand .2s linear 0s forwards;
   -moz-animation: hover-expand .2s linear 0s forwards;
   -ms-animation: hover-expand .2s linear 0s forwards;
   -o-animation: hover-expand .2s linear 0s forwards;
   -webkit-animation: hover-expand .2s linear 0s forwards;*/
}
.dropdown-menu > li > a:hover {
	background-color:#6ACAC3;
	color:#FFF;
}
.current-time {
	color: #595959;
    display: block;
    /* font-family: Raleway,sans-serif; */
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 2.2em;
    padding: 10px 24px;
    position: relative;
    text-transform: uppercase;
	
	
    float: left;
    width: 100%;
    -webkit-transition: all .1s ease 0s;
    -moz-transition: all .1s ease 0s;
    -ms-transition: all .1s ease 0s;
    -o-transition: all .1s ease 0s;
    transition: all .1s ease 0s;
}
.current-time > i {
    float: left;
   	line-height:2.2em;
	color:#FFF;

}
.current-time span {
    float: left;
    /* font-family: Raleway,sans-serif; */
    letter-spacing: 1px;
    margin-left: 15px;
    text-transform: uppercase;
	color:#FFF;
}
@keyframes hover-expand {
   0% {
	   opacity: 0;
	   transform: rotateY(90deg);
   }
   100% {
	   opacity: 1;
	   transform: rotateY(0deg);
   }
   ;
}
@-moz-keyframes hover-expand {
   0% {
	   opacity: 0;
	   -moz-transform: rotateY(90deg);
   }
   100% {
	   opacity: 1;
	   -moz-transform: rotateY(0deg);
   }
   ;
}
@-ms-keyframes hover-expand {
   0% {
	   opacity: 0;
	   -ms-transform: rotateY(90deg);
   }
   100% {
	   opacity: 1;
	   -ms-transform: rotateY(0deg);
   }
   ;
}
@-o-keyframes hover-expand {
   0% {
	   opacity: 0;
	   -o-transform: rotateY(90deg);
   }
   100% {
	   opacity: 1;
	   -o-transform: rotateY(0deg);
   }
   ;
}
@-webkit-keyframes hover-expand {
   0% {
	   opacity: 0;
	   -webkit-transform: rotateY(90deg);
   }
   100% {
	   opacity: 1;
	   -webkit-transform: rotateY(0deg);
   }
   ;
}
@media (min-width: 320px) and (max-width: 767px) {
   .navbar-collapse.in {
	   overflow-y:visible;
	   }
   #navbar {
	   position: absolute;
	   z-index: 999;
	   background-color: #FFF;
	   padding:0px
   }
   .navbar-header {
	   width: 100%;
	   display: block;
   }
   .navbar-default .navbar-collapse,
   .navbar-default .navbar-form {
	   border: none;
   }
   .navbar-nav {
	   margin: 0px;
	   color: #444
   }
   .navbar-toggle {
	   margin-bottom: 0px;
	   margin: 0px;
	   border: none;
   }
   .navbar-default .navbar-toggle:hover,
   .navbar-default .navbar-toggle:focus {
	   background-color: #6ACAC3;
   }
   .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
   .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
	   background-color: #FFF;
	   color: #2C3E50 !important;
   }
   .navbar-nav > li > .dropdown-menu {
	   padding-left: 25px;
   }
   ul.dropdown-menu {
		padding:0px !important;   
	}
   .dropdown-menu > li > a {
	   color: #999;
	   font-size: 12px;
	   padding: 10px 15px !important;
	   transition: all 0.1s ease-in 0s;
   }
   .dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover {
	   background-color: #6ACAC3;
	   color:#FFF;
	   }
	.navbar-nav > li > .dropdown-menu {
		background-color: #FFF;
		}
		.logo-row .logo {
			display: table;
			margin: 0 auto;
		}
		.owl-controls {
			display:none !important;
		}
		.navbar-default .navbar-toggle .icon-bar {
			background-color: #fff;
		}
		.navbar-toggle {
			background-color: #6ACAC3;
			background-image: none;
			border: none !important;
			border-radius: 0;
			float: right;
			padding: 18px;
			position: relative;
		}

}
