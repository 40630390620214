@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");
* {
	margin: 0;
	padding: 0;
}
header.headCts {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 0px;
}
.header.headCts-cont {
	position: sticky;
	top: 0px;
	z-index: 1010;
	background: white;
	box-shadow: 0 8px 24px hsla(210,8%,62%,.2);
}

nav Link {
	margin: 0 1rem;
	color: var(--textColor);
	text-decoration: none;
}
.bar {
	font-size: 14px;
	font-weight: 500;
}

.bar:hover {
	/* background-color: red; */
	/* border-bottom: 3px solid red; */
	color: red;
	text-decoration: none;
	/* border-radius: 5px; */
}

header.headCts .nav-btn {
	display: flex;
	align-items: center;
	gap: 10px;
	padding: 10px 0px;
	justify-content: space-between;
	display: none;
}

header.headCts div,
nav {
	/* display: flex;
	align-items: center;
	margin-right: auto;
	margin-left: auto;
	list-style: none; */
	/* margin-right: 7%; */
}
.pod {
	display: none;
	color: rgb(60, 59, 59);
}
.socialmedia-mobile {
	display: none;
}
.responsive_nav {
	display: block;
}
.mob-social-dl {
	display: none;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
	.mob-social-dl {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
	}
	.mob-social-dl .pod,
	.mob-social-dl .socialmedia-mobile {
		display: flex;
		gap: 5px;
		align-items: center;
	}
	header.headCts {
		height: 60px;
		gap: 20px;
	}
	.image-viewer {
		height: 100vh;
	}
	.span {
		text-align: center;
		height: 45px;
	}
	header.headCts .nav-btn {
		display: block;
		font-size: 24px;
		align-items: center;
		display: flex;
		color: #2b2525;
		border: 2px solid #434343;
		border-radius: 100px;
		width: 40px;
		height: 40px;
		flex-shrink: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 16px;
		/* background: linear-gradient(90deg, #ff98004d 1.59%, #ffc10769 84.61%); */
	}

	header nav {
		/* position: fixed;

		top: 210px;
		left: 0;

		height: 440px;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1.5rem;
		background-color: white; */
	}
	.bar {
		/* background-color: white;
		font-size: 18px;
		font-weight: 700;
		padding-top: 12px;
		padding-right: 16px;
		padding-left: 18px;
		text-decoration: none;
		height: 40px;
		color: black; */
	}

	header.headCts .responsive_nav {
		transform: translateY(100vh);
	}

	nav .nav-close-btn {
		position: absolute;
		top: 1rem;
		right: 2rem;
	}
	.pod {
		/* display: block;
	position:absolute;
	color:black;
	top:2px;
	left:20px;
	font-weight: 600;
	font-size: larger; */
	}
	.navlogo {
		/* margin-left: -25%;
    margin-top: 11%; */
		position: relative;
		top: 20px;
		margin-bottom: 3%;
	}
	.socialmedia-mobile {
		/* position: absolute;
	top:18%;
	right:12%;
	display: block; */
	}
	.socialmedia-mobile a {
		padding: 5px;
		color: black;
		text-decoration: none;
	}
	.socialmedia-mobile a:hover {
		/* color:red; */
	}
	.logo {
		margin-top: 5%;
		text-align: center;
		margin-bottom: 1%;
	}
	.date {
		margin-bottom: 0px;
	}
	.style {
		display: none;
	}
}
@media (max-width: 767px) {
	.mobile-view {
		display: none;
	}
	.banner-view {
		display: none;
	}
}
.style {
	display: block;
}
.logo img {
	margin-right: 10%;
	height: 125px;
	margin-left: auto;
	margin-right: auto;
	margin-top: -10%;
}



.nav-linking a:hover,.nav-linking a.barActive {
    background: #D02B2B;
    color: white;
    padding: 8px 10px;
}

.nav-linking a {
    background: transparent;
    color: black;
    padding: 8px 10px;
    transition: all .2s ease;
}