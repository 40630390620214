.search-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
      right: 90px;
      /* top: 23px; */
  }
  
  .search-bar input {
    padding: 5px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 18px;
    /* width: 300px; */
    width: 100%;
    /* margin-right: 10px; */
  }
  
  /* button {
    background-color: #d32415;
    color: #fff;
    border: none;
    border-radius: 100%;
    padding: 4px 7px;
    cursor: pointer;
    position: absolute;
    right: 5px;
  } */
  
  @media (max-width: 768px) {
    .search-bar {
      display: flex;
      justify-content: center;
      align-items: center;
      /* margin: -11px; */
      position: absolute;
      /* right: 10px; */
      right: 25%;
      top: 3px;
      width: 50%;
      /* border: 1px solid red; */
    }
    
    input {
      /* padding: 5px;
      font-size: 16px;
      border: 1px solid #ccc;
      border-radius: 18px; */
      /* width: 300px; */
      /* width: 100%; */
      /* margin-right: 10px; */
    }
    
    button {
      /* background-color: d32415;
      color: #fff;
      border: none;
      border-radius: 100%;
      padding: 4px 7px;
      cursor: pointer;
      position: absolute;
      right: 2.2%; */
    }
    .about-box{
      width: 100%;
      height: 100%;
  
    }
    .search-box{
      /* border: 1px solid red; */
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    .box1{
      /* border: 1px solid blue; */
      width: 100%;
      /* height: 200px; */
      display: flex;
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;
    }
    .box2{
     width: 100%;
    }
  
    .flex-box{
      display: flex;
      flex-direction: row;
      /* width: 65%; */
      height: 300px;
    }
    .image-flex{
      /* border: 1px solid green; */
      display: flex;
      flex-direction: row;
      height: 300px;
      /* width: 35%; */
    }
    .search-item img{
      /* border: 1px solid red;   */
      margin-left: auto;
      margin-right: auto;
    }
    .grid-search{
      display: flex;
      flex-direction: column;
    border-bottom: 1px dotted rgb(180, 179, 179);
  
    }
    .hash-tagName{
      border-bottom: 1px solid rgb(180, 179, 179);
      /* display: flex; */
      width: 100%;
      padding: 10px;
      text-align: center;
      cursor: pointer;
    }
    /* .hash-tagName a div{
      margin-left: 10px;
      margin-right: 10px;
      padding: 7px;
      background-color: #d32415;
        font-weight: 600;
        font-size: medium;
        cursor: pointer;
        margin-bottom: 5px;
    
    } */
  }
  


  .about-box{
    width: 100%;
    height: 100%;

  }
  .search-box{
    /* border: 1px solid red; */
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .box1{
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
  }
  .box1 h2{
    /* font-family: "Times New Roman", Times, serif; */
    font-weight: 600;
    text-transform: capitalize;
  }
  .box1 h4 a{
    color: black;
    text-decoration: none;
  }
  .box1 h4 a:hover{
    color: red;
  }
  .box2{
    /* border: 1px solid blue; */
    height: 300px;
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
  }

  .flex-box{
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 300px; */
  }
  .image-flex{
    /* border: 1px solid green; */
    display: flex;
    flex-direction: row;
  }
  .grid-search{
    display: flex;
  border-bottom: 1px dotted rgb(180, 179, 179);

  }
  .search-item{
    display: flex;
    /* height: 190px; */
    flex-grow: 2;
    /* flex-basis: 100px; */
  }
  .search-item img{
    padding: 10px;
    /* height: 230px;
    width: 270px; */
    height: 200px;
    width: 255px;
  }
 
  /* .search-item h3{
    text-align: justify;
  } */
  .search-item h3 a{
    /* border: 1px solid red; */
    width: 100%;
    text-align: justify;
    text-decoration: none;
    color: black;

  }

  .search-item h3 a:hover{
    color: red;
  }
  .search-item p{
    text-align: justify;
  }
  .search-item h4{
    color: rgb(104, 103, 103);
  }
.icon-links{  
  display: flex;
  gap: 20px;
  color: black;
  padding: 1%;
  right: 13px;
}
.icon-links li a{
  color: black;
  text-decoration: none;
  font-size: larger;
}
.icon-links li a:hover{
  color: red;
}
.search-grid{
  border: none;
  margin-bottom: 0;
}
.keyword{
  border-bottom:   1px dotted rgb(180, 179, 179);
  
}
.hash-tagName{
  /* border-bottom: 1px solid rgb(180, 179, 179); */
  /* display: flex; */
  /* width: 100%;
  padding: 10px;
  text-align: center; */
}
/* .hash-tagName a div{
  margin-left: 10px;
  margin-right: 10px;
  padding: 7px;
  background-color: #d32415;
    font-weight: 600;
    font-size: medium;
    cursor: pointer;
    margin-bottom: 5px;

} */
/* .hash-tagName{
  display: flex;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
 flex-direction: row;
}
.hash-tag1{
  border: 1px solid black;
  display: flex;
  height: 40px;
  flex-grow: 1;
  flex-basis: 100px;
  justify-content: center;
  text-align: center;
  padding: 5px;
}
.tagname {
  border: 1px solid red;
  padding: 5px;
 cursor: pointer;
} */
/* 
.hashTag{
  border-bottom: 1px solid rgb(180, 179, 179);
  width: 100%;
} */
.banner-view {
  position: sticky;
  top: 55px;
}

@media (max-width: 768px) {
  .tags{
    display: flex;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
   flex-direction: row;
  }
  .tags1{
    border: 1px solid black;
    display: flex;
    height: 40px;
    flex-grow: 1;
    flex-basis: 100px;
    justify-content: center;
    text-align: center;
    padding: 5px;
  }
  .tagname {
    border: 1px solid red;
    padding: 5px;
   cursor: pointer;
  }
}
.search-title h3 a{
  color: black;
  text-decoration: none;
}
.search-title h3 a:hover{
  color: #d32415;
}
@media screen and (min-width: 320px) and (max-width: 767px) 
{
.sections {
		margin-left:15%;
		margin-right: 15%;
		/* border: 1px solid red; */
	}
}
.search-bar-custom {
	background: white;
	box-shadow:
		rgba(17, 17, 26, 0.05) 0px 1px 0px,
		rgba(17, 17, 26, 0.1) 0px 0px 8px;
	display: flex;
	align-items: center;
	padding-left: 20px;
	width: 100%;
	max-width: 320px;
	gap: 10px;
	border-radius: 100px;
	position: relative;
}
.search-bar-custom button {
	padding: 10px;
	background: #439595;
	border-radius: 100px;
	color: white;
	flex-shrink: 0;
	width: 40px;
	height: 40px;
	font-size: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.search-bar-custom input {
	width: 100%;
	padding: 10px 0px;
	font-size: 13px;
}

.search-bar-custom {box-shadow: none;width: 0%;transition: width 1.5s ease-in-out;}

.search-bar-custom input {
    opacity: 0;
    pointer-events: none;
    width: 0%;
}

.search-bar-custom:hover input {
    width: 100%;
    max-width: 320px;
    opacity: unset;
    pointer-events: unset;
    padding-right: 50px;
}

.search-bar-custom:hover {
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,  rgba(17, 17, 26, 0.1) 0px 0px 8px;
    transition: width 1.5s ease-in-out;
    width: 100%;
}

.search-bar-custom button {
    position: absolute;
    right: 0;
}

.search-bar-custom:hover input {
    width: 100%;
    transition: width 2s ease-in-out;
}

@media (max-width:768px) {
  .search-bar-custom {
		box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,  rgba(17, 17, 26, 0.1) 0px 0px 8px;
		width: 100%;
		max-width: 100%;
	}
	
	.search-bar-custom:hover input {
		width: 100%;
		padding-right: 50px;
	}
  .search-bar-custom input {
    opacity: unset;
    pointer-events: unset;
    width: 100%;
}
.search-bar-custom {
  max-width: unset;
}
}