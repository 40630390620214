#total-magzine {
  background-image: url("./Jpeg/magzine-background-grey.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  /* pointer-events: none; */
  height: 100vh;
}

.image-viewer {
  display: flex;
  flex-direction: column;
  align-items: center; /* pointer-events: none; */
  height: 100vh;
  justify-content: center;
  gap: 20px;
}

.zoom-buttons {
  margin-bottom: 10px;
}

.demoPage {
  max-width: 100%;
  transition: width 0.3s ease-in-out;
  pointer-events: none;
}

.api-images {
  width: 380px;
  height: 500px;
}

.head-box {
  /* border:1px solid blue; */
  text-align: center;
  height: 100vh;
  background-image: url("./Jpeg/magzine-background-grey.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.boxing {
  box-shadow: 0 0 5px 2px #3b3b3b;
}

.demo-book {
  max-width: 100%;
  min-width: 380px;
  min-height: 500px;
  width: 100%;
  max-width: 100%;
  display: block;
}

.stf__parent {
  position: relative;
  display: block;
  box-sizing: border-box;
  transform: translateZ(0);
  margin-left: auto;
  margin-right: auto;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  /* margin-top: 4%;  */
  /* margin-bottom: 10%; */
}

.sft__wrapper {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.stf__parent canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.stf__block {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  perspective: 2000px;
}

.stf__item {
  display: none;
  position: absolute;
  transform-style: preserve-3d;
}

.stf__outerShadow {
  position: absolute;
  left: 0;
  top: 0;
}

.stf__innerShadow {
  position: absolute;
  left: 0;
  top: 0;
}

.stf__hardShadow {
  position: absolute;
  left: 0;
  top: 0;
}

.stf__hardInnerShadow {
  position: absolute;
  left: 0;
  top: 0;
}

/* sideflip-btns */

.sideflip-btn {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
  align-items: center;
  top: 0px;
  bottom: 0;
  margin: auto;
}
/* .sideflip-btn1{
  height:40px;
  width:40px;
  border:1px solid red;
  
} */
/* .sideflip-btn2{
  height:40px;
  width:40px;
  border:1px solid red;
 
} */
.glyphicon-chevron-left {
  font-size: x-large;
  cursor: pointer;
}

.glyphicon-chevron-right {
  font-size: x-large;
  cursor: pointer;
}

/* downward-buttons */

.downwards-bar {
  margin-right: auto;
  margin-left: auto;
  z-index: 2;
  display: flex;
  background: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.btn1 {
  height: 0px;
  width: 0px;

  margin-left: auto;
  margin-right: auto;
  margin-top: -25%;
  background-color: white;
}

.btn2 {
  height: 42px;
  width: 42px;
  cursor: pointer;
  border: 1px solid white;
}

.fa-chevron-left {
  height: 42px;
  width: 42px;
  padding-top: 13px;
  padding-left: 13px;
  color: #777;
  border: 1px solid white;
  /* background-color: white; */
  cursor: pointer;
}

.btn3 {
  height: 42px;
  width: 42px;
  cursor: pointer;
  border: 1px solid white;
}

.fa-chevron-right {
  height: 42px;
  width: 42px;
  padding-top: 13px;
  padding-left: 14px;
  color: #777;
  border: 1px solid white; /* background-color: white; */
}

.btn4 {
  height: 42px;
  width: 42px;
  cursor: pointer;
  border: 1px solid white;
}

.fa-search-plus {
  height: 42px;
  width: 42px;
  padding-top: 13px;
  padding-left: 13px;
  color: #777;
  border: 1px solid white;
  /* background-color: white; */
}

.btn5 {
  height: 42px;
  width: 42px;
  cursor: pointer;
  border: 1px solid white;
}

.fa-search-minus {
  height: 42px;
  width: 42px;
  padding-top: 13px;
  padding-left: 12px;
  color: #777;
  border: 1px solid white;
  /* background-color: white; */
}

.btn6 {
  height: 42px;
  width: 42px;
  cursor: pointer;
  border: 1px solid white;
}

.fa-arrows-alt {
  height: 42px;
  width: 42px;
  padding-top: 13px;
  padding-left: 12px;
  color: #777;
  border: 1px solid white;
  /* background-color: white; */
}

.fa-share-alt {
  height: 42px;
  width: 42px;
  color: white;
  color: #777;
  border: 1px solid white;
  /* background-color: white; */
}

.exit {
  font-size: x-large;
  cursor: pointer;
  position: absolute;
  top: 2%;
  right: 2%;
}

.total-magzine {
  height: 100vh;
}

/* social-media-pop-up-share-icons */

.link-copied-to-clipboard {
  height: 20px;
  width: 153px;
  position: absolute;
  top: 87px;
  left: 140px;
  border-radius: 12px;
  background-color: #1f1e1e;
  color: white;
}

.pop-up-share-icons {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  background-color: white;
  border-radius: 20px;
  padding-right: 5px;
  padding-left: 5px;

  box-shadow: 0px 0px 25px black;
  transform: translate(-50%, -50%) scale(1.2);
  transition:
    top 0s 0.2s ease-in-out,
    opacity 0.2s 0s ease-in-out,
    transform 0.2s 0s ease-in-out;
}

.pop-up-share-icons-close {
  position: absolute;
  left: 92%;
  border: none;
  /* background-color: white; */
  height: 0px;
  display: inline;
}

.pop-up-share-icons :is(header, .icons, .field) {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.pop-up-share-icons header {
  padding-bottom: 15px;
  border-bottom: 1px solid #ebedf9;
}

header span {
  /* font-size: 21px;
  font-weight: 600; */
}

header .close,
.icons a {
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

header .close {
  color: #878787;
  font-size: 17px;
  background: #f2f3fb;
  height: 33px;
  width: 33px;
  cursor: pointer;
}

header .close:hover {
  background: #ebedf9;
}

.pop-up-share-icons .content {
  margin: 20px 0;
}

/* .pop-up-share-icons .icons {
  margin: 15px 0 20px 0;
} */

.content p {
  font-size: 16px;
}

.content .icons a {
  height: 5px;
  width: 50px;
  font-size: 20px;
  text-decoration: none;
  margin-left: -40%;
  /* border: 1px solid red; */
  /* gap: 1px; */
}

.icons a i {
  transition: transform 0.3s ease-in-out;
}

.icons .fb {
  color: #1877f2;
  border-color: 1pc solid #b7d4fb;
}

.icons .fb:hover {
  color: #1877f2;
}

.icons .tw {
  color: #46c1f6;
  border-color: #b6e7fc;
}

.icons .tw:hover {
  color: #46c1f6;
}

.icons .li {
  color: #0072b1;
  border-color: #f5bccf;
}

.icons .li:hover {
  color: #0072b1;
}

.icons .wt {
  color: #25d366;
  border-color: #bef4d2;
}

.icons .wt:hover {
  color: #25d366;
}

.icons .tl {
  color: #0088cc;
  border-color: #b3e6ff;
}

.icons .tl:hover {
  color: #0088cc;
}

.icons a:hover {
  color: #fff;
  border-color: transparent;
}

.icons a:hover i {
  transform: scale(1.2);
}

.content .field {
  margin: 12px 0 -5px 0;
  /* height: 45px; */
  border-radius: 4px;
  padding: 0 5px;
  border: 1px solid #e1e1e1;
}

.field.active {
  border-color: #7d2ae8;
}

.field i {
  width: 42px;
  font-size: 18px;
  text-align: center;
}

.field.active i {
  color: #7d2ae8;
}

.field input {
  width: 100%;
  /* height: 100%; */
  border: none;
  outline: none;
  font-size: 15px;
  margin-left: -13%;
}

.field button {
  /* color: ; */
  padding: 0px;
  border: 1px solid #f2f3fb;
  background: white;
  width: 15%;
  /* background: #7d2ae8; */
}

.field button:hover {
  color: #0088cc;
}

.downwards-bar i {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
  font-size: 13px;
  transition: 0.3s;
  transition-property: opacity, color, background-color;
}

.downwards-bar i:hover {
  background: #eee;
  color: #00acce;
}

div.ebookBtns {
  width: 42px;
  height: 42px;
  border: 0px !important;
  cursor: pointer;
  transition: 0.3s;
  transition-property: opacity, color, background-color;
}

div.ebookBtns:first-child {
  border-radius: 5px 0px 0px 0;
}
.sideflip-btn span.nvIco {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  background: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  border-radius: 42px;
  cursor: pointer;
}

span.nvIco .fa-solid.fa-chevron-left,
span.nvIco .fa-solid.fa-chevron-right {
  height: unset;
  width: unset;
  border: none;
  padding: 0;
}

span.nvIco:hover {
  transform: scale(1.1);
  color: #00acce;
}

span.nvIco:hover i {
  color: #00acce;
}
.emagzine-container .stf__block .bookUi {
  background: #ffffff69;
  /* backdrop-filter: blur(8px); */
  box-shadow:
    rgba(0, 0, 0, 0.19) 0px 10px 20px,
    rgba(0, 0, 0, 0.23) 0px 6px 6px;
  border-radius: 12px;
}
/* .emagzine-container .stf__block{
  background: #ffffff69;
  backdrop-filter: blur(8px);
  box-shadow:
    rgba(0, 0, 0, 0.19) 0px 10px 20px,
    rgba(0, 0, 0, 0.23) 0px 6px 6px;
  border-radius: 12px;
} */
.bookUi.--simple.stf__item.--soft.--left {
  border-radius: 10px 0px 0px 10px !important;
  overflow: hidden !important;
}
.bookUi.--simple.stf__item.--soft.--right {
  border-radius: 0px 10px 10px 0px !important;
  overflow: hidden !important;
}

.bookUi.--simple.stf__item.--soft.cover-page {
  border-radius: 10px !important;
  overflow: hidden !important;
}
.bookUi.last-page.stf__item {
  border-radius: 10px !important;
  overflow: hidden !important;
}
.downwards-bar {
  position: absolute;
  right: 0;
  flex-flow: column;
  bottom: 0;
  top: unset;
  right: 23px;
}

/* .bookUi.cover-page.stf__item {
  left: 0 !important;
  right: 0 !important;
  margin: auto !important;
  transform: unset !important;
  transform-origin: unset !important;
  display: unset!important;
  z-index: unset !important;
}
.bookUi.cover-page.stf__item:hover{
  transform: unset !important;
} */
@media only screen and (min-width: 768px) {
  .bookUi.cover-page.stf__item,
  .bookUi.last-page.stf__item {
    /* pointer-events: none; Disable interactions */
    transform: none !important;
    left: 0 !important;
    right: 0 !important;
    margin: auto;
    z-index: 0 !important;
  }
  .bookUi.cover-page.stf__item:hover ~ .bookUi.inner-page.stf__item {
    visibility: hidden !important; /* Hide the second page when hovering over the cover */
  }

  /* Cover page styles */
  .cover-page {
    cursor: pointer;
    background-color: #f5f5f5; /* Optional: Add background for visual distinction */
  }

  /* Blur effect when the cover page is opening */
  .cover-page.cover-opening {
    filter: blur(5px); /* Apply blur */
  }

  /* Last page styles */
  .last-page {
    background-color: #e0e0e0; /* Optional: Add background for visual distinction */
  }

  /* Inner pages */
  .inner-page {
    pointer-events: auto;
  }
}

/* .emagzine-container .stf__block::before{
  content: 'Start Reading';
  position: absolute;
  left: 0;
  top: 0px;
  bottom: 0px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}
.emagzine-container .stf__block::after{
  content: 'End';
  position: absolute;
  right: 0;
  top: 0px;
  bottom: 0px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
} */
@media (max-width: 768px) {
  .downwards-bar {
    position: relative;
    flex-flow: row;
    right: 0;
    left: 0;
    margin: auto;
  }
}
