
.article-description{
    /* border: 1px solid red; */
    position: absolute;
    top: 75%;
    width: 90%;
}
.article-description h5 a{
    color: white;
    text-decoration: none;
    font-size: 18px;
    /* font-weight: bold; */
    cursor: pointer;
}
.article-description h5 a:hover{
    color: red;
}
.article-description p a{
    color: #565454;
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
}
/* .article-img {
    position: absolute;
    top: 80%;
} */

/* .article-img::after{
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.6));
    background: rgba(0, 0, 0, 0.6) none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    opacity: 2;
    position: absolute;
    top: 0;
    transition: all 0.5s ease 0s;
    width: 100%;
} */
.article-img::after{
    width: 100%;
    content: "";
    left: 0;
    top: 0;
    position: absolute;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.6));
    height: 100%;
    opacity: 2;
}
.bottom-left {
    position: absolute;
    bottom: 0px;
    left: 0px;
    cursor: pointer;
    /* position: absolute;
    bottom: 0px;
    left: 0px;
    cursor: pointer;
    border: 1px solid black;
    text-align: justify;
    padding: 5px;
    line-height: 3px; */
    line-height: 10px;
    
  }
  .bottom-left h4 a{
    color: white;
    text-decoration: none;
    font-size: 16px;

  }
  .bottom-left h4 a:hover{
    color: #d32415;
  }

  .bottom-left p a{
    color: #e1e1e1;
    text-decoration: none;
    cursor: text;
    font-size: smaller;
    text-transform: uppercase;
  }
  .bottom-left p a:hover{
    color: #d32415;
  }
  .banner-fixed {
    /* position: fixed; */
    display: block;
    /* top: 220px;
    z-index: 2; */
  }
  .banner-fixed {
    /* position: -webkit-sticky; */
    position: sticky;
    /* position: fixed; */
    /* bott: 200px; */
    z-index: 999;
  }
  /* .card {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }  */
/* .card-body{
  height: 120px;
} */
  .card {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    /* height: 350px; */
  }
  
  .card:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
  .img-hover-zoom {
    overflow: hidden;
  }
  .img-hover-zoom img {
    transition: all .3s ease-in-out;
  }
  .img-hover-zoom:hover img {
    transform: scale(1.2);
  }
  

  
  @media only screen and (min-width: 767px) and (max-width: 991px){
    .banner-fixed{
        display: none;
      }
      .grid-container{
        display: none;
      }
      .none{
        display: none;
      }
  }
  @media only screen and (min-width: 992px) and (max-width: 1023px){
    .banner-fixed{
        display: none;
      }
      .grid-container{
        display: none;
      }
      .none{
        display: none;
      }
  }
  @media only screen and (min-width: 1024px) and (max-width: 1033px){
    .banner-fixed{
      display: none;
      /* left: 16px; */
    }
    .none{
      display: none;
    }
  }
  @media (min-width: 992px)
{
    .containering {
    width: 1125px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
}
}
  @media (max-width: 768px){
    .banner-fixed{
      display: none;
    }
    .none{
      display: none;
    }
  }
  @media (min-width: 992px){
.col-md-6 {
    width: 50%;
}
.none{
  display: none;
}
.wrappers{
  display: block;
  /* grid-template-areas:
      "header header header header header"
      "empty_left sidebar_1 content sidebar_2 empty_right"
      "footer footer footer footer footer";
  grid-template-rows: 0fr 1fr 0fr;
  grid-template-columns: 0fr 100px 87% 100px 0fr;
  min-h
  eight: 100vh; */
}
  }
  .grid-container {
    display: flex;
    display: block;
  }
  
  .content-container {
    margin-left: 10px; /* Adjust the margin to the width of your fixed side banner */
  }
  .overflow-auto {
    overflow: auto;
  }
  /* section{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  article{
    height: 200px;
  } */
  .size{
    font-size: 10px;
    text-transform: uppercase;
  }
  .size span{
    color: #868585;
  }
  /* .size  */
  .article_desc{
    border-bottom: 1px solid #a4a0a0;
  }
  .article_desc h5 a{
    color: black;
    text-decoration: none;
    font-size: smaller;   
  }
  .article_desc h5 a:hover{
    color: #d32415;
  }
  .article_desc p a:hover{
    color: #d32415;
  }
  .article_desc p span:hover{
    color: #d32415;
  }
  .title-article{
    padding: 6px;
  }
  .title-article h3 span{
    border: 1px solid black;
    padding: 6px;
    font-size: large;
  }
  .none{
    display: block;
  }
  /* body{
    font-family: verdana;
  } */
  .containers{
    display: flex;
    justify-content: center;
    border: 1px solid black;
  
  }
  .fa-globe{
    font-size: 5rem;
    padding-right: 5px;
  }
  .box {
    padding: 5px;
    margin: 5px;
    color: #ddd;
    flex:1;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  .hero-container{
    height: 50vh;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(rgba(256,256,256,.1),rgba(0,0,0,.3)),url('https://cdn.dribbble.com/users/343382/screenshots/1497688/3r2def.png');
    background-size: cover;
    
    
  }
  @media only screen and (max-width: 700px) {
   
    .box {
    flex:0;
      }
    .container {
      flex-direction: column;
    }
  }
  .zoom-in{
    overflow: hidden;
  }
  .zoom-in a image{
    transition: all .5s ease-in-out;
  }
  .zoom-in:hover img{
    transform: scale(1.1);

  }
  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 200px;
  }
  /* .image_container{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 200px;
  } */
  
  /* .image_contianer img{
   height: 100%;
   position: fixed;
  } */

/* .no-gutters{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border: 1px solid red;
  width: 100%;
} */

