/*
Zerotheme.com | Free Html5 Responsive Templates
Zerogrid - A Single Grid System for Responsive Design
Author: Kimmy
Version : 3.0
Author URI: http://www.zerotheme.com/
*/
/* -------------------------------------------- */
/* ------------------Grid System--------------- */
.zerogrid {
	width: 1200px;
	position: relative;
	margin: 0 auto;
	padding: 0px;
}

.zerogrid:after {
	content: "\0020";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
}

.zerogrid .f-right {
	float: right !important;
}

.zerogrid .f-left {
	float: left !important;
}

.zerogrid .row:before,
.row:after {
	content: '\0020';
	display: block;
	overflow: hidden;
	visibility: hidden;
	width: 0;
	height: 0;
}

.zerogrid .row:after {
	clear: both;
}

.zerogrid .row {
	zoom: 1;
}

.zerogrid .wrap-col {
	margin: 10px;
}

.zerogrid .col-1-2,
.zerogrid .col-1-3,
.zerogrid .col-2-3,
.zerogrid .col-1-4,
.zerogrid .col-2-4,
.zerogrid .col-3-4,
.zerogrid .col-1-5,
.zerogrid .col-2-5,
.zerogrid .col-3-5,
.zerogrid .col-4-5,
.zerogrid .col-1-6,
.zerogrid .col-2-6,
.zerogrid .col-3-6,
.zerogrid .col-4-6,
.zerogrid .col-5-6 {
	float: left;
	display: inline-block;
}

.zerogrid .col-full {
	width: 100%;
}

.zerogrid .col-1-2 {
	width: 50%;
}

.zerogrid .offset-1-2 {
	margin-left: 50%;
}

.zerogrid .col-1-3 {
	width: 33.33%;
}

.zerogrid .col-2-3 {
	width: 66.66%;
}

.zerogrid .offset-1-3 {
	margin-left: 33.33%;
}

.zerogrid .offset-2-3 {
	margin-left: 66.66%;
}

.zerogrid .col-1-4 {
	width: 24%;
}

.zerogrid .col-2-4 {
	width: 50%;
}

.zerogrid .col-3-4 {
	width: 75%;
}

.zerogrid .offset-1-4 {
	margin-left: 25%;
}

.zerogrid .offset-2-4 {
	margin-left: 50%;
}

.zerogrid .offset-3-4 {
	margin-left: 75%;
}

.zerogrid .col-1-5 {
	width: 20%;
}

.zerogrid .col-2-5 {
	width: 40%;
}

.zerogrid .col-3-5 {
	width: 60%;
}

.zerogrid .col-4-5 {
	width: 80%;
}

.zerogrid .offset-1-5 {
	margin-left: 20%;
}

.zerogrid .offset-2-5 {
	margin-left: 40%;
}

.zerogrid .offset-3-5 {
	margin-left: 60%;
}

.zerogrid .offset-4-5 {
	margin-left: 80%;
}

.zerogrid .col-1-6 {
	width: 16.66%;
}

.zerogrid .col-2-6 {
	width: 33.33%;
}

.zerogrid .col-3-6 {
	width: 50%;
}

.zerogrid .col-4-6 {
	width: 66.66%;
}

.zerogrid .col-5-6 {
	width: 83.33%;
}

.zerogrid .offset-1-6 {
	margin-left: 16.66%;
}

.zerogrid .offset-2-6 {
	margin-left: 33.33%;
}

.zerogrid .offset-3-6 {
	margin-left: 50%;
}

.zerogrid .offset-4-6 {
	margin-left: 66.66%;
}

.zerogrid .offset-5-6 {
	margin-left: 83.33%;
}

@media only screen and (min-width: 960px) and (max-width: 1199px) {
	.zerogrid {
		width: 960px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 959px) {
	.zerogrid {
		width: 768px;
	}
}

@media only screen and (max-width: 767px) {

	.zerogrid,
	.zerogrid .col-1-2,
	.zerogrid .col-1-3,
	.zerogrid .col-2-3,
	.zerogrid .col-1-4,
	.zerogrid .col-2-4,
	.zerogrid .col-3-4,
	.zerogrid .col-1-5,
	.zerogrid .col-2-5,
	.zerogrid .col-3-5,
	.zerogrid .col-4-5,
	.zerogrid .col-1-6,
	.zerogrid .col-2-6,
	.zerogrid .col-3-6,
	.zerogrid .col-4-6,
	.zerogrid .col-5-6 {
		width: 100%;
	}

	.zerogrid .offset-1-2,
	.zerogrid .offset-1-3,
	.zerogrid .offset-2-3,
	.zerogrid .offset-1-4,
	.zerogrid .offset-2-4,
	.zerogrid .offset-3-4,
	.zerogrid .offset-1-5,
	.zerogrid .offset-2-5,
	.zerogrid .offset-3-5,
	.zerogrid .offset-4-5,
	.zerogrid .offset-1-6,
	.zerogrid .offset-2-6,
	.zerogrid .offset-3-6,
	.zerogrid .offset-4-6,
	.zerogrid .offset-5-6 {
		margin-left: 0;
	}
}