@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
body {
	font-family: "Montserrat", serif !important;
	position: relative;
	overflow-x: hidden;
	-webkit-text-size-adjust: 100%;
	-webkit-overflow-scrolling: touch;
	-webkit-font-smoothing: antialiased;
	line-height: 1.6em;
	max-width: 1585px;
	margin: 0 auto;
	background: white;
}

.wrapper {
	width: 100%;
}

.mt10 {
	margin-top: 10px;
}

.mt20 {
	margin-top: 20px;
}

.mt30 {
	margin-top: 30px;
}

.mt40 {
	margin-top: 40px;
}

.mt50 {
	margin-top: 50px;
}

.mt60 {
	margin-top: 60px;
}

.mb10 {
	margin-bottom: 10px;
}

.mb20 {
	margin-bottom: 20px;
}

.mb30 {
	margin-bottom: 30px;
}

.mb40 {
	margin-bottom: 40px;
}

.mb50 {
	margin-bottom: 50px;
}

.nopadding {
	padding: 0;
}
.nomargin {
	margin: 0 !important;
}

.bg-white {
	background-color: #fff;
}

.bg-gray {
	background-color: #f9f9f9;
}

.bg-dark {
	background-color: #242424;
}

section {
	padding: 80px 0;
}

.section {
	margin-bottom: 20px;
	position: relative;
	overflow: hidden;
	margin-left: 40px;
	/* margin-left: 6%; */
	margin-right: -5%;
	/* border: 1px solid red; */
}
.main-content div.section:last-child {
	margin-bottom: 0;
}
a {
	outline: none;
	color: #242424;
	text-decoration: none;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease- 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

a:hover {
	text-decoration: none;
	color: #f44a56;
}

h1,
h2 {
	line-height: 1.5em;
	font-weight: 400;
}

h3,
h4 {
	font-weight: 400;
	line-height: 1.4em;
}

h5,
h6 {
	line-height: 1.4em;
}

.post-entry p {
	color: #777;
	font-size: 16px;
	line-height: 30px;
	margin-bottom: 15px;
}

.btn-colored-blog {
	background-color: #f44a56;
	color: #fff;
	border-radius: 4px;
	padding: 8px 20px;
	text-transform: capitalize;
	font-weight: 500;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease- 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.btn-colored-blog:hover {
	color: #f44a56;
	background-color: transparent;
	border: 1px solid #f44a56;
}

.show-more-btn {
	text-align: center;
}

.show-more-btn a i {
	margin-right: 5px;
}

.post-tools {
	list-style: none;
	position: relative;
	overflow: hidden;
	overflow: none;
	padding-left: 0;
}

.catname .btn,
.post-tools .btn {
	border-radius: 3px;
	color: #fff;
	font-size: 12px;
	font-weight: 400;
	padding: 5px 10px;
	text-transform: capitalize;
	border-bottom: none;
	background-image: -moz-linear-gradient(center bottom, rgba(0, 0, 0, 0.17) 0%, rgba(255, 255, 255, 0.17) 100%);
}

.picture .category-image .catname a:hover {
	background-color: #242424;
}

p .readmore {
	text-transform: capitalize;
	margin-left: 10px;
	letter-spacing: 1px;
	color: #242424;
	font-weight: 500;
}

/*HEADING STYLE*/

@media screen and (max-width: 767px) {
	.heading {
		display: table;
		width: 100%;
		height: 100%;
		margin-bottom: 50px;
	}
}
@media screen and (max-width: 767px) {
	.heading .item {
		display: table;
		width: 100%;
		height: 100%;
		margin-bottom: 50px;
	}
}
.heading {
	position: relative;
	overflow: hidden;
	margin-bottom: 30px;
}

.heading.colored {
	background-color: #f1f1f1;
}

.heading .main-heading {
	font-size: 20px;
	text-transform: capitalize;
	color: #242424;
	font-weight: 500;
	padding: 10px 15px;
	margin: 0;
	float: left;
	line-height: 1.1em;
	border: 1px solid #646262;
}

.heading .heading-ping {
	width: 0;
	position: absolute;
	top: 10px;
	height: 0;
	border-style: solid;
	border-width: 10px 0 10px 10px;
	border-color: transparent transparent transparent #242424;
}

.heading-read-more {
	float: right;
	padding: 5px;
}

.heading-read-more a {
	/* padding: ; */
	text-transform: capitalize;
	padding: 6px 10px;
	font-size: 14px;
	font-weight: 500;
}

.heading-read-more a:hover {
	opacity: 0.8;
	color: #fff;
}

.widget .heading .main-heading {
	color: #242424;
	font-size: 16px;
	font-weight: 500;
	margin: 0;
	padding: 10px 15px;
	line-height: 1.2em;
	border: 1px solid #444;
}

/* ----  BREADCRUMB ---- */

.my-breadcrumb {
	background: #24201f url("../images/breadcrumb.jpg") repeat scroll center center / cover;
	padding: 60px 0;
	text-align: center;
	position: relative;
}

.my-breadcrumb::before {
	background: rgba(0, 0, 0, 0.4) none repeat scroll 0 0;
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.my-breadcrumb .page-banner h1 {
	color: #fff;
	font-size: 30px;
	font-weight: 500;
	margin: 0;
	text-transform: capitalize;
}

.my-breadcrumb .page-banner .breadcrumb {
	background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
	margin: 0;
	padding: 0;
}

.my-breadcrumb .breadcrumb > li {
	display: inline-block;
	text-transform: capitalize;
	font-size: 16px;
}

.my-breadcrumb .page-banner .breadcrumb a {
	color: #fff;
	text-transform: capitalize;
}

.my-breadcrumb .breadcrumb > li + li::before {
	content: "\f101 ";
	font-family: FontAwesome;
	padding: 0 5px;
	color: #fff;
}

.my-breadcrumb .breadcrumb > .active {
	color: #fff;
	text-transform: capitalize;
}

/*CATEGORY COLOR CODE*/

.btn-purple {
	background-color: #9c27b0;
	color: #fff;
}

.btn-orange {
	background-color: #ff5722;
	color: #fff;
}

.btn-yellow {
	background-color: #e28712;
	color: #fff;
}

.btn-gray {
	background-color: #607d8b;
	color: #fff;
}

.btn-green {
	background-color: #2ecc71;
	color: #fff;
}

.btn-pink {
	background-color: #e91e63;
	color: #fff;
}

.btn-red {
	background-color: #f44336;
	color: #fff;
}

.btn-blue {
	background-color: #00aeef;
	color: #fff;
}

.btn-black {
	background-color: #242424;
	color: #fff;
}

.btn-maroon {
	background-color: #7b2143;
	color: #fff;
}

.btn-lightblue {
	background-color: #8e93c4;
	color: #fff;
}

.btn-dark-red {
	background-color: #d11141;
	color: #fff;
}

.section .owl-nav {
	position: absolute;
	right: 15px;
	top: -73px;
	margin: 0;
}

.section .owl-theme .owl-nav div.owl-prev::after,
.section .owl-theme .owl-nav div.owl-next::after {
	color: #fff;
	font-size: 20px;
}

.section .owl-theme .owl-nav div.owl-next,
.section .owl-theme .owl-nav div.owl-prev {
	background-color: #444;
	border: none;
	line-height: 28px;
	width: 35px;
	height: 35px;
}
.box-style {
	background-image: url("../images/box-bg.jpg");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
}
.box-style .main-content,
.box-style footer,
.box-style .parallel-post-style {
	background-color: #fff;
}
.box-style .parallel-post-style .grid-box .thumb img {
	max-height: 175px;
	width: auto;
}
.box-style .insta-gallery a {
	width: 75px;
}
/*TOP BAR*/

.topbar {
	background-color: #fff;
	padding: 10px 0;
	/* border: 1px solid red; */
	/* position: -webkit-sticky;
	position: sticky;
	top: 0; */
}

.topbar.white {
	background-color: #fff;
	padding: 15px 0;
	border-bottom: 1px solid #f1f1f1;
}

.topbar.white ul li,
.topbar.white ul li a {
	color: #444;
}

.topbar ul {
	padding-left: 0;
	margin-bottom: 0;
	/* float: right; */
}
ul.subs-topbar {
	display: flex;
	/* justify-content: end; */
	align-items: center;
}

.topbar ul li {
	position: relative;
	display: inline-block;
	/* margin-left: 20px; */
	color: #fff;
}

.topbar ul li a {
	/* color: #444; */
	color: #000;
	font-weight: 500;
	text-decoration: none;
}

.topbar ul li a:hover {
	color: #f44a56;
}

.topbar ul li i {
	margin-right: 5px;
}

.topbar .social-media-icons-top {
	/* float: left; */
}
section.topbar {
	border-bottom: 1px solid #00000017;
	/* margin-bottom: 9px; */
	background: linear-gradient(33deg, #d4e1ff, #84ecef);
}
.topbar .social-media-icons-top li {
	margin-right: 10px;
	margin-left: 0;
}

.topbar .social-media-icons-top li i {
	margin: 0;
}

.topbar ul.pull-right li {
	margin-right: 0;
	margin-left: 20px;
}

.topbar .news-ticker-block {
	padding: 0;
	background-color: #fff;
}

.topbar .breakingNews > .bn-title,
.topbar .breakingNews > ul > li,
.topbar .breakingNews > ul {
	height: 15px;
	background-color: transparent;
}

.topbar .breakingNews {
	min-height: 15px;
	background-color: #444;
}

.topbar .breakingNews > ul > li > a {
	color: #fff;
	line-height: 24px;
}

.topbar .breakingNews > ul > li > a:hover {
	color: #fff;
}

.topbar .bn-red > .bn-title > span {
	top: 5px;
	border-left-color: #fff;
	border-width: 7px 0 7px 7px;
}

.topbar .breakingNews > .bn-navi {
	display: none;
}

.topbar .breakingNews > .bn-title > h2 {
	font-size: 16px;
	font-weight: 700;
	text-transform: uppercase;
	padding: 0;
	padding-right: 10px;
	background-color: transparent;
	line-height: 24px;
	color: #fff;
	height: 30px;
}

.topbar.white .breakingNews {
	background-color: transparent;
}

.topbar.white .breakingNews > .bn-title > h2 {
	color: #242424;
}

.topbar.white .bn-red > .bn-title > span {
	border-left-color: #242424;
}

.topbar.white .breakingNews > ul > li > a {
	color: #242424;
}

/*MENU CUSTOMIZATIONS*/

#menu-1 {
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}

.megaMenu ul.menu-search-bar-desktop > li > a > i.fa,
.megaMenu ul.menu-search-bar-mobile > li > a > i.fa {
	background: #242424;
}

.megaMenu .drop-down li:hover > a,
.megaMenu .drop-down li:hover > a .description,
.megaMenu ul.menu-mobile-trigger:hover i.fa,
.megaMenu ul.menu-links > li:hover > a {
	color: #fff;
}

.megaMenu .drop-down-medium .list-items a:hover,
.megaMenu .drop-down-large .list-items a:hover,
.megaMenu .drop-down-medium .list-items ol li:hover:after,
.megaMenu .drop-down-large .list-items ol li:hover:after,
.megaMenu .drop-down-medium .nav-tabs.vertical li.active a,
.megaMenu .drop-down-medium .nav-tabs.vertical li:hover a,
.megaMenu .drop-down-medium .nav-tabs.vertical li:active a,
.megaMenu .drop-down-medium .nav-tabs.vertical li:focus a,
.megaMenu .drop-down-large .nav-tabs.vertical li.active a,
.megaMenu .drop-down-large .nav-tabs.vertical li:hover a,
.megaMenu .drop-down-large .nav-tabs.vertical li:active a,
.megaMenu .drop-down-large .nav-tabs.vertical li:focus a {
	color: #f44a56;
}

.megaMenu .drop-down-medium,
.megaMenu .drop-down-large,
.megaMenu .drop-down-medium.nav-tabs,
.megaMenu .drop-down-large.nav-tabs {
	/* drop down with medium size */
	background: #f9f9f9;
	border: none;
	border-top: 2px solid #f44a56;
	box-shadow: 0 3px 4px 1px rgba(0, 0, 0, 0.2);
}

.megaMenu .drop-down-medium .tab-content.vertical,
.megaMenu .drop-down-large.nav-tabs .tab-content.vertical,
.megaMenu .drop-down-medium .nav-tabs.vertical a::after,
.megaMenu .drop-down-large .nav-tabs.vertical a::after {
	background-color: #fff;
	border-color: #ccc;
}

.megaMenu .drop-down-medium .nav-tabs.vertical li::after,
.megaMenu .drop-down-medium .nav-tabs.vertical li::before,
.megaMenu .drop-down-large .nav-tabs.vertical li::after,
.megaMenu .drop-down-large .nav-tabs.vertical li::before {
	background: #ccc;
}

.megaMenu ul.menu-links > li > a {
	font-size: 14px;
}

#menu-1 .menu-links li a.home-icon {
	background-color: #242424;
	padding: 15px;
}

#menu-1 .menu-links li a.home-icon i {
	color: #fff;
	font-size: 20px;
}

.megaMenu .drop-down {
	background-color: #fff;
	box-shadow: 0 3px 4px 1px rgba(0, 0, 0, 0.2);
	border-top: 2px solid #f44a56;
}

#menu-1.megaMenu ul.menu-links > li {
	border-left: 1px solid #f2f2f2;
}

#menu-1.megaMenu ul.menu-links > li:first-child {
	border-left: none;
}

.megaMenu ul.menu-links > li:first-child {
	border-right: none;
}

.megaMenu ul.menu-links > li:last-child {
	border-right: none;
}

.megaMenu ul.menu-links > li:last-child a {
	border: none;
}

#menu-1.megaMenu .drop-down li > a::after {
	background-color: #f44a56;
}

.megaMenu .drop-down li {
	border-color: #f2f2f2;
}

.megaMenu .sticky-container {
	background-color: #fff;
	box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.2);
}

.megaMenu.mega-menu-sticky-move .sticky-container {
	animation-name: sticky_expand;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}

/*MENU CONTAINER SIZE*/

.menu-container-section {
	padding: 0;
	box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.1);
}

#menu-2.megaMenu {
	padding: 15px 0;
}

#menu-2.megaMenu ul.menu-links > li > a {
	height: 2.75em;
}

#menu-2.megaMenu ul.menu-logo > li > a img {
	max-height: 2em;
	margin: 0;
}

#menu-2.megaMenu ul.menu-logo > li > a {
	height: auto;
	padding: 0;
}

#menu-2.megaMenu ul.menu-logo li:hover a::after {
	opacity: 0;
}

#menu-3.megaMenu ul.menu-logo > li > a img {
	max-height: 2em;
	margin: 0;
}

#menu-3.megaMenu ul.menu-logo li:hover a::after {
	opacity: 0;
}

/*MENU DARK*/

.menu-dark {
	background-color: #242424;
}

.menu-dark #menu-2.megaMenu {
	background-color: #242424;
	padding: 0;
	min-height: 3em;
}

.menu-dark #menu-2.megaMenu ul.menu-links > li > a {
	color: #fff;
	height: 3em;
}

.menu-dark .megaMenu .sticky-container {
	background-color: #242424;
}

/*MENU CSS FOR BLOCKS AND LISTING*/

.megaMenu .category-image a img {
	border: none;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease- 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.megaMenu .category-image:hover img {
	-webkit-transition: all 0.6s ease 0s;
	-moz-transition: all 0.6s ease- 0s;
	-o-transition: all 0.6s ease 0s;
	transition: all 0.6s ease 0s;
}

.megaMenu .catname .btn,
.post-tools .btn {
	border-radius: 4px;
	color: #fff;
	font-size: 12px;
	font-weight: 500;
	padding: 5px 10px;
	text-transform: capitalize;
	line-height: 1em;
	border: none;
	background-image: -moz-linear-gradient(center bottom, rgba(0, 0, 0, 0.17) 0%, rgba(255, 255, 255, 0.17) 100%);
}

.megaMenu .grid-1 {
	margin-bottom: 0;
	border: none;
}

.megaMenu .grid-1 .detail {
	background-color: #f9f9f9;
	padding: 15px;
}

.megaMenu .caption h5 {
	font-size: 18px;
	color: #444;
}

.megaMenu .caption h5 a:hover {
	color: #f44a56;
}

.megaMenu .hover-show-div .post-type {
	padding: 10px 12px;
}

.megaMenu .small-grid .col-md-6:last-child .small-post {
	margin-bottom: 0;
	border: none;
}

/*MENU CATEGORY COLOR CODE*/

.megaMenu .category-image .btn:hover {
	color: #fff;
}

.megaMenu .category-image .btn-purple {
	background-color: #9c27b0;
	color: #fff;
}

.megaMenu .category-image .btn-orange {
	background-color: #ff5722;
	color: #fff;
}

.megaMenu .category-image .btn-yellow {
	background-color: #e28712;
	color: #fff;
}

.megaMenu .category-image .btn-gray {
	background-color: #607d8b;
	color: #fff;
}

.megaMenu .category-image .btn-green {
	background-color: #2ecc71;
	color: #fff;
}

.megaMenu .category-image .btn-pink {
	background-color: #e91e63;
	color: #fff;
}

.megaMenu .category-image .btn-red {
	background-color: #f44336;
	color: #fff;
}

.megaMenu .category-image .btn-blue {
	background-color: #00aeef;
	color: #fff;
}

.megaMenu .category-image .btn-black {
	background-color: #242424;
	color: #fff;
}

.megaMenu .category-image .btn-maroon {
	background-color: #7b2143;
	color: #fff;
}

.megaMenu .category-image .btn-lightblue {
	background-color: #8e93c4;
	color: #fff;
}

.megaMenu .category-image .btn-dark-red {
	background-color: #d11141;
	color: #fff;
}

.megaMenu ul.menu-search-bar-desktop .drop-down,
.megaMenu ul.menu-search-bar-mobile .drop-down {
	background: #f9f9f9;
}
.megaMenu ul.menu-search-bar-desktop button[type="search"],
.megaMenu ul.menu-search-bar-mobile button[type="search"],
.megaMenu ul.menu-search-bar-desktop input[type="search"],
.megaMenu ul.menu-search-bar-mobile input[type="search"] {
	background: #fff;
	color: #242424;
}
.megaMenu ul.menu-search-bar-desktop button[type="submit"]::after,
.megaMenu ul.menu-search-bar-mobile button[type="submit"]::after,
.megaMenu ul.menu-search-bar-desktop input[type="submit"]::after,
.megaMenu ul.menu-search-bar-mobile input[type="submit"]::after {
	background: #fff !important;
	border: 1px solid #f44a56;
	color: #242424 !important;
}
.megaMenu ul.menu-search-bar-desktop button[type="submit"],
.megaMenu ul.menu-search-bar-mobile button[type="submit"],
.megaMenu ul.menu-search-bar-desktop input[type="submit"],
.megaMenu ul.menu-search-bar-mobile input[type="submit"] {
	background: #f44a56 !important;
}
.megaMenu ul.menu-search-bar-desktop button[type="submit"]:hover,
.megaMenu ul.menu-search-bar-mobile button[type="submit"]:hover,
.megaMenu ul.menu-search-bar-desktop input[type="submit"]:hover,
.megaMenu ul.menu-search-bar-mobile input[type="submit"]:hover {
	color: #f44a56;
}

/*ZERO GRID*/

.zerogrid-section {
	padding: 0;
	/* margin-left: 5%;
	margin-right: 5%; */
	margin-left: 13%;
	margin-right: -3%;
}

.zerogrid-section img {
	height: auto;
	overflow: hidden;
	transition: all 4.3s ease-out 0s;
	width: 100%;
}

.zerogrid-section .zerogrid {
	width: 100%;
}

.zerogrid-section .post-content {
	background-color: transparent;
	bottom: 0;
	left: 0;
	padding: 15px;
	position: absolute;
	width: 100%;
	z-index: 9;
}

.zerogrid-section .catname {
	position: absolute;
	top: -15px;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease- 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.zerogrid-section .catname a:hover {
	background-color: #444;
}

.zerogrid-section .btn {
	border-radius: 3px;
	color: #fff;
	font-size: 12px;
	font-weight: 500;
	padding: 5px;
	text-transform: capitalize;
}

.zerogrid-section .post-content h5 {
	color: #fff;
	font-size: 18px;
	font-weight: 500;
	text-transform: capitalize;
}

.zerogrid-section .post-content h5 a {
	color: #fff;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease- 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.zerogrid-section .post-content h5 a:hover {
	color: #f44a56;
}

.zerogrid-section .post-content .post-tools {
	list-style: outside none none;
	margin: 0;
	padding: 0;
}

.zerogrid-section .post-content .post-tools li::after {
	color: #e1e1e1;
	content: "/";
	padding: 0 10px;
}

.zerogrid-section .grid-item,
.zerogrid-section .post-thumb {
	overflow: hidden;
	position: relative;
}

.zerogrid-section .zerogrid .wrap-col {
	margin: 0;
}

.zerogrid-section .post-content .post-tools li,
.zerogrid-section .post-content .post-tools li a {
	color: #e1e1e1;
}

.zerogrid-section .post-content .post-tools li:hover,
.zerogrid-section .post-content .post-tools li a:hover {
	color: #f44a56;
}

.zerogrid-section .post-thumb::after {
	background: rgba(0, 0, 0, 0.9);
	/* fallback for old browsers */
	background: -webkit-linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.9));
	/* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.9));
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+,Opera 12+,Safari 7+ */
	/*background: rgba(0, 0, 0, 0.6) none repeat scroll 0 0;*/
	content: "\a ";
	height: 100%;
	left: 0;
	opacity: 1;
	position: absolute;
	top: 0;
	transition: all 0.5s ease 0s;
	width: 100%;
}

.full-width-slider {
	background-color: #f1f1f1;
	margin-top: -100px;
}

.full-width-slider .detail {
	padding: 15px;
	background-color: #fff;
}

.full-width-slider .picture .category-image .catname {
	left: 20px;
	bottom: 20px;
	position: absolute;
}

.full-width-slider .caption h5 {
	margin-top: 0;
}

.full-width-slider .heading-read-more {
	float: right;
	margin-right: 80px;
	padding: 7px;
}

.full-width-slider .owl-nav {
	margin: 0;
	position: absolute;
	right: 15px;
	top: -72px;
}

.full-width-slider .owl-theme .owl-nav div.owl-next,
.full-width-slider .owl-theme .owl-nav div.owl-prev {
	background-color: #444;
	border: medium none;
	border-radius: 5px;
	height: 30px;
	line-height: 24px;
	width: 30px;
}

.full-width-slider .owl-theme .owl-nav div.owl-prev::after,
.full-width-slider .owl-theme .owl-nav div.owl-next::after {
	color: #fff;
	font-size: 20px;
}

/*VIDEO SECTION MAIN*/

#video-gallery {
	width: 100%;
}

.videoGallery .rsTmb {
	padding: 20px;
}

.videoGallery .rsThumbs .rsThumb {
	width: 220px;
	height: 80px;
	border-bottom: 1px solid #2e2e2e;
}

.videoGallery .rsThumbs {
	width: 220px;
	padding: 0;
}

.videoGallery .rsThumb:hover {
	background: #000;
}

.videoGallery .rsThumb.rsNavSelected {
	background-color: #02874a;
	border-bottom: -color #02874a;
}

.sampleBlock {
	left: 3%;
	top: 1%;
	width: 100%;
	max-width: 400px;
}
.groups {
	width: 100%;
}

@media screen and (max-width: 768px) {
	.form-group {
		border: 1px solid red;
		max-width: 100%;
	}
	.videoGallery .rsTmb {
		padding: 6px 8px;
	}
	.videoGallery .rsTmb h5 {
		font-size: 12px;
		line-height: 17px;
	}
	.videoGallery .rsThumbs.rsThumbsVer {
		width: 100px;
		padding: 0;
	}
	.videoGallery .rsThumbs .rsThumb {
		width: 100px;
		height: 47px;
	}
	.videoGallery .rsTmb span {
		display: none;
	}
	.videoGallery .rsOverflow,
	.royalSlider.videoGallery {
		height: 300px !important;
	}
	.sampleBlock {
		font-size: 14px;
	}

	.zerogrid-section {
		/* padding: 0;
	border: 1px solid red; */
		margin-left: 0%;
		margin-right: 0%;
		margin-top: -25px;
	}
}

/*PARALLEL POST STYLE*/

.parallel-post-style {
	padding-bottom: 50px;
}

.parallel-post-style .grid-box {
	position: relative;
	overflow: hidden;
	margin-bottom: 30px;
	background-color: #fcfcfc;
}

.parallel-post-style .grid-box ul {
	padding-left: 0px;
	font-size: 14px;
	text-transform: capitalize;
	overflow: hidden;
	position: relative;
	border-radius: 0;
	box-shadow: none;
	list-style: none;
	margin-bottom: 0;
}

.parallel-post-style .grid-box .thumb img {
	width: auto;
	max-height: 160px;
}

.parallel-post-style .grid-box .post-content {
	padding: 10px 0;
	position: relative;
	overflow: hidden;
}

.parallel-post-style .grid-box .post-content h5 {
	font-size: 16px;
	margin-top: 0;
	text-transform: capitalize;
	font-weight: 500;
}

.parallel-post-style .grid-box .post-content .post-tools {
	margin-bottom: 10px;
}

.parallel-post-style .grid-box p {
	margin-bottom: 0;
}

.parallel-post-style .grid-box .heading-read-more {
	float: none;
	padding: 0;
}

/*ARCHIVE POSTS*/

.new-archives {
	background-color: #f1f1f1;
	padding-bottom: 60px;
}

.new-archives .section .nav-tabs.nav-justified > li > a {
	text-transform: uppercase;
	font-weight: 500;
}

.new-archives .nav-tabs.nav-justified > li > a {
	font-size: 14px;
}

.new-archives .post-content h3 {
	margin-bottom: 0;
}

.new-archives .small-grid .small-post {
	border-bottom: 1px solid #ccc;
}

.new-archives .latest-news-grid .detail {
	background-color: #fff;
	padding: 15px;
}

.new-archives .small-grid {
	margin-bottom: 0;
}

/*CATEGORY SIDEBAR*/

.category-sidebar {
	background-color: #242424;
}

.category-sidebar .ad-div {
	padding-top: 10px;
	margin-bottom: 30px;
}

.category-widget h4 {
	background-color: #f44a56;
	color: #fff;
	text-transform: capitalize;
	font-weight: 700;
	padding: 10px;
	font-size: 16px;
	margin-top: 0;
}

.category-widget .category-widget-detail {
	padding: 15px;
}

.category-widget .category-widget-detail ul {
	padding-left: 0;
	list-style: none;
	margin: 0;
}

.category-widget .category-widget-detail ul li {
	padding: 8px 0;
	border-bottom: 1px solid #999;
}

.category-widget .category-widget-detail ul li:last-child {
	border-bottom: none;
}

.category-widget .category-widget-detail ul li a {
	color: #fff;
	text-transform: capitalize;
	font-size: 14px;
	display: block;
}

.sidebar-middle .category-widget .category-widget-detail ul li a:hover {
	color: #f44a56;
}

.category-widget .category-widget-detail ul li a i {
	margin-right: 10px;
	font-size: 14px;
}

.category-widget .category-widget-detail.category-widget-block ul li a i {
	display: block;
	font-size: 30px;
	text-align: center;
	margin-bottom: 10px;
	margin-right: 0;
}

.category-widget .category-widget-detail.category-widget-block ul li {
	text-align: center;
	padding: 15px 0;
	border-bottom: none;
}

.category-widget .category-widget-block ul li:nth-child(even) {
	background-color: #444;
}

.category-widget .category-widget-block ul li a {
	font-size: 16px;
	text-align: center;
	text-transform: capitalize;
}

.sidebar-middle {
	background-color: transparent;
	padding: 0 15px;
	border-right: 1px solid #f5f5f5;
	border-left: 1px solid #f5f5f5;
}

.sidebar-middle .category-widget .category-widget-detail ul li a {
	color: #444;
}

.sidebar-middle .category-widget .category-widget-detail {
	padding: 0;
}

.category-widget .category-widget-detail ul li {
	border-bottom: 1px solid #f5f5f5;
}

.category-widget .detail .caption h5 {
	font-size: 16px;
	text-align: left;
}

.category-widget .detail ul {
	margin-bottom: 0;
	text-align: center;
}

.category-widget .detail ul li {
	float: none;
	display: inline;
}

/*VIDEO SECTION*/

.video-section {
	background-color: #f1f1f1;
}

.video-section iframe {
	border: 0 none;
	min-height: 425px;
	width: 100%;
}

.video-section .rsDefault .rsThumb.rsNavSelected {
	background-color: #f44a56;
}

.bx-controls-direction a {
	display: none;
}

.scrollup {
	background-color: #f44a56;
	border-radius: 5px;
	bottom: 34px;
	box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
	display: none;
	height: 40px;
	opacity: 0.5;
	padding: 5px;
	position: fixed;
	right: 20px;
	width: 40px;
}

.scrollup:hover {
	opacity: 1;
}

.scrollup:focus {
	text-decoration: none;
}

.scrollup i {
	color: #fff;
	display: block;
	line-height: 30px;
	text-align: center;
}

.info {
	color: #aaaaaa;
	padding-right: 10px;
}

.info .date,
.info .comments-count,
.info .likes {
	padding-right: 20px;
}

.three-grid {
	border-bottom: none !important;
}

.three-grid .info span {
	font-size: 12px;
}

.custom {
	padding-left: 25px;
}

.custom-box {
	width: 32%;
}

.modal-header {
	border-bottom: 1px solid #e5e5e5;
	min-height: 16.43px;
	padding: 15px;
}

.modal-content {
	border-radius: 0;
}

.modal-body {
	overflow: hidden;
}

.custom small {
	font-weight: bold;
	color: #000;
}

.author {
	padding-top: 15px;
}

.author .pic .name {
	color: #aaaaaa;
	padding-left: 12px;
}

.post-entry blockquote {
	background-color: #f9f9f9;
	font-size: 16px;
	line-height: 26px;
	margin: 30px 80px;
	padding: 10px 20px;
	position: relative;
}

.post-entry blockquote::before {
	color: #f44a56;
	content: "";
	display: block;
	font-family: "FontAwesome";
	font-size: 34px;
	left: -70px;
	position: absolute;
	top: 15px;
}

.post-entry .post-tools li {
	font-size: 16px;
	line-height: 50px;
}

.post-entry h3 {
	margin-top: 0;
	text-transform: capitalize;
}

.post-entry h2 {
	margin-top: 0;
	text-transform: capitalize;
	font-weight: 500;
}

.post-entry .post-image {
	margin: 20px 0;
	position: relative;
	overflow: hidden;
}

.post-entry ul.post-entry-poits {
	list-style: none;
	margin-bottom: 30px;
}

.post-entry ul.post-entry-poits li {
	padding: 6px 0;
	font-size: 16px;
	color: #777;
}

.post-entry ul.post-entry-poits li i {
	font-size: 16px;
	color: #f44a56;
	margin-right: 10px;
}

.post-entry img.pull-left {
	margin-bottom: 10px;
	margin-right: 20px;
}

.post-entry .picture {
	margin-bottom: 30px;
	position: relative;
}
.post-entry .picture.quote-post h2 {
	position: absolute;
	top: 10px;
	padding: 30px 70px;
	color: #fff;
}
.post-entry .picture.quote-post::before {
	background: rgba(0, 0, 0, 0.7) none repeat scroll 0 0;
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}
.post-entry .picture.quote-post h2::before {
	color: #fff;
	content: "";
	display: block;
	font-family: "FontAwesome";
	font-size: 20px;
	left: 25px;
	position: absolute;
}
.post-entry .catname {
	margin-bottom: 10px;
}

.post-entry .social-share {
	position: relative;
	overflow: hidden;
	margin-bottom: 20px;
	padding-left: 0;
}

.post-entry .social-share li {
	display: inline-block;
	margin: 0 5px 5px 0;
	background-color: #244872;
	border-radius: 4px;
	box-shadow: none;
	background-image: -moz-linear-gradient(center bottom, rgba(0, 0, 0, 0.17) 0%, rgba(255, 255, 255, 0.17) 100%);
}

.post-entry .social-share li i {
	margin-right: 5px;
}

.post-entry .social-share li a {
	color: #fff;
	display: block;
	padding: 5px 25px;
}

.post-entry .social-share li.dribbble {
	background-color: #f46899;
}

.post-entry .social-share li.vimeo {
	background-color: #00b3ec;
}

.post-entry .social-share li.stumbleupon {
	background-color: #eb4924;
}

.post-entry .social-share li.reddit {
	background-color: #5f99cf;
}

.post-entry .social-share li.facebook {
	background-color: #3c599f;
}

.post-entry .social-share li.rss {
	background-color: #f26522;
}

.post-entry .social-share li.lastfm {
	background-color: #d51007;
}

.post-entry .social-share li.flickr {
	background-color: #ff0084;
}

.post-entry .social-share li.instagram {
	background-color: #685243;
}

.post-entry .social-share li.google {
	background-color: #cf3d2e;
}

.post-entry .social-share li.linkedin {
	background-color: #0085ae;
}

.post-entry .social-share li.pinterest {
	background-color: #cc2127;
}

.post-entry .social-share li.twitter {
	background-color: #32ccfe;
}

.post-entry .post-share {
	color: #ccc;
	float: right;
	line-height: 25px !important;
	text-align: center;
	text-transform: capitalize;
}

.post-entry .post-share strong {
	font-size: 30px;
	display: block;
	color: #ccc;
}

.post-entry .post-tools li.post-share::after {
	color: #444;
	content: normal;
	padding: 0;
}

.post-entry .tag-list {
	padding: 0;
}

.post-entry .tag-list a {
	border-radius: 4px;
	font-size: 12px;
	padding: 0 10px;
	box-shadow: none;
}

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%;
	/* 16:9 */
	padding-top: 25px;
	height: 0;
	margin-bottom: 20px;
}

.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.post-entry iframe {
	width: 100%;
	height: 100%;
}

.author-box {
	padding: 15px;
	background-color: #f9f9f9;
	position: relative;
	overflow: hidden;
	margin: 50px 0;
}

.author-box h3 {
	font-size: 20px;
	margin-top: 0;
	text-transform: capitalize;
}

.author-box p {
	margin-bottom: 0;
	color: #777;
	font-size: 16px;
	line-height: 30px;
}

.author-box ul {
	padding: 0;
}

.author-box .social-media-icons {
	margin: 0;
}

/*SOCIAL SHARE 2*/

.post-entry .social-share.social-share-style-2 li {
	width: 100%;
	text-align: center;
	margin: 0;
	margin-bottom: 5px;
}

.post-entry .social-share.social-share-style-2 li a {
	padding: 10px 0;
}

.post-entry .social-share.social-share-style-2 li.post-share {
	background-color: transparent;
	background: none;
	border-bottom: 2px solid #f44a56;
	border-radius: 0;
	margin-bottom: 30px;
	padding-bottom: 10px;
	color: #999;
}

.share-ping {
	border-color: #f44a56 transparent transparent;
	border-style: solid;
	border-width: 16px;
	height: 0;
	left: 20px;
	position: absolute;
	top: 65px;
	width: 0;
}

.post-tools.nolineheight li {
	line-height: 1.2em;
}

.post-entry .social-share.social-share-style-2 li.post-share strong {
	color: #f44a56;
	font-size: 38px;
	line-height: 30px;
}

.next-n-prev {
	margin: 30px 0;
	position: relative;
	overflow: hidden;
	padding: 20px 0;
	border-top: 1px solid #f1f1f1;
	border-bottom: 1px solid #f1f1f1;
}

.next-n-prev .pre-post {
	border-right: 1px solid #f1f1f1;
	text-align: left;
	padding-right: 15px;
}

.next-n-prev .next-post {
	text-align: right;
	padding-left: 15px;
}

.next-n-prev span {
	margin-right: 10px;
	font-size: 18px;
}

.next-n-prev .next-post i {
	margin-left: 10px;
	color: #f44a56;
}

.next-n-prev .pre-post i {
	margin-right: 10px;
	color: #f44a56;
}

.next-n-prev a {
	color: #f44a56;
}

.post-entry .related-posts .picture {
	margin-bottom: 0;
}

.post-entry .related-posts .post-tools li {
	font-size: 12px;
	line-height: 24px;
}

.post-entry .related-posts .caption h5 {
	font-size: 16px;
}

.post-entry .related-posts {
	margin-bottom: 20px;
}

.post-entry .related-posts .grid-1 {
	margin-bottom: 30px;
	border: none;
}

.post-entry .related-posts .grid-1 .detail {
	padding: 10px;
	background-color: #f9f9f9;
}

/* HEADER*/
.logo {
	margin-top: 20px;
}
.logo-row {
	padding: 25px 0;
}

.logo-center .logo a {
	display: table;
	margin: 0 auto;
}

/*.dark-logo-row {
	border-bottom: 1px solid #444;
	background-color: #242424;
}*/
.dark-logo-row {
	background: rgba(0, 0, 0, 0) url("../images/fullslide7.jpg") no-repeat scroll center center / cover;
	position: relative;
}
.dark-logo-row::after {
	background: rgba(0, 0, 0, 0.9);
	content: "\a ";
	height: 100%;
	left: 0;
	opacity: 1;
	position: absolute;
	top: 0;
	transition: all 0.5s ease 0s;
	width: 100%;
}
.dark-logo-row .logo,
.dark-logo-row .advertisement {
	position: relative;
	z-index: 9;
}

.dark-logo-row .advertisement {
	float: right;
}
.navbar-fixed-top {
	position: fixed !important;
}

.sticky {
	position: fixed;
	width: 100%;
	left: 0;
	top: 0;
	z-index: 100;
	border-top: 0;
	background: #fff;
}

/*FEATURED SLIDER CSS */

.featured-news-slider .item,
.featured-news-slider .post-thumb {
	position: relative;
	overflow: hidden;
}

.featured-news-slider .item,
.featured-news-slider .post-thumb a {
	display: block;
	z-index: 9;
}

.featured-news-slider .post-content {
	position: absolute;
	left: 0px;
	bottom: 0px;
	background-color: transparent;
	width: 100%;
	padding: 15px 20px 15px;
	z-index: 9;
}

.featured-news-slider .post-content h5 {
	font-size: 18px;
	color: #fff;
	font-weight: 500;
}

.featured-news-slider .post-content h5 a {
	color: #fff;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease- 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.featured-news-slider .post-content h5 a:hover {
	color: #f44a56;
}

.featured-news-slider .post-content .post-tools {
	margin: 0px;
	padding: 0px;
	list-style: none;
}

.featured-news-slider .post-content .post-tools li::after {
	color: #e1e1e1;
	content: "/";
	padding: 0 10px;
}

.featured-news-slider .post-content .post-tools li {
	float: left;
	color: #e1e1e1;
	font-size: 14px;
	text-transform: capitalize;
}

.featured-news-slider .post-content .post-tools li a {
	color: #e1e1e1;
}
.featured-news-slider .post-content .post-tools li strong {
	color: #e1e1e1;
}

.featured-news-slider .btn {
	border-radius: 3px;
	color: #fff;
	font-size: 12px;
	font-weight: 500;
	padding: 5px;
	text-transform: capitalize;
}
.featured-news-slider .catname {
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease- 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
.featured-news-slider .catname:hover .btn {
	background-color: #242424;
}

.featured-slider img {
	width: 100%;
	height: auto;
	transition: all 4.3s ease-out 0s;
	overflow: hidden;
}

.post-thumb:after {
	content: "\A";
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.6);
	/* fallback for old browsers */
	background: -webkit-linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.9));
	/* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 1));
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+,Opera 12+,Safari 7+ */
	opacity: 1;
	-webkit-transition: all 0.5s ease 0s;
	-moz-transition: all 0.5s ease- 0s;
	-o-transition: all 0.5s ease 0s;
	transition: all 0.5s ease 0s;
}

/*.post-thumb:hover:after {
	opacity: 0;
}*/

.hover-show-div {
	position: absolute;
	top: 30px;
	right: 20px;
}

.hover-show-div .post-type {
	background-color: rgba(244, 74, 86, 0.7);
	border-radius: 4px;
	overflow: hidden;
	padding: 10px 12px;
	position: relative;
	font-size: 20px;
}

.hover-show-div .post-type:hover {
	background-color: rgba(244, 74, 86, 1);
}

.hover-show-div .post-type i {
	color: #fff;
}

.main-content .socialHolder .dropdown-menu {
	top: 41 !important;
	left: 50px;
}

.main-content .socialHolder ul {
	top: 40px !important;
}

.socialHolder ul li a {
	border-radius: 0;
}

.socialShare > a {
	padding: 10px 19px;
	background-color: rgba(106, 202, 195, 0.7);
}

.socialHolder .dropdown-menu {
	background-color: transparent;
	border: medium none;
	margin: 0;
	min-width: 50px !important;
	padding: 0;
}

.socialHolder .btn-info {
	border: none;
	margin: 0px;
	border-radius: 0;
}

.main-content .hover-show-div {
	position: absolute;
	right: 20px;
	top: 30px;
}

.mobile-social-share ul {
	float: right;
	list-style: none outside none;
	margin: 0;
	min-width: 61px;
	padding: 0;
}

.share {
	min-width: 17px;
}

.mobile-social-share li {
	display: block;
	font-size: 18px;
	list-style: none outside none;
	margin-bottom: 3px;
	margin-left: 4px;
	margin-top: 3px;
}

.btn-share {
	background-color: #bebebe;
	border-color: #cccccc;
	color: #333333;
}

.btn-twitter {
	background-color: #3399cc !important;
	width: 50px;
	color: #ffffff !important;
}

.btn-facebook {
	background-color: #3d5b96 !important;
	width: 50px;
	color: #ffffff !important;
}

.btn-facebook {
	background-color: #3d5b96 !important;
	width: 50px;
	color: #ffffff !important;
}

.btn-google {
	background-color: #dd3f34 !important;
	width: 50px;
	color: #ffffff !important;
}

.btn-linkedin {
	background-color: #1884bb !important;
	width: 50px;
	color: #ffffff !important;
}

.btn-pinterest {
	background-color: #cc1e2d !important;
	width: 50px;
	color: #ffffff !important;
}

.btn-mail {
	background-color: #ffc90e !important;
	width: 50px;
	color: #ffffff !important;
}

.caret {
	border-left: 4px solid rgba(0, 0, 0, 0);
	border-right: 4px solid rgba(0, 0, 0, 0);
	border-top: 4px solid;
	display: inline-block;
	height: 0;
	margin-left: 2px;
	vertical-align: middle;
	width: 0;
}

.main-content {
	width: 100%;
	margin-top: -61px;
	/*background: url(../images/content_background.png);*/
}

.section-title {
	width: 100%;
	float: left;
	clear: both;
	margin-bottom: 25px;
}

.featured-slider-2.owl-carousel .owl-item img {
	height: auto;
	overflow: hidden;
	width: auto;
	transition: all 4.3s ease-out 0s;
}

/*FULL WIDTH SLIDER MAIN*/

.full-with-slider-section {
	position: relative;
	overflow: hidden;
	background-color: #242424;
	padding: 0;
}

.full-with-slider-section .post-thumb::after {
	background: none;
	content: "";
}

.full-with-slider-section .post-content {
	background-color: rgba(0, 0, 0, 0.6);
	bottom: 0;
	left: 30%;
	padding: 20px;
	position: absolute;
	width: 370px;
	z-index: 9;
	text-align: center;
	display: none;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease- 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.full-with-slider-section .item,
.full-with-slider-section .post-thumb {
	position: relative;
	overflow: hidden;
}

.full-with-slider-section .post-content h5 {
	font-size: 18px;
	color: #fff;
	font-weight: 500;
}

.full-with-slider-section .post-content h5 a {
	color: #fff;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease- 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.full-with-slider-section .post-content h5 a:hover {
	color: #f44a56;
}

.full-with-slider-section .post-content .post-tools {
	padding: 0px;
	list-style: none;
	margin: 0 auto;
	display: table;
}

.full-with-slider-section .post-content .post-tools li {
	float: left;
	color: #fff;
	font-size: 14px;
	text-transform: capitalize;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease- 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.full-with-slider-section .post-content .post-tools li::after {
	color: #e1e1e1;
	content: "/";
	padding: 0 10px;
}
.full-with-slider-section .post-content .post-tools li,
.full-with-slider-section .post-content .post-tools li a,
.full-with-slider-section .post-content .post-tools li a strong {
	color: #e1e1e1;
}

.full-with-slider-section .post-content .post-tools li:last-child::after {
	display: none;
}

.full-with-slider-section .post-content .post-tools li:hover {
	color: #f44a56;
}

.full-with-slider-section .post-content .post-tools li a:hover {
	color: #f44a56;
}

.full-with-slider-section .post-content .post-tools li a {
	color: #fff;
}

.full-with-slider-section .post-content .post-tools li:last-child {
	margin: 0;
}

.full-with-slider-section .btn {
	border-radius: 3px;
	color: #fff;
	font-size: 12px;
	font-weight: 500;
	padding: 5px;
	text-transform: capitalize;
	line-height: 1em;
}

.full-with-slider-section img {
	width: 100%;
	height: auto;
	transition: all 4.3s ease-out 0s;
	overflow: hidden;
}

.full-with-slider-section .owl-carousel .owl-stage-outer {
	overflow: inherit;
}

.full-with-slider-section .owl-carousel .owl-item {
	padding: 60px 60px 0 60px;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease- 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.full-with-slider-section .owl-carousel .owl-item.active.center {
	padding: 0;
	margin: 25px 0;
}

.full-with-slider-section .owl-carousel .owl-item.active.center .post-content {
	display: block;
}

.full-with-slider-section .owl-theme .owl-nav {
	margin-top: 0;
}

.full-with-slider-section .owl-theme .owl-nav .owl-next {
	right: -30px;
	position: absolute;
	top: 45%;
	height: 50px;
	width: 50px;
	padding: 10px;
	line-height: 28px;
}

.full-with-slider-section .owl-theme .owl-nav .owl-prev {
	left: -30px;
	position: absolute;
	top: 45%;
	height: 50px;
	width: 50px;
	padding: 10px;
	line-height: 28px;
}

/*FULL WIDTH STYLE 2*/

.full-width-style-2 {
	background-color: transparent;
}

.full-width-style-2 .owl-carousel .owl-item {
	padding: 0;
}

.full-width-style-2 .owl-carousel .owl-item.active.center {
	margin: 0;
}

/*OWL SLIDER CUSTOM CSS*/

.owl-theme .owl-nav div.owl-prev,
.owl-theme .owl-nav div.owl-next {
	background: transparent;
	border-radius: 3px;
	width: 40px;
	height: 40px;
	text-align: center;
	float: left;
	opacity: 1;
	font-size: 0;
}

.owl-theme .owl-nav div.owl-next {
	float: right;
}

.owl-theme .owl-nav div.owl-prev:after,
.owl-theme .owl-nav div.owl-next:after {
	content: "\f104";
	font-family: FontAwesome;
	font-size: 30px;
	color: #fff;
}

.owl-theme .owl-nav div.owl-next:after {
	content: "\f105";
}

.owl-theme .owl-nav div {
	padding: 3px 0px;
	margin: 0px;
}

.owl-theme .owl-nav div.owl-next,
.owl-theme .owl-nav div.owl-prev {
	border: none;
	color: #999;
	line-height: 25px;
	border-radius: 0;
	width: 35px;
	height: 35px;
	background-color: #242424;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease- 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.owl-theme .owl-nav div.owl-next:hover,
.owl-theme .owl-nav div.owl-prev:hover {
	background-color: #f44a56;
	color: #fff;
}

/*CSS PREE LOADR*/

#preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	/* background: #2c3e50; */
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 100%;
	z-index: 99;
}

#status {
	width: 50px;
	height: 30px;
	position: fixed;
	left: 50%;
	top: 50%;
	margin: -25px 0 0 -15px;
}

.spinner {
	margin: 0px auto;
	width: 50px;
	height: 30px;
	text-align: center;
	font-size: 10px;
}

.spinner > div {
	background-color: #fff;
	height: 100%;
	width: 6px;
	display: inline-block;
	-webkit-animation: stretchdelay 1.2s infinite ease-in-out;
	animation: stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
	-webkit-animation-delay: -1.1s;
	animation-delay: -1.1s;
}

.spinner .rect3 {
	-webkit-animation-delay: -1s;
	animation-delay: -1s;
}

.spinner .rect4 {
	-webkit-animation-delay: -0.9s;
	animation-delay: -0.9s;
}

.spinner .rect5 {
	-webkit-animation-delay: -0.8s;
	animation-delay: -0.8s;
}

@-webkit-keyframes stretchdelay {
	0%,
	40%,
	100% {
		-webkit-transform: scaleY(0.4);
	}
	20% {
		-webkit-transform: scaleY(1);
	}
}

@keyframes stretchdelay {
	0%,
	40%,
	100% {
		transform: scaleY(0.4);
		-webkit-transform: scaleY(0.4);
	}
	20% {
		transform: scaleY(1);
		-webkit-transform: scaleY(1);
	}
}

/* COMMENT SECTION */

.post-entry .comment-list {
	list-style: outside none none;
	margin: 0;
	padding: 0;
}

.post-entry .comment li {
	padding-right: 10px;
}

.post-entry .comment li:last-child {
	border-bottom: 0;
}

.post-entry .comment-info {
	background-color: #fcfcfc;
	display: block;
	margin-bottom: 30px;
	overflow: hidden;
	padding: 15px;
	position: relative;
}

.post-entry .comment-info img {
	margin-right: 10px;
	margin-bottom: 0;
}

.post-entry .comment-info p {
	margin-bottom: 0;
}

.post-entry .comment-info img {
	max-width: 90px;
}

.post-entry .comment-info .author-title {
	color: #444;
	line-height: 16px;
	margin-bottom: 10px;
}
.post-entry .comment-info .author-desc {
	margin-left: 115px;
}
.post-entry .author-title strong {
	color: #444;
	font-size: 16px;
	text-transform: capitalize;
}

.post-entry .author-title .list-inline li {
	border-right: 1px solid #999;
	padding-right: 10px;
}

.post-entry .author-title .list-inline li:last-child {
	border: 0;
	padding-right: 0;
}

.post-entry .author-title .list-inline li a {
	font-size: 16px;
	color: #777;
}

.post-entry .author-title .list-inline li a:hover {
	color: #f44a56;
}

.post-entry .comment-date {
	border-left: 1px solid #777;
	color: #777;
	font-size: 12px;
	margin-left: 17px;
	padding-left: 17px;
}

.post-entry .comment-list .children {
	list-style: outside none none;
	padding-left: 67px;
}

/* .post-entry .commentform {} */

.post-entry .commentform .form-group {
	margin-bottom: 20px;
}

.post-entry .commentform .form-group input {
	height: 40px;
	border-radius: 0;
	box-shadow: none;
	border: none;
	border-radius: 4px;
	background-color: #f9f9f9;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease- 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.post-entry .commentform .form-group textarea {
	border-radius: 0;
	box-shadow: none;
	border: none;
	border-radius: 4px;
	background-color: #f9f9f9;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease- 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.post-entry .commentform .form-group input:focus,
.post-entry .commentform .form-group textarea:focus {
	background-color: #f9f9f9;
	box-shadow: none;
}

/*FULL WIDTH IMAGE SINGLE POST*/

.full-width-post-section {
	padding: 0;
}

.full-width-post-section img {
	width: 100%;
}

.full-width-post-section .post-content {
	background-color: rgba(0, 0, 0, 0.8);
	bottom: 0;
	left: 30%;
	padding: 20px;
	position: absolute;
	width: 500px;
}

.full-width-post-section .post-content h5 {
	font-size: 26px;
	color: #fff;
	text-transform: capitalize;
}

.full-width-post-section .post-content .post-tools a,
.full-width-post-section .post-content .post-tools li,
.full-width-post-section .post-tools li::after {
	color: #fff;
}

/*FULL WIDTH SLIDER*/

.post-content h3 {
	padding: 0px;
	font-weight: normal;
	margin-bottom: 10px;
	margin-top: 0px;
	line-height: 20px;
}

.post-content h3 a {
	text-decoration: none;
	color: #222;
}

.thumb iframe {
	width: 100%;
	border: 0px;
	min-height: 350px;
}

.fullwidth-news-post-excerpt .owl-theme .owl-nav {
	position: absolute;
	top: 35%;
	z-index: 999;
	left: 0;
	width: 100%;
}

.thumb img {
	width: 100%;
	height: auto;
}

/*  PAGINATION */

.pagination-holder {
	clear: both;
	float: left;
	width: 100%;
	margin-bottom: 25px;
}

.pagination-holder nav {
	background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
	box-shadow: none;
	height: inherit;
	text-align: center;
}

.pagination-holder nav ul {
	margin: 0;
	padding: 0;
}

.pagination-holder nav ul li {
	display: inline-block;
	margin-left: 5px;
}

.pagination-holder .pagination > li > a,
.pagination-holder .pagination > li > span,
.pagination-holder .pagination > li:first-child > a,
.pagination-holder .pagination > li:first-child > span,
.pagination-holder .pagination > li:last-child > a,
.pagination-holder .pagination > li:last-child > span {
	border-radius: 4px;
	font-size: 15px;
	height: 40px;
	padding: 0;
	line-height: 40px;
	width: 40px;
	color: #444;
}

.pagination-holder .pagination > .active > a,
.pagination-holder .pagination > .active > span,
.pagination-holder .pagination > .active > a:hover,
.pagination-holder .pagination > .active > span:hover,
.pagination-holder .pagination > .active > a:focus,
.pagination-holder .pagination > .active > span:focus {
	background-color: #f44a56;
	border-color: #f44a56;
	color: #fff;
}

.pagination-holder .pagination > li > a:hover,
.pagination-holder .pagination > li > span:hover,
.pagination-holder .pagination > li > a:focus,
.pagination-holder .pagination > li > span:focus {
	background-color: #f44a56;
	border-color: #f44a56;
	color: #fff;
}

/* AUTHOR LIST */

.authors-grid {
	width: 100%;
	padding: 0px;
	margin: 0px;
}

.authors-grid li {
	list-style: none;
}

.authors-grid .author-box {
	width: 100%;
	float: left;
	clear: both;
	border-radius: 2px;
	overflow: hidden;
	margin: 15px 0;
	text-align: center;
}

.authors-grid .author-box h3 {
	margin: 15px 0 12px;
	font-size: 20px;
	font-weight: 500;
	text-transform: uppercase;
	width: 100%;
	float: left;
}

.authors-grid .author-box p {
	color: #fff;
	margin: 0px;
}

.auth-arti-no {
	float: left;
}

.auth-arti-no a {
	background-color: #f44a56;
	color: #fff;
	font-size: 20px;
	font-weight: bold;
	padding: 15px;
}

.auth-arti-name {
	float: left;
}

/*ARTICLE CAPTION AND RELATED CSS */

.picture .category-image {
	position: relative;
	overflow: hidden;
}

.picture .category-image .catname {
	position: absolute;
	bottom: 20px;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease- 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.picture .category-image:hover img {
	transform: scale(1.1);
}

.category-image a img {
	height: auto;
	overflow: hidden;
	transition: all 0.9s ease-out 0s;
	width: 100%;
}

.picture .overlay-category {
	background-color: #fff;
	bottom: 0;
	font-size: 12px;
	font-weight: 400;
	left: 10px;
	letter-spacing: 2px;
	margin-bottom: 0;
	margin-top: 0;
	padding: 6px 10px;
	position: absolute;
	z-index: 100;
}

.grid-1 .detail p {
	color: #777;
	font-size: 16px;
	line-height: 1.8em;
	margin-bottom: 0;
	margin-top: 10px;
}

.post-detail .detail {
	padding-top: 20px;
}

.detail .info {
	color: #aaaaaa;
}

.detail .info .likes {
	padding-right: 16px;
}

.detail .info i {
	padding-right: 2px;
}

.caption {
	position: relative;
	/* overflow: hidden; */
}

.caption a {
	color: #242424;
}

.caption h5 {
	color: #242424;
	font-size: 18px;
	font-weight: 500;
	margin-top: 0;
	text-transform: capitalize;
	width: 300px;
}

.caption a:hover {
	color: #f44a56;
}

.small-caption a {
	color: #444;
}

.small-caption {
	color: #000;
	font-size: 20px;
	font-weight: 400;
	padding-top: 10px;
	padding-bottom: 10px;
}

/* GRID CSS */

.grid-1 {
	border-bottom: 1px solid #f1f1f1;
	border-radius: 0;
	margin-bottom: 20px;
	width: 100%;
}

.post1 {
	/* border: 1px solid red; */
	width: 100%;
	display: flex;
	flex-direction: row;
}

.grid-1 .detail {
	/* overflow: hidden; */
	padding: 15px 0;
	position: relative;
}

.category-image img:hover {
	transform: scale(1.6);
	overflow: hidden;
	width: 100%;
}

.grey-inner-values span {
	margin-right: 10px;
}

.grid-1 .catname {
	bottom: 0px;
	left: 20px;
	position: absolute;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease- 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.grid-1 .thumb .catname {
	bottom: 20px;
	left: 22px;
	position: absolute;
	font-style: normal;
}

.post-content h3 a:hover {
	color: #444 !important;
}

.grid-1 .catname a {
	color: #fff;
}
.listing {
	margin-bottom: 0;
}
.listing .grid-1 {
	margin-bottom: 30px;
	position: relative;
	overflow: hidden;
	border: none;
}
.listing .grid-1:last-child {
	margin-bottom: 0;
}

.grid-1 ul {
	padding-left: 0px;
	color: #fff;
	font-size: 14px;
	text-transform: capitalize;
	overflow: hidden;
	position: relative;
	border-radius: 0;
	box-shadow: none;
	list-style: none;
	margin-bottom: 0;
}

.grid-1 .catname span {
	background-color: #f44a56;
	color: #fff;
	display: block;
	font-size: 11px;
	padding: 5px;
	text-align: center;
	text-transform: uppercase;
}

.grid-1 .desc h5 {
	color: #242424;
	font-size: 20px;
	font-weight: 500;
	margin-top: 0;
	font-style: normal;
}

.grid-1 .desc h5 a {
	color: #242424;
}

.grid-1 .desc h5 a:hover {
	color: #f44a56;
}

.grid-1 .desc p {
	color: #444;
	margin-bottom: 0;
	font-size: 14px;
	line-height: 2.2em;
	font-style: normal;
	text-transform: none;
	padding-top: 10px;
}

.thumb {
	transition: all 0.6s ease-in-out 0s;
}

.thumb {
	background: #fff none repeat scroll 0 0;
	transition: all 0.6s ease-in-out 0s;
}

.thumb:hover img {
	/*opacity: 0.7;*/
	transform: scale(1.1);
}

.thumb img,
.gallery-hover,
.gallery-grid img,
.news-grid-style-1 .news-post-excerpt h3 a,
.catname-btn,
.news-post-excerpt img,
.footer-social li a,
.thumb,
.pro-content,
.product-grid ul.grid > li,
.cart-button,
.post-content h3 a,
.news-grid-style-3 .grid-holder .thumb {
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease- 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
	overflow: hidden;
}

.news-list h3 {
	font:
		500 20px/20px "Roboto",
		sans-serif;
	margin: 16px 0 9px;
	text-transform: uppercase;
}

.news-list p {
	color: #444;
	font-size: 14px;
	margin: 5px 0 0;
}

.news-list.owl-theme .owl-nav {
	position: absolute;
	top: 35%;
	width: 100%;
	z-index: 99;
}

.content-slider-2 {
	position: relative;
}

.content-slider-2 .grid-1 {
	margin-bottom: 0;
}

/*GRID STYLE 2*/

.grid-1.gride-style-2 {
	border: none;
	margin-bottom: 30px;
}

.grid-1.gride-style-2 .detail {
	padding: 20px;
	background-color: #f9f9f9;
}

/*SMALL GRID CSS */

.small-grid-container {
	position: relative;
	overflow: hidden;
}

.small-grid,
.small-post {
	padding: 0px;
	margin-bottom: 0;
}

.small-grid li {
	list-style: outside none none;
}

.small-grid li:last-child .small-post {
	margin-bottom: 0;
}

.small-grid .small-post {
	margin-bottom: 15px;
	position: relative;
	overflow: hidden;
	padding-bottom: 15px;
	border-bottom: 1px solid #f2f2f2;
}

.small-grid .small-thumb {
	float: left;
	/* margin-right: 15px; */
	overflow: hidden;
	width: 34%;
}

.small-thumb img {
	height: auto;
	width: 100%;
}

.post-content .post-tools {
	color: #fff;
	font-size: 14px;
	letter-spacing: 1px;
	margin-right: 0;
	padding-left: 0;
	text-transform: capitalize;
	overflow: hidden;
}

.post-content .post-tools li {
	color: #777;
	float: left;
	font-size: 12px;
	margin-left: 20px;
}

.post-tools li {
	color: #444;
	float: left;
	font-size: 12px;
	text-transform: uppercase;
}
.post-tools li a {
	color: #777;
}
.post-tools li::after {
	color: #777;
	content: "/";
	padding: 0 10px;
}

.post-tools li:last-child::after {
	display: none;
}

.post-tools li i {
	margin-right: 5px;
}

.post-tools li strong {
	color: #777;
}

.caption .post-tools {
	color: #fff;
	font-size: 14px;
	text-transform: capitalize;
	list-style: none;
	position: relative;
	overflow: hidden;
}

.latest-news-grid {
	position: relative;
	overflow: hidden;
	margin-bottom: 20px;
	margin-left: -16px;
}

.latest-news-grid .detail {
	padding: 20px 0;
}

.latest-news-grid .post-tools {
	color: #fff;
	font-size: 14px;
	text-transform: capitalize;
}

.post-tools li:hover {
	color: #f44a56;
}

.small-post .post-content h3 a {
	color: #242424;
	font-size: 16px;
	text-decoration: none;
	font-style: normal;
	text-transform: capitalize;
	font-weight: 500;
}

.small-post .post-content h3 a:hover {
	color: #f44a56 !important;
}

.news-list {
	border-radius: 2px;
	margin-bottom: 30px;
	overflow: hidden;
}

.news-list {
	clear: both;
	float: left;
	width: 100%;
}

.news-list .post-content {
	padding: 24px 30px 30px 15px;
}

.news-list h3 {
	font:
		500 18px/42px "Roboto",
		sans-serif;
	margin: 0;
	padding: 0;
	text-transform: uppercase;
}

#sidebar .headings {
	margin-left: 0px;
}
#sidebar aside {
	margin-left: -2%;
}

#sidebar iframe {
	width: 100%;
	border: 0px;
	min-height: 250px;
	border-radius: 3px;
}

.social-icons {
	font-size: 22px;
	margin: 0px;
	overflow: auto;
	text-align: center;
}

.social-icons .social-container {
	overflow: hidden;
	position: relative;
}

.social-icons .social-grid {
	float: left;
	width: 50%;
}

.social-icons:hover .icons {
	color: #fff;
}

.social-icons .icons {
	display: block;
	padding: 10px 0;
}

.authors ul li {
	display: inline-block;
	/* float: left; */
	margin: 0;
	padding: 0 2px 2px 0;
}

/*SIDEBAR WIDGET*/

.sidebar-color {
	background-color: #f1f1f1;
}

.sidebar-color .widget {
	margin-bottom: 0;
}

.sidebar-no-color .widget {
	background-color: transparent;
	padding: 0;
}

.sidebar-no-color .widget .grid-1 .detail {
	padding: 20px 0;
}

.sidebar-no-color .widget .grid-1 .detail .caption h5 {
	font-size: 16px;
}

.widget {
	position: relative;
	overflow: hidden;
	margin-bottom: 30px;
}
#sidebar div.widget:last-child {
	margin-bottom: 0;
}
.widget-bg {
	background-color: #f9f9f9;
	padding: 20px;
}

.widget-bg-white {
	background-color: #fff;
	padding: 20px;
}

.widget .ad-div {
	margin-bottom: 15px;
}

.widget .ad-div img {
	display: inline-block;
}

.rss-widget {
	background-color: #444;
}

.rss-widget .heading .main-heading {
	background-color: #f2f2f2;
	color: #444;
}

.rss-widget .heading .heading-ping {
	border-color: transparent transparent transparent #f2f2f2;
}

.rss-widget .form-inline {
	margin-bottom: 20px;
}

.rss-widget .form-inline .form-group .form-control:focus {
	box-shadow: none;
	border-color: #f2f2f2;
}

.rss-widget .form-inline .form-control {
	width: 300px;
	border-radius: 0;
	height: 45px;
	border-color: #444;
}

.rss-widget .form-inline .btn {
	border-radius: 0;
	height: 45px;
	padding: 6px 10px;
	border-color: #444;
}

.rss-widget p {
	color: #fff;
}

.widget .grid-1 .detail {
	padding: 7px;
	background-color: #fff;
}

.widget .grid-1 {
	margin-bottom: 0;
	border: none;
}

.widget .owl-nav {
	position: absolute;
	top: -72px;
	right: 0;
	margin-top: 0;
}

.widget .owl-theme .owl-nav div.owl-prev::after,
.widget .owl-theme .owl-nav div.owl-next::after {
	font-size: 20px;
	color: #fff;
}

/* .widget .video-section-widget iframe {
	height:280px;
} */

.widget .video-section-widget iframe {
	height: 100%;
	width: 100%;
}

/*WIDGET CATEGORY*/

.cat-holder {
	float: left;
	margin: 0;
	width: 100%;
	padding: 0;
}

.cat-holder li:first-child {
	padding-top: 0;
}

.cat-holder li {
	float: left;
	list-style: outside none none;
	width: 100%;
	padding: 10px 0;
	border-bottom: 1px solid #ddd;
}

.cat-holder li:last-child {
	margin-bottom: 20px;
}

.cat-holder a {
	color: #444;
	font-size: 14px;
}

.cat-holder a:hover {
	color: #f44a56;
}

.cat-holder .count {
	color: #444;
	float: right;
	font-size: 14px;
	font-weight: 500;
	text-align: center;
	font-style: normal;
}

.cat-holder a::before {
	color: #444;
	content: "";
	font-family: FontAwesome;
	font-size: 16px;
	margin-right: 10px;
}

/*POL WIDGET*/

.pol-holder li {
	border-bottom: none;
	float: left;
	list-style: outside none none;
	padding: 10px 0;
	width: 100%;
}

.pol-holder a {
	color: #777;
	font-size: 14px;
}

.pol-holder ul {
	margin: 0;
	padding: 0;
	width: 100%;
	position: relative;
	overflow: hidden;
	padding-left: 10px;
}

.pol-holder li input {
	margin-right: 10px;
}

.widget .pol-btn {
	position: relative;
	overflow: hidden;
	margin-top: 20px;
}

.widget .pol-btn a {
	background-color: transparent;
	border: 1px solid #f44a56;
	padding: 10px 15px;
	text-transform: uppercase;
	text-align: center;
	margin-right: 10px;
	font-size: 14px;
	color: #f44a56;
}

.widget .pol-btn a:hover {
	background-color: #f44a56;
	color: #fff;
}

.widget .pol-holder p {
	font-size: 16px;
	font-weight: 500;
}

.search-widget input.form-control {
	height: 40px;
	box-shadow: none;
}

.search-widget input.form-control:focus {
	border: 1px solid #ccc;
}

.search-widget {
	position: relative;
	overflow: hidden;
}

.search-widget button {
	position: absolute;
	right: 0;
	top: 0;
	padding: 10px;
	background-color: transparent;
	box-shadow: none;
	border: none;
}

/*AUTHOR POST WIDGET*/

.author-widget {
	position: relative;
	overflow: hidden;
}

.author-widget .auth-pic {
	margin-bottom: 20px;
	padding: 0 80px;
}

.author-widget .auth-meta {
	text-align: center;
}

.author-widget .auth-meta h4 {
	margin-top: 0;
	font-size: 20px;
	font-weight: 500;
	text-transform: capitalize;
}

.author-widget .social-media-icons ul {
	padding: 0;
}

.author-widget .social-media-icons ul li i {
	color: #444;
}

/*PHOTO GALLERY SECTION*/

.photo-gallery-section {
	position: relative;
	overflow: hidden;
	padding: 0;
}

/* TABS*/

ul.tabs-posts {
	margin: 0px;
	padding: 0px;
	clear: both;
}

ul.tabs-posts > li {
	list-style-type: none;
	margin-top: 20px;
	overflow: hidden;
	position: relative;
}

.tabs-posts .post-tools li {
	border: none;
}

.tabs-posts .post-tools {
	margin-top: 10px;
}

.nav-tabs.nav-justified > li > a {
	border-bottom: none !important;
	border-radius: 0px !important;
	color: #fff;
	background-color: #444;
	font-size: 16px;
	font-weight: 500;
	text-transform: capitalize;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
	border-radius: 0px;
	border-color: #f44a56 !important;
	background-color: #f44a56;
	color: #fff;
}

#sidebar ul.nav-tabs {
	padding-left: 0px;
	border-bottom: 2px solid #f44a56;
}

ul.tabs-posts li .pic {
	float: left;
	margin-right: 10px;
}

ul.tabs-posts li .info {
	color: #aaaaaa;
	padding-top: 10px;
}

ul.tabs-posts li .info .likes {
	padding-right: 16px;
}

ul.tabs-posts li i {
	margin-right: 5px;
}

ul.tabs-posts li .caption a {
	color: #242424;
	font-size: 16px;
	text-transform: capitalize;
	font-weight: 400;
}

ul.nav-tabs.nav-justified > li {
	border-right: 1px solid #fff;
}

.nav-tabs.nav-justified > li > a:hover {
	border: 1px solid #444;
}

.tag-list {
	padding-top: 20px;
}

.tag-list a {
	border: 1px solid #f9f9f9;
	color: #444;
	background-color: #f9f9f9;
	display: inline-block;
	font-size: 14px;
	margin-bottom: 10px;
	margin-right: 3px;
	padding: 5px 15px;
	text-transform: uppercase;
	transition: all 0.3s ease 0s;
}

.tag-list a,
.tag-list a:hover,
.tag-list a:active,
.tag-list a:focus {
	text-decoration: none;
	outline: none;
}

.tag-list a:hover {
	color: #fff;
	background-color: #f44a56;
}

.tabs-posts li .caption a:hover {
	text-decoration: underline;
	color: #242424;
}

/*ADVERTISEMENT CSS  */

.style-box {
	text-align: center;
	width: 100%;
}

.ad-728x90 {
	padding-top: 14px;
	padding-bottom: 14px;
	text-align: right;
}

.ad-728x90 img {
	padding: 0px;
}

.ad-div {
	display: table;
	width: 100%;
	height: 100%;
	/* margin-bottom: 50px; */
}

.ad-div img {
	display: inline-block;
}

.section .ad-div {
	margin-bottom: 30px;
	margin-top: 10px;
}

section.ad-section {
	padding: 40px 0;
	background-color: #f2f2f2;
}

.ad-section .ad-div {
	margin-bottom: 0;
}

/*CONTACT US CSS*/

.contact-detail p {
	margin-bottom: 50px;
	line-height: 30px;
	width: 50;
}

.contact-form h3 {
	text-transform: capitalize;
	margin-top: 0;
}

.contact-form .form-group {
	margin-bottom: 20px;
}

.contact-form .form-group input {
	height: 40px;
	border-radius: 0;
	box-shadow: none;
	border: none;
	border-bottom: 1px solid #ccc;
	padding-left: 0;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease- 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.contact-form .form-group textarea {
	border-radius: 0;
	box-shadow: none;
	border: none;
	border-bottom: 1px solid #ccc;
	padding-left: 0;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease- 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.contact-form .form-group input:focus,
.contact-form .form-group textarea:focus {
	background-color: #f9f9f9;
	box-shadow: none;
	border-color: #ccc;
	padding-left: 15px;
}

.contact-detail {
	width: 35%;
	text-align: center;
	display: block;
	margin: 0 auto;
}

.contact-detail h3 {
	font-size: 20px;
	margin-top: 0;
}

.contact-detail-box .icon-box {
	background-color: #f44a56;
	float: left;
	margin-right: 10px;
	padding: 10px 12px;
	border-radius: 4px;
}

.contact-detail-box .icon-box i {
	color: #fff;
	font-size: 24px;
}

.contact-detail-box {
	margin-bottom: 20px;
	overflow: hidden;
	position: relative;
	padding: 15px;
	background-color: #f2f2f2;
}

.content-area h4 {
	font-size: 18px;
	margin: 0;
}

.content-area p {
	margin-bottom: 0;
}

/* .contact-form-style-2 {} */

.contact-form-style-2 .contact-form .form-group input {
	height: 40px;
	border-radius: 4px;
	background-color: #f2f2f2;
	padding-left: 15px;
	border: none;
}

.contact-form-style-2 .contact-form .form-group textarea {
	border-radius: 4px;
	background-color: #f2f2f2;
	padding-left: 15px;
	border: none;
}

.contact-form-style-2 .contact-detail-box {
	border-radius: 4px;
}

/* FOOTER CSS*/

footer {
	position: relative;
	width: 100%;
	overflow: hidden;
}

footer h2 {
	text-transform: capitalize;
	color: #fff;
	font-size: 20px;
	margin-top: 0;
	margin-bottom: 20px;
}

.bg-dark .post-tools li::after {
	color: #fff;
	content: "/";
	padding: 0 10px;
}
.bg-dark .post-tools li a {
	color: #fff;
}
.bg-dark .post-tools li strong {
	color: #fff;
}
.footer-top {
	padding: 15px 0;
}

.footer-link ul {
	position: relative;
	float: left;
	margin: 0;
	padding-left: 0;
}

.footer-link ul li {
	position: relative;
	float: left;
	font-size: 13px;
	font-weight: 400;
	color: #fff;
	margin-right: 17px;
	letter-spacing: 1px;
}

.copyright {
	position: relative;
	float: right;
	padding-top: 3px;
}

.copyright span {
	color: #242424;
	font-size: 12px;
	text-transform: capitalize;
}

footer p,
footer a {
	color: #fff;
}

footer p {
	display: inline-block;
	position: relative;
	overflow: hidden;
}

footer .footer-block p {
	margin-top: 20px;
}
footer .footer-block ul {
	position: relative;
	padding-left: 12px;
	padding: 0;
}

footer .footer-block a {
	width: 100%;
	display: block;
	padding: 6px 0;
	text-transform: capitalize;
}

footer .footer-block a span {
	float: right;
}

.footer-link.bg-white,
footer.bg-white {
	border-top: 1px solid #f6f6f6;
	/* padding: 15px 0px; */
}

.footer-link.bg-white ul li a {
	color: #242424;
	font-weight: 400;
}

.footer-link.bg-white ul li a:hover {
	color: #f44a56;
}

.footer-top ul.tabs-posts li .caption a,
.footer-top .post-tools li {
	color: #fff;
}

.footer-top ul.tabs-posts li .pic a img {
	width: 75px;
}

.footer-block {
	position: relative;
}

footer .personal-info li {
	color: #f9f9f9;
	display: inline-block;
	font-size: 16px;
	line-height: 24px;
	padding-bottom: 10px;
	width: 100%;
}

footer .personal-info li i {
	color: #fff;
	float: left;
	font-size: 18px;
	line-height: 24px;
	margin-right: 10px;
}

.my-pined-post {
	position: relative;
	overflow: hidden;
}

.my-pined-post .thumb {
	float: none;
}

.my-pined-post-meta {
	position: relative;
	overflow: hidden;
}

footer .my-pined-post a {
	padding: 0;
}

footer {
	background-attachment: scroll;
	background-image: url("../images/footer-bg-2.png");
	background-position: center bottom;
	padding-bottom: 0;
	position: relative;
}

.footer-white h2,
.footer-white p,
.footer-white a,
.footer-white .personal-info li,
.footer-white .personal-info li i,
.footer-white ul.tabs-posts li .caption a,
.footer-white .post-tools li,
.footer-white .social-media-icons ul li i {
	color: #242424;
}

.footer-white .personal-info li,
.footer-white ul.tabs-posts li .caption a {
	font-size: 14px;
}

.footer-white ul.post-tools li {
	margin-bottom: 0;
}

.footer-2 {
	background-color: #242424;
}

.footer-2 .footer-block {
	display: block;
	margin: 0 auto;
	text-align: center;
}

.footer-2 .footer-top {
	padding: 30px 0;
}

.footer-footer-area {
	border-top: 1px solid #999;
	margin-top: 20px;
	overflow: hidden;
	padding-top: 20px;
	position: relative;
}

.footer-footer-area ul {
	list-style: outside none none;
	margin: 0;
	padding-left: 0;
	position: relative;
	float: right;
}

.footer-footer-area ul li {
	color: #fff;
	float: left;
	font-weight: 400;
	letter-spacing: 1px;
	margin-right: 0;
	margin-left: 15px;
	position: relative;
}

.footer-footer-area .copyright {
	float: left;
	padding: 0;
	position: relative;
}

.footer-footer-area .copyright a {
	font-size: 16px;
	font-weight: 500;
	color: #f44e56;
	margin-left: 5px;
}

.footer-footer-area .copyright span {
	font-size: 14px;
	color: #fff;
}

.footer-footer-area p,
.footer-footer-area a {
	color: #fff;
}

/*INSTAGRAM GALLERY*/

.insta-gallery a {
	width: 80px;
	/* border: 1px solid red; */
	/* display: inline-block; */
	margin-right: 5px;
	margin-bottom: 5px;
}

.insta-gallery {
	/* border: 1px solid red; */
	place-content: center;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

footer .footer-top ul.twitter-widget .caption a.url {
	display: block;
	font-weight: 700;
	color: #f44a56;
	margin-top: 5px;
	text-transform: none;
}

/*LOAD MORE POSTS*/

#loadMore {
	margin: 30px 0;
}

div.post {
	display: none;
}

#fluid-posts .ad-div {
	margin: 20px 0;
}

.zerogrid-section .grid-item .ad-div img {
	height: 450px;
}

/*FOOTER TAGS*/

.bg-dark .tag-list a {
	background-color: rgba(255, 255, 255, 0.1);
	border: none;
	border-radius: 4px;
	color: #fff;
	font-size: 12px;
}

footer .tag-list a {
	border: none;
	border-radius: 4px;
	color: #444;
	font-size: 12px;
}

footer .tag-list {
	padding: 0;
}

.footer-detail ul {
	padding: 0;
	list-style: none;
}

/* SOCIAL COUNTERS*/

.social-icons .facebook .icons {
	color: #3b5998;
}

.social-icons .googleplus .icons {
	color: #dd4b39;
}

.social-icons .twitter .icons {
	color: #2aa9e0;
}

.social-icons .instagram .icons {
	color: #685243;
}

.social-icons .pinterest .icons {
	color: #cc2028;
}

.social-icons .youtube .icons {
	color: #de1829;
}

.social-icons .vine .icons {
	color: #35b57c;
}

.social-icons .soundcloud .icons {
	color: #ff4100;
}

.social-icons .vk .icons {
	color: #45668e;
}

.social-icons .foursquare .icons {
	color: #f94877;
}

.social-icons .github .icons {
	color: #333333;
}

.social-icons .dribbble .icons {
	color: #ea4c89;
}

ul.social-counter-list li.colored-social i {
	float: left;
	font-size: 20px;
	height: 45px;
	line-height: 45px;
	margin-right: 10px;
	text-align: center;
	vertical-align: middle;
	width: 45px;
	padding: 0;
	color: #fff;
}

ul.social-counter-list li.colored-social .sc-num {
	margin: 0;
}

.colored-social .fa-dribbble,
.social-grid-icons .fa-dribbble:hover,
.social-icons .dribbble:hover .icons {
	background-color: #f46899;
}

.colored-social .fa-vimeo,
.social-grid-icons .fa-vimeo:hover,
.social-icons .vimeo:hover .icons {
	background-color: #00b3ec;
}

.colored-social .fa-stumbleupon,
.social-grid-icons .fa-stumbleupon:hover {
	background-color: #eb4924;
}

.colored-social .fa-reddit,
.social-grid-icons .fa-reddit:hover {
	background-color: #5f99cf;
}

.colored-social .fa-facebook,
.social-grid-icons .fa-facebook:hover,
.social-icons .facebook:hover .icons {
	background-color: #3c599f;
}

.colored-social .fa-rss,
.social-grid-icons .fa-rss:hover {
	background-color: #f26522;
}

.colored-social .fa-lastfm,
.social-grid-icons .fa-lastfm:hover {
	background-color: #d51007;
}

.colored-social .fa-flickr,
.social-grid-icons .fa-flickr:hover {
	background-color: #ff0084;
}

.colored-social .fa-instagram,
.social-grid-icons .fa-instagram:hover,
.social-icons .instagram:hover .icons {
	background-color: #685243;
}

.colored-social .fa-foursquare,
.social-grid-icons .fa-foursquare:hover,
.social-icons .foursquare:hover .icons {
	background-color: #0086be;
}

.colored-social .fa-github,
.social-grid-icons .fa-github:hover,
.social-icons .github:hover .icons {
	background-color: #070709;
}

.colored-social .fa-google-plus,
.social-grid-icons .fa-google-plus:hover,
.social-icons .googleplus:hover .icons {
	background-color: #cf3d2e;
}

.colored-social .fa-instagram,
.social-grid-icons .fa-instagram:hover {
	background-color: #a1755c;
}

.colored-social .fa-linkedin,
.social-grid-icons .fa-linkedin:hover {
	background-color: #0085ae;
}

.colored-social .fa-pinterest,
.social-grid-icons .fa-pinterest:hover,
.social-icons .pinterest:hover .icons {
	background-color: #cc2127;
}

.colored-social .fa-twitter,
.social-grid-icons .fa-twitter:hover,
.social-icons .twitter:hover .icons {
	background-color: #32ccfe;
}

.colored-social .fa-vk,
.social-grid-icons .fa-vk:hover,
.social-icons .vk:hover .icons {
	background-color: #375474;
}

.colored-social .fa-soundcloud,
.social-grid-icons .fa-soundcloud:hover,
.social-icons .soundcloud:hover .icons {
	background-color: #ff4100;
}

.colored-social .fa-vine,
.social-grid-icons .fa-vine:hover,
.social-icons .vine:hover .icons {
	background-color: #35b57c;
}

.colored-social .fa-xing,
.social-grid-icons .fa-xing:hover {
	background-color: #00555c;
}

.colored-social .fa-youtube,
.social-grid-icons .fa-youtube:hover,
.social-icons .youtube:hover .icons {
	background-color: #c52f30;
}

.social-counter-widget li.social-counter-twitter i {
	border-color: #00baff;
	color: #00baff;
}

.social-counter-widget li.social-counter-facebook i {
	border-color: #44619d;
	color: #44619d;
}

.social-counter-widget li.social-counter-gplus i {
	border-color: #e71414;
	color: #e71414;
}

.social-counter-widget li.social-counter-youtube i {
	border-color: #c30000;
	color: #c30000;
}

.social-counter-widget li.social-counter-vimo i {
	border-color: #44bbff;
	color: #44bbff;
}

.social-counter-widget li.social-counter-soundcloud i {
	border-color: #ff681e;
	color: #ff681e;
}

.social-counter-widget li.social-counter-instgram i {
	border-color: #0b79e5;
	color: #0b79e5;
}

.social-counter-widget li.social-counter-dribbble i {
	border-color: #d875a2;
	color: #d875a2;
}

.social-counter-widget li.social-counter-delicious i {
	border-color: #2567ee;
	color: #2567ee;
}

ul.social-counter-list {
	padding-left: 0;
	list-style: none;
}

ul.social-counter-list li {
	margin-bottom: 10px;
	width: 50%;
	float: left;
}

ul.social-counter-list li a {
	position: relative;
	overflow: hidden;
}

ul.social-counter-list li i {
	font-size: 20px;
	padding: 15px;
	background-color: #fff;
	margin-right: 20px;
	float: left;
}

ul.social-counter-list .sc-num {
	display: block;
	font-size: 20px;
	margin: 5px 0;
}

.social-ping {
	border-color: transparent transparent transparent #fff;
	border-style: solid;
	border-width: 10px 0 10px 10px;
	height: 0;
	position: absolute;
	top: 28px;
	width: 0;
	left: -20px;
}

.social-counter-list-2 {
	position: relative;
	overflow: hidden;
	padding: 0;
	list-style: none;
	margin-bottom: 0;
}

.social-counter-list-2 li.social-item {
	display: block;
	position: relative;
	overflow: hidden;
	margin-bottom: 20px;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease- 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
.social-counter-list-2 li.social-item:last-child {
	margin-bottom: 0;
}

.social-counter-list-2 li.social-item a:hover .social-item-meta {
	background-color: #242424;
	color: #fff;
}

.social-counter-list-2 li.social-item .social-item-icon.twitter {
	background-color: #00baff;
}

.social-counter-list-2 li.social-item .social-item-icon.facebook {
	background-color: #44619d;
}

.social-counter-list-2 li.social-item .social-item-icon.gplus {
	background-color: #e71414;
}

.social-counter-list-2 li.social-item .social-item-icon.youtube {
	background-color: #c30000;
}

.social-counter-list-2 li.social-item .social-item-icon.vimo {
	background-color: #44bbff;
}

.social-counter-list-2 li.social-item .social-item-icon.soundcloud {
	background-color: #ff681e;
}

.social-counter-list-2 li.social-item .social-item-icon.instgram {
	background-color: #0b79e5;
}

.social-counter-list-2 li.social-item .social-item-icon.dribbble {
	background-color: #d875a2;
}

.social-counter-list-2 li.social-item .social-item-icon.delicious {
	background-color: #2567ee;
}

.social-counter-list-2 li.social-item .social-item-icon.linkedin {
	background-color: #0085ae;
}

.social-counter-list-2 li.social-item .social-item-icon {
	border-radius: 4px 4px 0 0;
	color: #fff;
	text-align: center;
	padding: 10px 0;
}

.social-counter-list-2 li.social-item .social-item-icon i {
	font-size: 20px;
}

.social-counter-list-2 li.social-item .social-item-meta {
	padding: 10px 0;
	text-align: center;
	background-color: #f2f2f2;
	border-radius: 0 0 4px 4px;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease- 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.social-counter-list-2 li.social-item .social-item-num {
	font-size: 20px;
	font-weight: 500;
}

.social-style-3 li.social-item .social-item-meta {
	background-color: #242424;
	border-radius: 0 4px 4px 0;
	float: right;
	padding: 10px 20px;
	text-align: center;
	transition: all 0.3s ease 0s;
}

.social-style-3 li.social-item .social-item-icon i {
	float: left;
	font-size: 20px;
	line-height: 25px;
	padding: 10px;
}

.social-style-3 li.social-item .social-item-icon {
	color: #fff;
	padding: 10px 0;
	text-align: left;
	position: relative;
	overflow: hidden;
	padding: 0;
	border-radius: 4px;
}

.social-style-3 li.social-item .social-item-icon span.title {
	display: inline-block;
	font-size: 16px;
	overflow: hidden;
	line-height: 45px;
	position: relative;
	vertical-align: middle;
}

.social-style-3 li.social-item .social-item-num {
	font-size: 20px;
	font-weight: 400;
}

/*SOCIAL MEDIA ICONS*/

.social-media-icons {
	position: relative;
	margin: 10px 0;
}

footer .social-media-icons ul {
	position: relative;
	padding-left: 0;
	list-style: none;
	margin-bottom: 0;
}

.social-media-icons ul li {
	display: inline-block;
}

.social-media-icons ul li i {
	font-size: 16px;
	color: #777;
	padding: 8px;
	/* background-color: black; */
	display: block;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease- 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.social-media-icons ul li:hover i {
	color: #f44a56;
	border-color: #f44a56;
}

.social-media-icons ul li a {
	padding: 0;
}

/* MAIN TABS CSS */

.section .tabs .nav-tabs.nav-justified {
	margin-bottom: 30px;
	border-bottom: 3px solid #f44a56;
}

.section .nav-tabs.nav-justified > li > a {
	text-transform: capitalize;
	font-weight: 500;
}

.section ul.nav-tabs.nav-justified > li {
	border: none;
}

/*TOP SMALL POSTS SLIDER*/

.top-small-post-slider {
	background:
		rgba(0, 0, 0, 0),
		url("../images/fullslide7.jpg") no-repeat scroll center center / cover;
	padding: 40px 0;
	position: relative;
}
.top-small-post-slider::after {
	background: rgba(247, 247, 247, 0.9);
	content: "\a ";
	height: 100%;
	left: 0;
	opacity: 1;
	position: absolute;
	top: 0;
	transition: all 0.5s ease 0s;
	width: 100%;
}
.top-small-post-slider .small-post {
	overflow: hidden;
	position: relative;
	background-color: #f9f9f9;
	margin: 0 15px;
	border-radius: 5px;
	border: 1px solid #f2f2f2;
}

.top-small-post-slider .small-thumb {
	float: left;
	overflow: hidden;
	width: 100px;
}

.top-small-post-slider .post-content {
	position: relative;
	overflow: hidden;
	padding: 10px;
}

.top-small-post-slider .post-content h3 {
	font-weight: normal;
	line-height: 20px;
	margin-bottom: 10px;
	margin-top: 0;
	padding: 0;
}

.top-small-post-slider .post-content h3 a {
	color: #242424;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	text-decoration: none;
	text-transform: capitalize;
}

.top-small-post-slider .post-content .post-tools {
	margin-bottom: 0;
}

.top-small-post-slider .post-content .post-tools li,
.top-small-post-slider .post-content .post-tools li a {
	color: #444;
}

.top-small-post-slider .post-content .post-tools li:hover,
.top-small-post-slider .post-content .post-tools li a:hover {
	color: #f44a56;
}

.top-small-post-slider .post-content .post-tools li::after {
	color: #777;
	content: "/";
	padding: 0 10px;
}

.top-small-post-slider .owl-nav {
	margin: 0;
}

.top-small-post-slider .owl-theme .owl-nav div.owl-prev::after,
.top-small-post-slider .owl-theme .owl-nav div.owl-next::after {
	font-size: 30px;
}

.top-small-post-slider .owl-theme .owl-nav div.owl-prev {
	left: -3px;
	position: absolute;
	top: 28px;
	border-radius: 5px;
}

.top-small-post-slider .owl-theme .owl-nav div.owl-next {
	right: -3px;
	position: absolute;
	top: 32px;
	border-radius: 5px;
}
/*404 PAGE*/

.error-info {
	text-align: center;
}
.error-info img {
	margin-bottom: 30px;
}
.error-info .error-sub {
	font-size: 20px;
}
.error-info p {
	margin-top: 10px;
	margin-bottom: 20px;
}

.listing .author-box {
	margin-top: 0;
}

/* ---- COLOR SWITCHER CSS ----- */
.color-switcher {
	width: 142px;
	position: fixed;
	left: -142px;
	top: 25%;
	background: #fff;
	z-index: 9999;
	padding: 0 0 5px;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
	border: 1px solid #f1f1f1;
}
.color-switcher h5 {
	border-bottom: 1px solid #f1f1f1;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	margin: 20px 15px;
	padding: 20px 0;
	text-transform: uppercase;
	margin-top: 0;
}
.color-switcher ul {
	list-style: outside none none;
	overflow: hidden;
	padding: 0 15px;
	position: relative;
}
.color-switcher ul li {
	float: left;
	margin-right: 10px;
	margin-bottom: 10px;
}
.color-switcher ul li:nth-child(even) {
	margin-right: 0;
}
.color-switcher ul li a {
	display: block;
	width: 45px;
	height: 45px;
	outline: none;
}
.color-switcher ul li a.defualt {
	background: #f44a56;
}
.color-switcher ul li a.midnight-blue {
	background: #2c3e50;
}
.color-switcher ul li a.blue {
	background: red;
}
/* .color-switcher ul li a.blue {
	background: #2196f3;
   } */
.color-switcher ul li a.amethyst {
	background: #9b59b6;
}
.color-switcher ul li a.golden {
	background: #e4b714;
}
.color-switcher ul li a.see-green {
	background-color: #40c37d;
}
.color-switcher ul li a.carrat {
	background: #e67e22;
}
.color-switcher ul li a.java-color {
	background: #1f9ea3;
}

.picker_close {
	width: 35px;
	height: 50px;
	position: absolute;
	right: -35px;
	top: -1px;
	border-radius: 0 5px 5px 0;
	border: 1px solid #f1f1f1;
	text-align: center;
	background: no-repeat center 5px #fff;
	border-left: 0;
}
.picker_close i {
	font-size: 24px;
	margin-top: 12px;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}
.position {
	left: 0;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}
.rotate-arrow {
	-ms-transform: rotate(180deg); /* IE 9 */
	-webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
	transform: rotate(180deg);
}

/* RESPONSIVENESS QURIES */

@media (min-width: 1300px) and (max-width: 3000px) {
	.box-style .container {
		width: 1280px;
	}
}
/*
@media (min-width: 768px) and (max-width: 991px) {
}*/

@media screen and (min-width: 768px) and (max-width: 1279px) {
	/* .small-img a img{
		margin-left: 10px;
	} */
	/* .mobile-view {
		display: none;
	  } */
	.banner-view {
		display: none;
	}
	.megaMenu {
		border-radius: 0;
		background-color: #fff;
	}
	.megaMenu ul.menu-logo > li > a {
		color: #444;
	}
	.advertisement img {
		margin-top: 20px;
	}
	.logo-row .logo a {
		text-align: center;
		display: block;
	}
	.megaMenu ul.menu-logo > li > a:hover {
		color: #444;
	}
	.megaMenu ul.menu-mobile-trigger i.fa {
		border-radius: 0;
	}
	.footer-block {
		margin-bottom: 30px;
	}
	.zerogrid .col-1-4 {
		width: 100%;
	}
	.zerogrid-section .zerogrid .col-1-4 .wrap-col {
		float: left;
		width: 50%;
	}
	.zerogrid-section .zerogrid .col-2-4 {
		width: 100%;
	}
	.heading .main-heading {
		padding: 8px 12px;
		font-size: 18px;
	}
	.small-grid .small-thumb {
		margin-right: 10px;
		width: 30%;
	}
	.listing .desc p {
		display: none;
	}
	.heading .heading-ping {
		top: 8px;
	}
	.breakingNews > .bn-title > h2 {
		font-size: 18px;
		font-weight: 500;
	}
	.top-small-post-slider .small-thumb {
		margin-right: 8px;
		width: 96px;
	}
	.megaMenu ul.menu-logo > li {
		border: none;
	}
	footer h2 {
		margin-top: 20px;
	}
	.topbar {
		/* display: none; */
	}
	.zerogrid .col-1-3 {
		width: 50%;
	}
}

@media screen and (min-width: 320px) and (max-width: 767px) {
	form {
		/* border: 1px solid red; */
		width: 90%;
		margin: 5%;
		/* margin-right: 10%; */
	}
	.heading .main-heading {
		padding: 8px 12px;
		font-size: 16px;
	}
	.megaMenu {
		border-radius: 0;
		background-color: #fff;
	}
	.megaMenu ul.menu-logo > li > a {
		color: #444;
	}
	.advertisement img {
		margin-top: 20px;
	}
	.logo-row .logo a {
		text-align: center;
		display: block;
	}
	.megaMenu ul.menu-mobile-trigger i.fa {
		border-radius: 0;
	}
	.megaMenu ul.menu-logo > li > a:hover {
		color: #444;
	}
	.breakingNews {
		min-height: inherit;
		height: 40px;
	}
	.heading .main-heading {
		font-size: 18px;
	}
	.heading-read-more a {
		margin-top: 10px;
	}
	.caption h5 {
		font-size: 18px;
	}
	.small-grid .small-thumb {
		/* width: 70px; */
		width: 30%;
		margin-left: 10px;
	}
	.heading-read-more a {
		margin-top: 0;
		display: none;
	}
	.grid-1 {
		margin-bottom: 20px;
	}
	.post1 {
		/* border: 1px solid red; */
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.grid-1 .desc h5 {
		margin-top: 10px;
		font-size: 18px;
	}
	ul.tabs-posts li .pic {
		margin-right: 10px;
		width: 70px;
	}
	.widget-bg {
		padding: 20px 15px;
	}
	#sidebar ul.nav-tabs {
		border: none;
	}
	ul.social-counter-list li i {
		font-size: 16px;
		margin-right: 15px;
		padding: 15px;
	}
	.social-ping {
		top: 20px;
	}
	ul.social-counter-list .sc-num {
		font-size: 16px;
		margin: 0;
	}
	.newsletter .form-group {
		float: left;
		width: 86%;
	}
	.rss-widget .form-inline .form-control {
		width: 100%;
	}
	.widget .owl-nav {
		display: none;
	}
	.copyright {
		display: inline-block;
		float: none;
	}
	.footer-link {
		text-align: center;
	}
	.footer-link ul {
		display: table;
		margin: 0 auto;
		float: none;
	}
	.social-media-icons ul li i {
		margin-bottom: 10px;
	}
	.featured-news-slider .post-content h5 {
		font-size: 18px;
	}
	.topbar .social-media-icons-top {
		float: none;
	}
	.topbar ul li {
		margin-bottom: 10px;
	}
	.topbar .social-media-icons-top li {
		margin-bottom: 0;
		margin-left: 0;
		margin-right: 10px;
	}
	.top-small-post-slider .small-thumb {
		display: none;
	}
	.top-small-post-slider .post-content {
		padding: 15px;
	}
	.parallel-post-style .grid-box .thumb img {
		width: 100%;
	}
	footer h2 {
		margin-top: 20px;
	}
	.topbar {
		/* display: none; */
	}
	.full-width-slider .owl-nav {
		top: -68px;
	}
	.megaMenu ul.menu-logo > li > a {
		padding-top: 10px;
	}
	.megaMenu .grid-1 {
		margin-bottom: 15px;
	}
	.featured-news-slider .post-content .post-tools li::after {
		color: #fff;
		content: "/";
		padding: 0 5px;
	}
	.post-tools li::after {
		color: #444;
		content: "/";
		padding: 0 5px;
	}
	/* .ad-div {
		margin-bottom: 35px;
	} */
	.full-with-slider-section .post-content {
		left: 0;
		width: 100%;
	}
	.full-with-slider-section .post-content h5 {
		font-size: 16px;
	}
	.full-with-slider-section .owl-theme .owl-nav .owl-prev {
		top: 35%;
	}
	.full-with-slider-section .owl-theme .owl-nav .owl-next {
		top: 35%;
	}
	.bannerAd1 {
		margin-top: auto;
		margin-bottom: -10%;
	}
	/* .grid-thumb{
	} */
	.section {
		margin-left: 10%;
		margin-right: 10%;
		/* border: 1px solid red; */
	}
	.post-contents h3 {
		margin-left: 20%;
	}
	.small-thumb img {
		margin-left: 15px;
	}
	.item {
		width: -webkit-fill-available;
		margin-left: 10px;
	}
	.SliderPostWidget {
		position: relative;
		left: 19px;
	}
}
.posts-contents h3 {
	margin-left: 36%;
	/* border: 1px solid red; */
}

.grid-thumb {
	/* border: 1px solid red; */
	width: 100%;
	height: 208.5px;
	margin-bottom: 0.8%;
	margin-left: 1.2%;
}
.grids-4 {
	border: 1px solid red;
	height: 700px;
}
.bannerAd1 {
	/* border: 1px solid red; */
	width: 100%;
	height: 130px;
	padding-left: 2%;
	padding-right: 2%;
	padding-top: 1%;
	margin-bottom: 1.5%;
}

/* ==== custom style ==== */

/* ==== header style  */
.header-main {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
	padding: 10px 0px;
}

/* .time-stamp {
    display: flex;
    align-items: center;
    flex-flow: column;
    gap: 5px;
    padding: 12px 29px;
    border-radius: 87px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
} */

/* .time-stamp h3 {
    color: #4b4b4b;
    margin: 0;
    font-size: 13px;
} */

/* .time-stamp p {
    margin: 0;
    font-size: 10px;
    color: #757575;
} */

.logo-img {
	display: flex;
	gap: 10px;
	align-items: center;
}

/* .time-stamp p span {
    color: #d72516;
} */

.logo-img a {
	display: flex;
	align-items: center;
	justify-content: center;
}

.header-main div.grid-view-card {
	flex: 1 1 0;
}

.logo-img img {
	width: 280px;
	display: block;
	margin: auto;
}
/* .time-stamp {
    max-width: 250px;
} */
.logo-img h2 {
	font-size: 32px;
	text-transform: uppercase;
	font-weight: 700;
	color: #d72516;
	display: none;
}

.logo-img h2 span {
	color: #d72516;
}


.header-search {
	display: flex;
	justify-content: end;
	width: 100%;
}
.tranding {
	display: flex;
	align-items: center;
	gap: 20px;
	/* justify-content: end; */
}

.time-stamp {
	display: flex;
	align-items: center;
	gap: 10px;
}

.time-stamp h3 {
	font-size: 14px;
	font-weight: 600;
	margin: 0;
	color: #104b4c;
}

.time-stamp p {
	margin: 0;
	font-size: 9px;
	font-weight: 500;
	color: #2a2a2a;
}

.time-stamp p span {
	color: #f44336;
}

ul.social-media-icons-top a {
	display: flex;
	width: 20px;
	height: 20px;
	background: white;
	border-radius: 10px;
	align-items: center;
	justify-content: center;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

ul.subs-topbar {
	min-height: 20px;
	gap: 20px;
	justify-content: end;
}
.banner-header img {
	max-width: 680px;
	border-radius: 10px;
}

.banner-header {
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	border-radius: 10px;
	cursor: pointer;
}
/* ==== header style  */


/* ==== mid page == */
.banner-view img {
	display: block;
	margin: auto;
	box-shadow:
		rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
		rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
	border-radius: 0px 0px 10px 10px;
}
/* ==== mid page == */

/* == images-gird */
.girdImagesHome {
	position: relative;
	box-shadow:
		rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
		rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
	border-radius: 8px;
	overflow: hidden;
}

.home-image-main a {
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

.overlayImg-text {
	background: #0000006e;
	position: absolute;
	bottom: 0;
	width: 100%;
	backdrop-filter: blur(1px);
	padding: 6px 12px;
}

.overlayImg-text h4 {
	font-size: 16px;
}
.overlayImg-text h4 {
	margin-bottom: 5px;
}

.overlayImg-text p {
	margin: 0;
	font-size: 12px;
}

.overlayImg-text a {
	text-decoration: none;
	color: white;
}

.overlayImg-text a:hover {
	text-decoration: none;
	color: white;
}

.home-image-main {
	position: relative;
}

.home-image-main:before {
	content: "";
	position: absolute;
	background: #0000004f;
	top: 0;
	bottom: 0;
	width: 100%;
	pointer-events: none;
}

a.img-redirection img {
	transition: all 0.3s ease;
}

a.img-redirection img:hover {
	transform: scale(1.1);
}
/* == images-gird */

.girdImagesHome.big-right-Img img {
	object-fit: cover;
	width: 100%;
	height: 189px;
}

.girdImagesHome.big-right-Img .overlayImg-text h4 {
	font-size: 12px;
}

.girdImagesHome.big-right-Img .overlayImg-text p {
	font-size: 11px;
}

.girdImagesHome.big-right-Img .overlayImg-text {
	min-height: 62px;
}

.four-card-grid {
	display: flex;
	flex-flow: column;
	gap: 15px;
}
/* ======= grid mid */
.subData-grid-container {
	margin-top: 30px;
}
.art-heading h3 {
	font-size: 22px;
	padding: 3px 9px;
	text-transform: uppercase;
	font-family: system-ui;
	font-weight: 600;
	/* background: linear-gradient(90deg,#ff98004d 1.59%,#ffc10769 84.61%);
    color: #4a2d00; */
}
.articalContainer {
	background: linear-gradient(90deg, #ff98004d 1.59%, #ffc10769 84.61%);
	border-radius: 10px;
	background: #f6f5ef;
	box-shadow:
		rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
		rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
	overflow: hidden;
}

.artical-cards {
	height: 100%;
}

a.articalimg-redirection {
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
}

.article-desc-data {
	padding: 10px 12px;
	background: #404040;
	color: white;
}

.article-desc-data a {
	text-decoration: none;
	color: white;
}

.article-desc-data h5 {
	font-size: 14px;
	color: white;
}

.article-desc-data p {
	margin: 0;
	font-size: 11px;
}

.article-desc-data p span {
	color: #b0b0b0;
}

.article-desc-data p strong {
	color: #03a9f4;
}
.articalContainer {
	margin-top: 10px;
}
.list-aritcal-outer {
	display: flex;
	flex-flow: column;
	justify-content: space-between;
	height: 100%;
	padding: 12px 12px 12px 0px;
}

.article_desc-list {
	background: white;
	padding: 12px;
	height: 100%;
	border-bottom: 1px solid #e7e7e7;
	display: flex;
	flex-flow: column;
	justify-content: space-between;
	box-shadow:
		rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
		rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.article_desc-list h5 {
	font-size: 13px;
}

.article_desc-list p {
	margin: 0;
	color: #4e4e4e;
	font-weight: 500;
	font-size: 10px;
}

.article_desc-list a {
	color: #e91e63;
}

.article_desc-list:last-child {
	border: none;
	border-bottom-right-radius: 10px;
}

.article_desc-list:first-child {
	border-top-right-radius: 10px;
}
.aric-img-data-grid {
	background: #f3faff;
	box-shadow:
		rgba(9, 30, 66, 0.25) 0px 1px 1px,
		rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
	height: 100%;
	border-radius: 4px;
	display: flex;
	flex-flow: column;
}

.artic-smallimg a {
	display: flex;
	align-items: center;
	justify-content: center;
}

.artic-smallimg img {
	height: 250px;
	width: 100%;
	object-fit: cover;
}

.article-desc-small a {
	color: #000000;
	text-transform: none;
	text-decoration: none;
	display: flex;
	align-items: center;
	justify-content: center;
}

.article-desc-small h5 {
	margin: 0;
	font-size: 14px;
	text-align: center;
}

.article-desc-small {
	min-height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 12px;
}

.topArticals {
	margin-top: 30px;
	margin-bottom: 30px;
}

.multi-artic h4 {
	font-size: 22px;
	padding: 3px 9px;
	text-transform: uppercase;
	font-weight: 400;
}
/* ======= grid mid */

/* footer ===== */
.footer-white {
	background: linear-gradient(90deg, #c9231657 1.59%, #ffc10769 84.61%);
	padding: 14px 0;
}

.caption1 a {
	text-decoration: none;
}
/* footer ===== */
/* artical ===== */
d {
}

.lft-rght-grid {
	display: flex;
	align-items: center;
	gap: 10px;
	border-radius: 5px;
	box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
	background: #a2a2a2;
	position: relative;
	max-height: 240px;
	overflow: hidden;
	cursor: pointer;
}

.grid-left-imgico a {
	display: flex;
	align-items: center;
	justify-content: center;
}

.grid-left-imgico a img {
}

.grid-right-txt h5 a {
	text-decoration: none;
	font-size: 16px;
	color: white;
}

.grid-right-txt h5 {
	margin-bottom: 10px;
	color: white;
	padding: 12px;
}

.grid-right-txt p a {
	color: #00bcd4;
	font-weight: 500;
}

.grid-right-txt p {
	margin: 0;
	font-size: 14px;
	color: white;
	padding: 0 10px;
}

.grid-right-txt {
	position: absolute;
	background: #19181896;
	bottom: 0;
	backdrop-filter: blur(1px);
	width: 100%;
	height: 0px;
	transition: 0.2s all ease;
	padding: 0;
	color: white;
}

.lft-rght-grid:hover .grid-right-txt {
	height: 100%;
}

.lft-rght-grid {
}
/* artical ===== */

.swiper.swiper-initialized.swiper-horizontal.swiper-android.mySwiper.swiper-backface-hidden {
	margin-top: 20px;
	margin-bottom: 20px;
}

img.card-img-top {
	object-fit: inherit;
}

/* ==== custom style ==== */

.right-gird-home-widget {
	display: flex;
	flex-flow: column;
	gap: 15px;
}
section.page-mid-section {
	padding: 0px;
}
body {
	background: #ffffff !important;
}
nav {
	background: white;
}
.popular-heading {
	padding: 12px;
	text-align: center;
	background: white;
	margin-bottom: 15px;
}

.popular-heading h5 {
	font-size: 18px;
	margin-bottom: 0;
	font-weight: 600;
}
.grid-popular {
	background: white;
	margin-bottom: 15px;
	box-shadow:
		rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
		rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
	border-radius: 5px;
	background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	-webkit-animation: gradient 15s ease infinite;
	animation: gradient 15s ease infinite;
	overflow: hidden;
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.pplur-desc h5 a {
	color: white;
	font-size: 14px;
	line-height: 22px;
}

.pplur-desc {
	padding: 12px 12px 0px 0px;
}

.pplur-desc h5 {
	font-size: 12px;
	color: #dfdfdf;
}

.poplrImg a {
	display: flex;
	align-items: center;
	justify-content: center;
	max-height: 100px;
	overflow: hidden;
}

section.mid-artcl {
	padding: 50px 0px 30px 0px;
}

.min-tranding-article h3 {
	font-size: 32px;
	text-align: center;
}

.min-tranding-article {
	margin-bottom: 30px;
}

.mid-trnd-grid {
	margin-bottom: 27px;
	border: 1px solid #dcd7d7;
	background: white;
}

.article-desc.for-tnd-se {
	padding: 10px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.article-desc.for-tnd-se h5 a {
	font-size: 18px;
	font-weight: 500;
	line-height: 27px;
}

.article-desc.mid-tranding-text {
	padding: 12px 0px;
}

.midAddImg a img {
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	border-radius: 10px;
	cursor: pointer;
	margin: auto;
}

.midAddImg a {
	display: block;
	margin-bottom: 30px;
}

.editor-chs {
	margin-top: 20px;
}

.title-articleCenter {
	text-align: center;
	padding: 6px 5px;

	margin-bottom: 30px;
}

.title-articleCenter h3 {
	text-transform: uppercase;
	font-size: 28px;
	margin: 0;
}

.card-custom-ui {
	border-radius: 10px;
	background: #f6f5ef;
	box-shadow:
		rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
		rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
	height: 100%;
	display: flex;
	flex-flow: column;
	gap: 10px;
	padding: 8px;
	justify-content: space-between;
}

.img-custom-ui {
	height: 220px;
	overflow: hidden;
}

.img-custom-ui img {
	border-radius: 9px 9px 0px;
}
.allTimeBest {
	margin: 30px 0px;
}

.allTimeBest .card-custom-ui {
	background: #fcffe3;
}

.img-clt-icon img {
	border-radius: 4px;
    height: 150px;
    object-fit: cover;
    width: 100%;
}

.img-clt-icon {
	background: #defdba;
	padding: 5px;
	border-radius: 10px;
}

.article-desc-custom-ui a {
	color: #242424;
}

p.size-custom-ui span {
	color: #757575;
	font-weight: 500;
}

section.page-mid-section {
	padding-bottom: 50px;
}

/* ==== new footer */
.social-media-icons ul li a {
	text-decoration: none;
}

.footer-link a {
	font-size: 12px;
	text-decoration: none;
	font-weight: 500;
	color: #383838;
}

.footer-link ul {
	list-style: none;
}

.footer-top h3 {
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 15px;
}

.footer-block p {
	font-size: 13px;
	text-align: left;
}

.footer-detail {
	margin-top: 20px;
	border: 1px solid;
	border-left: none;
	border-right: none;
	padding: 10px 0px;
}

ul.personal-info {
	margin: 0;
	display: flex;
	flex-flow: column;
	gap: 10px;
}

.footer-white .personal-info li,
.footer-white ul.tabs-posts li .caption a {
	color: #383838;
	font-size: 13px;
	display: flex;
	align-items: center;
	gap: 10px;
}

.footer-white .personal-info li i {
	width: 13px;
	height: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ff5722;
	color: white;
	padding: 10px;
	border-radius: 10px;
	font-size: 10px;
}

.caption1 a {
	font-size: 12px;
	font-weight: 500;
}

ul.tabs-posts > li {
	margin-top: 0;
}

.insta-gallery a {
	height: 200px;
	width: 200px;
	display: flex;
	flex-shrink: 0;
	margin: 0;
}

.insta-gallery {
	display: flex;
	overflow: auto;
	align-items: self-start;
	justify-content: start;
	border-radius: 10px;
	gap: 10px;
	flex-wrap: unset;
	flex-direction: unset;
}

.insta-gallery img {
	max-width: 100%;
	height: auto;
	object-fit: cover;
}
.footer-white ul.post-tools li {
	color: #403f3f;
	font-weight: 500;
}
.youtubeshort-container {
	padding: 30px 0px;
}

.sortsHeading h4 {
	display: flex;
	align-items: center;
	font-size: 22px;
	font-weight: 800;
}

.short-cardslider {
	display: flex;
	gap: 10px;
	overflow: auto;
	margin-top: 20px;
	padding: 8px 0px;
}

.short-slide-card {
	border-radius: 10px;
	overflow: hidden;
	flex-shrink: 0;
	display: flex;
	box-shadow:
		rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
		rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.short-slide-card iframe {
}
/* ==== new footer */
/* ==== Inner Pages */
.girdImagesHome.big-right-Img.innerpageHeightImg img {
	height: 280px;
}
section.inner-pages-section {
	padding: 30px 0px;
}
.innerPage-side-bar {
	display: flex;
	flex-flow: column;
	gap: 15px;
}
/* ==== Inner Pages */
.picture {
}

.picture img {
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
	border-radius: 10px;
}

.catname {
	margin-top: 20px;
}
.blog-dtsl {
	background: white;
	padding: 12px;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
	border-radius: 10px;
}
.catname p {
	font-size: 13px;
	font-weight: 400;
	font-family: inherit !important;
}
/* ======= marquee  ======= */
.marquee {
	height: 40px;
	overflow: hidden;
	background: linear-gradient(90deg, #ff9800 1.59%, #ffc107 84.61%);
	color: #333;
	border: none;
	position: fixed;
	bottom: 10px;
	right: 0;
	left: 0;
	display: flex;
	align-items: center;
	box-shadow:
		inset 0 -15px 30px rgb(0 0 0 / 21%),
		0 5px 10px rgb(0 0 0 / 29%);
	z-index: 1010;
}

.marquee p {
	width: 100%;
	margin: 0;
	text-align: center;
	transform: translateX(100%);
	animation: scroll-left 20s linear infinite;
	flex-shrink: 0;
	color: #66380a;
	font-size: 20px;
	font-weight: 500;
}

@-moz-keyframes scroll-left {
	0% {
		-moz-transform: translateX(100%);
	}
	100% {
		-moz-transform: translateX(-100%);
	}
}

@-webkit-keyframes scroll-left {
	0% {
		-webkit-transform: translateX(100%);
	}
	100% {
		-webkit-transform: translateX(-100%);
	}
}

@keyframes scroll-left {
	0% {
		-moz-transform: translateX(100%);
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
	}
	100% {
		-moz-transform: translateX(-100%);
		-webkit-transform: translateX(-100%);
		transform: translateX(-100%);
	}
}
/* ======= marquee  ======= */
@media (max-width: 767px) {
	.allTimeBest {
		margin: 30px 0px;
	}

	.allTimeBest .card-custom-ui {
		background: #fcffe3;
	}

	.img-clt-icon img {
		border-radius: 4px;
	}

	.img-clt-icon {
		background: #defdba;
		padding: 5px;
		border-radius: 10px;
	}

	.article-desc-custom-ui a {
		color: #03a9f4;
	}

	p.size-custom-ui span {
		color: #757575;
		font-weight: 500;
	}

	section.page-mid-section {
		padding-bottom: 50px;
	}

	.banner-header {
		/* display: none; */
	}

	.header-main {
		justify-content: center;
		align-items: center;
	}

	.grid-view-card {
		align-items: center;
		justify-content: center;
		display: flex;
	}

	.logo-img img {
		width: 180px;
	}

	.logo-img h2 {
		font-size: 22px;
		margin: 0;
	}
	.article-desc-custom-ui {
		margin-top: 10px;
	}
	.hash-tagName {
		overflow: auto;
    justify-content: unset;
    flex-flow: wrap;
    justify-content: center;
    gap: 5px;
    padding: 0px !important;
    border-bottom: none !important;
	}
	.badgesContainer {
		background: linear-gradient(90deg, #ff98004d 1.59%, #ffc10769 84.61%);
	}
	
	.midAddImg.my-xxl-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}

	a.my-xxl-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.popular-side-cont {
		padding: 30px 0px;
	}
	.footer-block img {
		max-width: 250px;
		display: block;
	}
	.pplur-desc {
		padding: 12px;
	}
	.marquee p {
		font-size: 13px;
		flex-shrink: 0;
	}
	.time-stamp {
		display: none;
	}
	
	ul.subs-topbar {
		gap: 8px;
	}
	
	li.subscribeBtn {
		margin: 0 !important;
	}
	
	.topbar ul li {
		margin: 0px;
	}
	
	.topbar ul li a {
		font-size: 10px;
	}
	
	.topbar .social-media-icons-top li {
		margin-right: 4px;
	}
	.header-main {
		flex-flow: wrap;
	}
	
	.banner-header img {
		max-width: unset;
		width: 100%;
	}
}
