.footerInner {
    background-color: #1e1e1e;
    color: #ffffff;
    padding: 40px 20px;
    text-align: center;
    padding: 0;
    padding: 30px;
}

.footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-logo img {
    width: 180px;
    margin-bottom: 15px;
}

.footer-description {
    max-width: 600px;
    font-size: 14px;
    margin-bottom: 20px;
    line-height: 1.6;
}

.footer-links {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
}

.footer-links a {
    text-decoration: none;
    color: white;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.footer-links a i {
    font-size: 18px;
}

.footer-categories {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    margin-bottom: 20px;
}

.footer-categories a {
    text-decoration: none;
    color: white;
    font-size: 14px;
    font-weight: 600;
}

.footer-divider {
    width: 80%;
    border: 0.5px solid #555;
    margin: 20px auto;
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 900px;
    margin: 0 auto;
    flex-wrap: wrap;
}

.footer-contact p {
    font-size: 13px;
    color: #b3b3b3;
    margin: 5px 0;
}

.footer-contact span {
    color: #ffffff;
}

.footer-social {
    display: flex;
    gap: 15px;
}

.footer-social a {
    color: white;
    font-size: 18px;
    text-decoration: none;
}

.footer-copyright {
    font-size: 12px;
    color: #b3b3b3;
    margin-top: 20px;
}
section.footer-section {
    padding: 0;
}

footer.footerInner {
    padding-bottom: 50px;
}