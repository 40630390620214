.subscription-background {
    min-height: 110px;
    width: 100%;
    border: 1px solid rgb(144, 142, 142);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.aslikahani-logo {
    height: 120px;
    width: 433px;
}

.sub-part {
    height: 120px;
    width: 800px;

}



.aslikahani-logo-size {
    height: 40%;
    width: 90%;
    margin-top: 34px;
    margin-left: 20px;
}
.subscribe-image {
    margin-top: 15px;
    cursor: pointer;
}
@media screen and (min-width: 320px) and (max-width: 800px) {
    .subscription-background {
        min-height: 100px;
        width: 100%;
        border: 1px solid black;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    .aslikahani-logo {
        height: 80px;
        width: 100%;
    }
    .aslikahani-logo-size {
      margin-left: 60px;
        width: 70%;
       
    }

    .sub-part {
        height: 110px;
        width: 100%;

    }

    
    .subscribe-image {
        width: 95%;
        margin-top: -1px;
        margin-left: 11px;
    }
    .subscribe-plans{
        left: 0;
    }
    /* .form-container{
        border: 1px solid #989898; 
        border-radius: 20px;
    } */

}
.form-container{
    border: 1px solid #989898; 
    border-radius: 20px;
}

/* SubscriptionDoor */


.blackSub-logo{
    width: 850px;
    margin-left: 20px;
    margin-top: 40px;
}
.flex{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    border:1px solid red;
    height:1500px;
}
.left{
    height:1500px;
    border:1px solid black;
}
.right{
    border:1px solid black;
    height:1500px;
    width:500px;
}
.latest-edition{

    height:40px;
    width:80%;
    background-color: red;
    color: white;
    text-align: center;
    font-size:x-large;
    font-weight: 600;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;

}
.box-size{
    margin-left: 20px;
    width:98%;
}
.form-submit{
    width: fit-content;
    left: 45%;
    margin-top: 4px;
    background-color: #d32415 !important;
    border: none;
}
/* .subscribe-plans{
    height: 416px;
    width: -webkit-fill-available;
    left: 70px;
    position: relative;
} */
/* width: -webkit-fill-available;
    margin-left: 20%; */
.subscribe-plans img{
    width: -webkit-fill-available;
}

.form-container {
	padding: 15px;
	max-width: 750px;
	margin: 0 auto;
   background-color: #dddada;
}
input,
textarea {
	border: 0;
}
[type="submit"] {
	background: white;
	color: black;
	text-shadow: 1px 1px 1px black;
	font-size: 1.4rem;
	text-transform: uppercase;
	text-align: center;
	padding: 12px 60px;
	width: 100%;
	cursor: pointer;
    /* border: 1px solid black; */
    border-radius: 10px;
}
[type="submit"]:hover{
    color: red;
}
.zeile {
	display: flex;
	flex-wrap: wrap;
	column-gap: 2%;
}
.zeile .input-container {
	flex: 1 0 200px;
}

.input-container {
	background: #dddada;
	padding: 16px;
	margin-bottom: 10px;
	position: relative;
}

.input-container textarea {
	resize: none;
	outline: none;
}

.input-container input,
.input-container textarea {
	font-size: 17px;
	color: #333;
	outline: none;
	width: 100%;
	background: white;
	position: relative;
	z-index: 2;
    border: 1px solid #535252;
    border-radius: 10px;
    font-family: sans-serif;
    padding: 4px;
}

.input-container label {
	color: grey;
	position: absolute;
	left: 24px;
	top: 16px;
	z-index: 7;
	font-size: 1.1rem;
	transition: 0.5s;
}
input:placeholder-shown + label,
textarea:placeholder-shown + label {
	top: 22px;
	font-size: 12px;
}
input:not(:placeholder-shown) + label,
textarea:not(:placeholder-shown) + label {
	top: 5%;
	font-size: 12px;
}
input:focus + label,
textarea:focus + label {
	top: 2px;
	font-size: 12px;
}

