/*COLOR*/
 .topbar ul li a:hover, .megaMenu .caption h5 a:hover, .zerogrid-section .post-content h5 a:hover, .zerogrid-section .post-content .post-tools li:hover, .zerogrid-section .post-content .post-tools li a:hover, .sidebar-middle .category-widget .category-widget-detail ul li a:hover, .post-entry blockquote::before, .post-entry ul.post-entry-poits li i, .post-entry .social-share.social-share-style-2 li.post-share strong, .next-n-prev .next-post i, .next-n-prev .pre-post i, .next-n-prev a, .featured-news-slider .post-content h5 a:hover, .full-with-slider-section .post-content h5 a:hover, .full-with-slider-section .post-content .post-tools li:hover, .full-with-slider-section .post-content .post-tools li a:hover, .post-entry .author-title .list-inline li a:hover, .caption a:hover, .grid-1 .desc h5 a:hover, .post-tools li:hover, .cat-holder a:hover, .footer-link.bg-white ul li a:hover, footer .footer-top ul.twitter-widget .caption a.url, .top-small-post-slider .post-content .post-tools li:hover, .top-small-post-slider .post-content .post-tools li a:hover {
	color: red;
}
.small-post .post-content h3 a:hover {
	color: red !important;
}

/*BACKGROUND COLOR*/
.btn-colored-blog, #menu-1.megaMenu .drop-down li> a::after, .category-widget h4, .video-section .rsDefault .rsThumb.rsNavSelected, .scrollup, .owl-theme .owl-nav div.owl-next:hover, .owl-theme .owl-nav div.owl-prev:hover, .auth-arti-no a, .grid-1 .catname span, .widget .pol-btn a:hover, .tag-list a:hover, .contact-detail-box .icon-box {
	background-color: #d32415;
}

/*BORDER TOP*/
.megaMenu .drop-down {
	border-top: 2px solid red;
}

/*BORDER AND COLOR*/
.btn-colored-blog:hover, .widget .pol-btn a, .btn-colored-blog:focus  {
	color: red;
	border: 1px solid red;
}

/*BORDER TOP 2PX*/
.megaMenu .drop-down-medium, .megaMenu .drop-down-large, .megaMenu .drop-down-medium.nav-tabs, .megaMenu .drop-down-large.nav-tabs {
	border-top: 2px solid red;
}

/*BORDER BOTTOM 2PX*/
.post-entry .social-share.social-share-style-2 li.post-share, #sidebar ul.nav-tabs {
	border-bottom: 2px solid #d32415;
}

/*BORDER COLO*/
.share-ping {
	border-color: red transparent transparent;
}

.megaMenu .menu-logo > li > a::after, .megaMenu .menu-links > li > a::after, .megaMenu .drop-down li > a::after, .megaMenu .drop-down-large .nav-tabs.vertical a::after, .megaMenu .drop-down-medium .nav-tabs.vertical a::after, .megaMenu .drop-down-large .nav-tabs a::after, .megaMenu .drop-down-medium .nav-tabs a::after, .megaMenu .drop-down-large .well::after, .megaMenu .drop-down-medium .well::after, .megaMenu .drop-down-large .list-group .list-group-item::after, .megaMenu .drop-down-medium .list-group .list-group-item::after, .megaMenu .drop-down-large .btn-default::after, .megaMenu .drop-down-medium .btn-default::after, .megaMenu .drop-down-large .btn::after, .megaMenu .drop-down-medium .btn::after, .megaMenu .drop-down-large table td::after, .megaMenu .drop-down-large table th::after, .megaMenu .drop-down-medium table th::after, .megaMenu .drop-down-medium table th::after, .megaMenu .menu-user-profile .profile-icons a::after, .megaMenu .menu-contact-us button[type="submit"]::after, .megaMenu .menu-contact-us button[type="reset"]::after, .megaMenu .menu-search-bar-desktop button[type="submit"]::after, .megaMenu ul.menu-search-bar-mobile button[type="submit"]::after, .megaMenu .menu-search-bar-desktop > li > a > i.fa::after, .megaMenu ul.menu-search-bar-mobile > li > a > i.fa::after {
    background-color: red;
}

.megaMenu .drop-down-medium .tab-content.vertical, .megaMenu .drop-down-large.nav-tabs .tab-content.vertical, .megaMenu .drop-down-medium .nav-tabs.vertical a::after, .megaMenu .drop-down-large .nav-tabs.vertical a::after {
	color:red !important;
	background-color:#FFF !important;
}
.megaMenu .drop-down-medium .list-items a:hover, .megaMenu .drop-down-large .list-items a:hover, .megaMenu .drop-down-medium .list-items ol li:hover::after, .megaMenu .drop-down-large .list-items ol li:hover::after, .megaMenu .drop-down-medium .nav-tabs.vertical li.active a, .megaMenu .drop-down-medium .nav-tabs.vertical li:hover a, .megaMenu .drop-down-medium .nav-tabs.vertical li:active a, .megaMenu .drop-down-medium .nav-tabs.vertical li:focus a, .megaMenu .drop-down-large .nav-tabs.vertical li.active a, .megaMenu .drop-down-large .nav-tabs.vertical li:hover a, .megaMenu .drop-down-large .nav-tabs.vertical li:active a, .megaMenu .drop-down-large .nav-tabs.vertical li:focus a{
	color:red;	
}

.pagination-holder .pagination> .active> a, .pagination-holder .pagination> .active> span, .pagination-holder .pagination> .active> a:hover, .pagination-holder .pagination> .active> span:hover, .pagination-holder .pagination> .active> a:focus, .pagination-holder .pagination> .active> span:focus {
	background-color: red;
	border-color: red;
}

.pagination-holder .pagination> li> a:hover, .pagination-holder .pagination> li> span:hover, .pagination-holder .pagination> li> a:focus, .pagination-holder .pagination> li> span:focus {
	background-color: red;
	border-color: red;
}
.nav-tabs> li.active> a, .nav-tabs> li.active> a:focus, .nav-tabs> li.active> a:hover {
	border-color: red !important;
	background-color: red;
}
.social-media-icons ul li:hover i {
	color: red;
	border-color: red;
}
.section .tabs .nav-tabs.nav-justified {
	border-bottom: 3px solid red;
}
.hover-show-div .post-type {
	background-color: rgba(33, 150, 243, 0.7);
}
.hover-show-div .post-type:hover {
	background-color: rgba(33, 150, 243, 1);
}

.megaMenu ul.menu-search-bar-desktop .drop-down, .megaMenu ul.menu-search-bar-mobile .drop-down {
  background: #f9f9f9;

}
.megaMenu ul.menu-search-bar-desktop button[type="search"], .megaMenu ul.menu-search-bar-mobile button[type="search"], .megaMenu ul.menu-search-bar-desktop input[type="search"], .megaMenu ul.menu-search-bar-mobile input[type="search"] {
	background:#FFF;
	color:#242424;	
}
.megaMenu ul.menu-search-bar-desktop button[type="submit"]::after, .megaMenu ul.menu-search-bar-mobile button[type="submit"]::after, .megaMenu ul.menu-search-bar-desktop input[type="submit"]::after, .megaMenu ul.menu-search-bar-mobile input[type="submit"]::after {
	background:#FFF !important;
	border:1px solid red;
	color:#242424 !important;	
}
.megaMenu ul.menu-search-bar-desktop button[type="submit"], .megaMenu ul.menu-search-bar-mobile button[type="submit"], .megaMenu ul.menu-search-bar-desktop input[type="submit"], .megaMenu ul.menu-search-bar-mobile input[type="submit"] {
		background:red !important;	
}
.megaMenu ul.menu-search-bar-desktop button[type="submit"]:hover, .megaMenu ul.menu-search-bar-mobile button[type="submit"]:hover, .megaMenu ul.menu-search-bar-desktop input[type="submit"]:hover, .megaMenu ul.menu-search-bar-mobile input[type="submit"]:hover {
	color:red;	
}
.megaMenu ul.menu-search-bar-desktop > li > a > i.fa::after, .megaMenu ul.menu-search-bar-mobile > li > a > i.fa::after {
    background: red ;
}